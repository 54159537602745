/* eslint-disable */
import React, { useRef, useCallback } from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import detectIt from 'detect-it'
import theme, { expandAbsolutely } from '../../styles/theme'
import { isCurrentBreakpointAtLeast } from '../../selectors'
import TileContainer from './TileContainer'
import { fitTextToContainer } from '../../utils/text'
import useElementSize from '../../hooks/useElementSize'
import ImageHover, { IMAGE_TRANSITION_DURATION } from '../widgets/ImageHover'

const HeaderTile = ({ className, image, title, subtitle, link, cta_text: ctaText, index }) => {
  const classes = useStyles()
  const containerRef = useRef()
  const wrapperRef = useRef()
  const textRef = useRef()
  const isMobile = !useSelector(isCurrentBreakpointAtLeast('md'))
  const fit = useCallback(() => {
    fitTextToContainer(containerRef.current, wrapperRef.current, [textRef.current], [isMobile ? 70 : 120])
  }, [])

  useElementSize(containerRef, fit)

  return <TileContainer link={link} cta_text={ctaText} className={cn(classes.link, className, {dark: !image})}>
    {image && <ImageHover className={classes.image} classNames={{ overlayImage: classes.overlayImage }} image={image} />}
    <header className={cn(classes.tile, className)} ref={containerRef}>
      <div className={classes.wrapper} ref={wrapperRef}>
        <h3 className={classes.title} ref={textRef}>{title.text}</h3>
        <h4 className={classes.subtitle}>{subtitle.text}</h4>
      </div>
    </header>
  </TileContainer>
}

const useStyles = createUseStyles({
  link: {
    position: 'relative',
    color: theme.colors.secondary,
    transition: `color ${IMAGE_TRANSITION_DURATION}s ease-in-out`,
    '&:hover': {
      color: theme.colors.white
    },
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&:hover $overlayImage': {
        opacity: 0
      }
    }),
    '&.dark': {
      color: theme.colors.white
    }
  },
  image: {
    ...expandAbsolutely
  },
  overlayImage: {},
  tile: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: theme.sp(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.sp(4)
    }
  },
  wrapper: {},
  title: {
    lineHeight: 1,
    marginBottom: 0,
    fontSize: theme.getRemValue(70),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.getRemValue(120)
    }
  },
  subtitle: {
    fontFamily: theme.fonts.body,
    fontSize: theme.getRemValue(20),
    lineHeight: 1.2,
    textTransform: 'none',
    marginBottom: 0
  }
}
)
export default HeaderTile
