import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { CONTENT_ROUTE } from '../../actions'
import URL from '../../utils/URL'
import injectSheet from 'react-jss'

class RichTextContainer extends PureComponent {
    // this handles the internal clicks
    _handleHtmlContentClick = (e) => {
      const { handleNavigate } = this.props

      if (e.defaultPrevented) {
        return
      }
      if (e.metaKey || e.ctrlKey || e.shiftKey) {
        return
      }
      if (e.button !== 0) {
        return
      }
      // Get the <a> element.
      var el = e.target
      while (el && el.nodeName !== 'A') {
        el = el.parentNode
      }
      // Ignore clicks from non-a elements.
      if (!el) {
        return
      }
      // Ignore the click if the element has a target.
      if (el.target && el.target !== '_self') {
        return
      }

      const targetUrl = new URL(el.href)
      const currentURL = new URL(window.location.href)

      // Ignore links that don't share a protocol and host with ours.
      if (targetUrl.protocol !== currentURL.protocol || targetUrl.host !== currentURL.host) {
        el.target = '_blank'
        return
      }

      // Prevent :focus from sticking; preventDefault() stops blur in some browsers
      el.blur()
      e.preventDefault()

      /// using the router, go to that url
      handleNavigate(targetUrl)
    }

    render () {
      const { className, content } = this.props
      return <div className={className}
        onClick={this._handleHtmlContentClick} dangerouslySetInnerHTML={{ __html: content }} />
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
  return {
    handleNavigate: (targetUrl) => {
      dispatch({ type: CONTENT_ROUTE, payload: [targetUrl.pathname] })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet({
})(RichTextContainer))
