import { put, takeLatest, call } from 'redux-saga/effects'
import { SUBSCRIBE_REQUEST, subscribeError, subscribeSuccess } from '../actions'
import { getApiUrl } from '../config'

function * fetchSubscribe (action) {
  try {
    var response = yield call(postData, action.payload)

    if (response.status === 'Error') {
      yield put(subscribeError(response.message))
    } else {
      yield put(subscribeSuccess(response.msg))
    }
  } catch (e) {
    yield put(subscribeError(e.message))
  }
}

function postData (data) {
  return fetch(getApiUrl() + '/subscribe', {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    body: JSON.stringify(data)
  }).then(response => response.json())
}

function * fetchSubscribeSaga () {
  yield takeLatest(SUBSCRIBE_REQUEST, fetchSubscribe)
}

export default fetchSubscribeSaga
