import { createSelector } from 'reselect'

const MAX_RIBBON_FONT_SIZE = 40

export const getRibbonFontSizes = (state) => state.ribbon
export const getMaxRibbonFontSize = (state) => MAX_RIBBON_FONT_SIZE

export const getRibbonFontSize = createSelector(
  getRibbonFontSizes,
  getMaxRibbonFontSize,
  (fontSizes, maxFontSize) => {
    var fontSize = maxFontSize
    for (var propertyName in fontSizes) {
      if (!fontSizes[propertyName]) continue
      fontSize = Math.min(fontSize, fontSizes[propertyName])
    }
    return fontSize
  })
