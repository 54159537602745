import React from 'react'
import { createUseStyles } from 'react-jss'

const RecordBox = ({ boxColor, circleColor, isHalfPosition, boxHeight, boxBorderRadius }) => {
  const classes = useStylesBox({ boxColor, circleColor, isHalfPosition, boxHeight, boxBorderRadius })
  return (
    <div className={classes.box}>
      <div className={classes.circle}>
        {isHalfPosition ? <div className={classes.circleHalfCover} /> : null}
      </div>
    </div>
  )
}

export default RecordBox

const ROTATE = {
  top: '180deg',
  right: '270deg',
  bottom: '0deg',
  left: '90deg'
}

const useStylesBox = createUseStyles({
  box: ({ boxColor, isHalfPosition, boxHeight, boxBorderRadius }) => ({
    width: `${boxHeight}px`,
    height: `${boxHeight}px`,
    borderRadius: `${boxBorderRadius}%`,
    backgroundColor: boxColor
  }),
  circle: ({ circleColor, isHalfCircle, isHalfPosition, boxHeight }) => ({
    width: `${boxHeight}px`,
    height: `${boxHeight}px`,
    borderRadius: `50% 50%`,
    backgroundColor: circleColor,
    transform: `rotate(${ROTATE[isHalfPosition]})`
  }),
  circleHalfCover: ({ boxColor, circleColor, isHalfPosition, boxHeight, boxBorderRadius }) => ({
    width: `${boxHeight}px`,
    height: `${boxHeight / 2}px`,
    borderRadius: `${boxBorderRadius}% ${boxBorderRadius}% 0 0`,
    backgroundColor: boxColor,
    animation: 'spin 5s infinite'
  })
})
