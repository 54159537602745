import { buffers, eventChannel, END } from 'redux-saga'

function createUploadFileChannel (xhr, file) {
  return eventChannel(emitter => {
    const onProgress = (e) => {
      if (e.lengthComputable) {
        let progress = 0
        if (e.total !== 0) {
          progress = parseInt((e.loaded / e.total) * 90, 10) // allow 10% extra while we wait for the server side to finish its work
        }
        emitter({ progress })
      }
    }
    const onFailure = (e) => {
      emitter({ err: new Error('Upload failed') })
      emitter(END)
    }
    const onAbort = (e) => {
      emitter(END)
    }

    xhr.upload.addEventListener('progress', onProgress)
    xhr.upload.addEventListener('error', onFailure)
    xhr.upload.addEventListener('abort', onAbort)
    xhr.onreadystatechange = e => {
      const { readyState, status } = xhr
      if (readyState === 4) {
        if (status >= 200 && status <= 299) {
          emitter({ success: true, response: JSON.parse(xhr.response) })
          emitter(END)
        } else {
          onFailure(null)
        }
      }
    }
    // xhr.open("POST", endpoint, true);
    xhr.send(file)
    return () => {
      xhr.upload.removeEventListener('progress', onProgress)
      xhr.upload.removeEventListener('error', onFailure)
      xhr.upload.removeEventListener('abort', onFailure)
      xhr.onreadystatechange = null
      xhr.abort()
    }
  }, buffers.sliding(2))
}

export default createUploadFileChannel
