import React from 'react'
import ResponsiveImage from '../widgets/ResponsiveImage'
import get from 'lodash/get'
import first from 'lodash/first'
import { createUseStyles } from 'react-jss'
import PageSection from './PageSection'

const ImageSlice = ({ image, size, position, spacing, className }) => {
  const classes = useStyles({ position, spacing })
  const aspect = get(first(image.images), ['dimensions', 'aspect'])
  return (
    <PageSection className={classes.imageWrapper} extended>
      <ResponsiveImage className={classes.container} images={image.images} blur={image.blur} aspect={1 / aspect} size={size} />
    </PageSection>
  )
}

const imagePosition = position => {
  switch (position) {
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-end'
    default:
      return 'center'
  }
}

const imageSpacing = (theme, spacing) => {
  switch (spacing) {
    case 'small':
      return 3
    case 'medium':
      return 4
    case 'large':
      return 5
    default:
      return 4
  }
}

const useStyles = createUseStyles((theme) => ({
  container: {
    margin: ({ position }) => position ? 0 : '0 auto'
  },
  imageWrapper: {
    marginBottom: ({ spacing }) => theme.sp(imageSpacing(spacing)),
    '& > div': {
      display: 'flex',
      justifyContent: ({ position }) => position ? imagePosition(position) : 'center'
    }
  }
}))

export default ImageSlice
