import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme, { afterFix } from '../../styles/theme'

const PageSection = ({ className, extended, fullWidth, children }) => {
  const classes = useStyles()
  return (
    <section className={cn(classes.container,
      {
        [classes.extended]: extended,
        [classes.fullWidth]: fullWidth
      }, className)}>
      <div className={cn(!extended && !fullWidth && classes.small)}>
        {children}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
    maxWidth: 940,
    ...afterFix(),
    padding: [0, theme.spacing.md],
    margin: [0, 'auto', theme.sp(4)],
    [theme.breakpoints.up('md')]: {
      margin: [0, 'auto', theme.sp(6)]
    }
  },
  small: {
    maxWidth: 680
  },
  fullWidth: {
    maxWidth: '100%',
    padding: 0
  }
})

export default PageSection
