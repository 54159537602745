import React from 'react'
import { createUseStyles } from 'react-jss'
import SubscriptionForm from '../SubscriptionForm'
import theme from '../../styles/theme'

const Subscribe = ({ title, ctaText, optInText, copy, thankYouMessage, optInStatement }) => {
  const classes = useStyles()
  return (
    <div className={classes.section} >
      <div className={classes.wrapper} >
        <div className={classes.titleContainer} >
          <div className={classes.title} dangerouslySetInnerHTML={{ __html: title && title.html }} />
        </div>
        <SubscriptionForm
          copy={copy}
          cta={ctaText}
          optInText={optInText}
          thankYouMessage={thankYouMessage}
          optInStatement={optInStatement}
        />
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  section: {
    marginBottom: 0,
    backgroundColor: theme.colors.yellow,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [theme.spacing.lg, theme.spacing.md, theme.spacing.xl, theme.spacing.md]
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // maxWidth: 500,
    width: '100%'
  },
  titleContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.sp(2)
  },
  copyContainer: {
    margin: [theme.sp(2), theme.sp(1)]
  },
  title: {
    margin: 0,
    fontFamily: theme.fonts.heading,
    textTransform: 'uppercase',
    fontSize: theme.getRemValue(60),
    lineHeight: 0.9,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.getRemValue(80)
    }
  }
})

export default Subscribe
