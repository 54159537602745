import inDOM from 'dom-helpers/util/inDOM'
import SessionStorage from 'redux-first-router-restore-scroll/dist/SessionStorage'
import { CONTENT_ROUTE, GALLERY_ROUTE, PAGE_CONTENT_LOADED } from '../actions'

class PauseableSessionStorage extends SessionStorage {
  paused = false

  pause () {
    this.paused = true
  }

  resume () {
    this.paused = false
  }

  save (location, key, value) {
    if (!this.paused) {
      return super.save(location, key, value)
    }
  }
}

let storage

export function getStateStorage () {
  if (!storage) {
    storage = new PauseableSessionStorage()
  }
  return storage
}

export default store => next => action => {
  if (inDOM) {
    if (action.type === CONTENT_ROUTE || action.type === GALLERY_ROUTE) {
      getStateStorage().pause()
    } else if (action.type === PAGE_CONTENT_LOADED) {
      getStateStorage().resume()
    }
  }
  return next(action)
}
