import React, { PureComponent } from 'react'
import cn from 'classnames'
import injectStyles from 'react-jss'
import get from 'lodash/get'
// import ResponsiveImage from '../widgets/ResponsiveImage'
import flow from 'lodash/flow'
import { expandAbsolutely } from '../../styles/theme'
import outlineText from '../../styles/outlineText'
import { canUseDom } from '../../utils/dom'
import CloseIcon from './CloseIcon'
// import { Textfit } from 'react-textfit'
import Transition from 'react-transition-group/Transition'
import { getScrollbarWidth } from '../../utils/scrollbar'
import Button from '../widgets/Button'

const ANIMATION_DURATION = 350

class GalleryItemDialog extends PureComponent {
    state = {
      nameWidth: null
    }

    componentDidMount () {
      this._checkBody(this.props)
    }

    componentDidUpdate () {
      this._checkBody(this.props)
    }

    _checkBody = (props) => {
      const { show } = props
      if (!canUseDom) return
      document.body.style.overflow = show ? 'hidden' : ''
      document.body.style.marginRight = show ? getScrollbarWidth() + 'px' : ''
    }

    setNameContainerRef = (ref) => {
      if (!ref) return
      this.setState({ nameWidth: ref.offsetWidth })
    }

    render () {
      const { classes, item, show, showNames, onClose, showAuction, auctionButtonText, auctionInstructionHtml } = this.props // showNames
      const { nameWidth } = this.state

      const bidLink = get(item, ['bid_link'])
      const itemNumberString = item && item.id.replace('r', '')
      const imageUrl = item && item.image.originalUrlSmall

      return <Transition in={show} timeout={ANIMATION_DURATION}>
        {(state) => (
          <div className={cn(classes.container, state, { withName: showNames })}>
            <div className={cn(classes.overlay, state)} onClick={onClose} />
            <div className={cn(classes.dialog, state)}>
              <div className={classes.closeIcon} onClick={onClose}><CloseIcon size={24} className={classes.closeIconLines} /></div>
              {item &&
              <div className={classes.content}>
                <img src={imageUrl} className={classes.image} />
                {/* <ResponsiveImage className={classes.image} images={item.image.images} blur={item.image.blur} aspect={1} /> */}
                <div className={classes.shareContent}>
                  <div className={classes.itemData}>
                    <div className={classes.itemNumber}>{item.number.replace('r', '')}</div>
                    {showNames &&
                    <div className={classes.itemNameContainer} ref={this.setNameContainerRef}>
                      {nameWidth && <div className={classes.itemName} style={{ width: nameWidth, maxWidth: '100%' }}>
                        {item.name ? item.name.toUpperCase() : 'Unknown Artist'}
                      </div>}
                      <div className={classes.itemTrack}>{item.track || 'Unknown Track'}</div>
                    </div>
                    }
                  </div>
                  {showAuction && (
                    <div className={classes.auctionContainer}>
                      <div className={classes.auctionInstructionText}>
                        <div dangerouslySetInnerHTML={{ __html: auctionInstructionHtml }} className={classes.copy} />
                      </div>
                      <div className={classes.auctionLinkContainer}>
                        <Button linkInfo={{ url: `https://peggy.com/artworks/secret-artwork-${itemNumberString}?invite_code=SECRET7` }} text={auctionButtonText} />
                      </div>
                    </div>
                  )}
                  {bidLink && (
                    <div className={classes.bidLinkContainer}>
                      <a className={classes.bidLink} href={bidLink} target='_blank'>Place Bid</a>
                    </div>
                  )}
                </div>
              </div>
              }
            </div>
          </div>
        )}
      </Transition>
    }
}

const BREAKPOINT = 1000

export default flow([
  injectStyles((theme) => ({
    container: {
      ...expandAbsolutely,
      overflow: 'auto',
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      zIndex: theme.zIndex.overlay,
      [theme.breakpoints.up(BREAKPOINT)]: {
        alignItems: 'center',
        overflow: 'hidden'
      },
      '&.withName $itemNumber': {
        fontSize: theme.getRemValue(24),
        justifyContent: 'flex-start',
        flex: 0
      },
      '&.entering, &.exiting, &.exited': {
        overflow: 'hidden'
      },
      '&.exited': {
        visibility: 'hidden'
      }
    },
    overlay: {
      ...expandAbsolutely,
      // position: 'fixed',
      backgroundColor: theme.colors.black,
      zIndex: theme.zIndex.overlay,
      opacity: 0,
      transition: 'opacity 0.35s',
      '&.entering, &.entered': {
        opacity: 0.75
      }
    },
    dialog: {
      maxWidth: 720 + 400,
      zIndex: theme.zIndex.dialog,
      opacity: 0,
      transform: 'translate(0%, 100px)',
      // Animating the exiting is going to be a bit harder, we need to store the previous selected item
      transition: 'none',
      '&.entering, &.entered': {
        transition: 'all 0.35s ease-out',
        opacity: 1,
        transform: 'translate(0%, 0)'
      },
      margin: [10, 'auto', 80],
      width: `100%`,
      [theme.breakpoints.up('sm')]: {
        margin: [10, 'auto', 80],
        width: `calc(100% - ${theme.spacingPx.sm * 2}px)`,
        padding: [0, theme.spacing.sm]
      },
      [theme.breakpoints.up(BREAKPOINT)]: {
        marginTop: 'auto',
        marginBottom: 'auto'
      }

    },
    closeIcon: {
      height: 56,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      maxWidth: 720,
      margin: ['auto', theme.spacing.sm],
      [theme.breakpoints.up('sm')]: {
        margin: 'auto'
      },
      [theme.breakpoints.up(BREAKPOINT)]: {
        maxWidth: 'inherit'
      }
    },
    closeIconLines: {
      backgroundColor: theme.colors.white,
      margin: ['auto', 0, 0]
    },
    content: {
      display: 'block',
      textAlign: 'center',
      [theme.breakpoints.up(BREAKPOINT)]: {
        display: 'flex',
        flexDirection: 'row'
      }
    },
    image: {
      width: `100%`,
      maxWidth: 720,
      backgroundColor: theme.colors.black,
      [theme.breakpoints.up(BREAKPOINT)]: {
        width: `calc(100% - 400px)`
      }
    },
    shareContent: {
      width: `100%`,
      maxWidth: 720,
      margin: [0, 'auto'],
      [theme.breakpoints.up(BREAKPOINT)]: {
        width: 400
      },
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.colors.white
    },
    record: {
      color: theme.colors.secondary,
      position: 'absolute',
      top: -10,
      width: '100%',
      height: '300%',
      [theme.breakpoints.up(BREAKPOINT)]: {
        height: '100%',
        top: '50%'
      }
    },
    shareLinksLarge: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      position: 'relative',
      paddingBottom: theme.sp(2),
      [theme.breakpoints.up(BREAKPOINT)]: {
        paddingBottom: theme.sp(6)
      },
      '& > span': {
        position: 'relative',
        justifyContent: 'center',
        margin: [theme.spacing.sm, 0],
        display: 'flex',
        padding: [0, '20%'],
        fontFamily: theme.fonts.heading,
        fontSize: theme.getRemValue(16),
        letterSpacing: 6,
        textAlign: 'center',
        textTransform: 'uppercase',
        [theme.breakpoints.up(BREAKPOINT)]: {
          fontSize: theme.getRemValue(28),
          letterSpacing: 16,
          margin: [theme.sp(2), 0, theme.sp(6)],
          padding: [0, '26%']
        }
      },
      '& > a': {
        position: 'relative',
        color: theme.colors.white,
        fontFamily: theme.fonts.heading,
        fontSize: theme.getRemValue(70),
        lineHeight: 1,
        letterSpacing: 6,
        textAlign: 'center',
        textTransform: 'uppercase',
        textDecoration: 'none',
        ...outlineText(theme.colors.secondary),
        '&:hover': {
          color: theme.colors.secondary
        }
      }
    },
    shareLinksSmall: {
      display: 'flex',
      margin: [0, theme.spacing.md, theme.spacing.md],
      '& > *:not(:last-child)': {
        marginRight: '1rem'
      },
      '& > span': {
        position: 'relative',
        display: 'flex',
        fontFamily: theme.fonts.heading,
        fontSize: theme.getRemValue(14),
        letterSpacing: 4,
        textAlign: 'center',
        textTransform: 'uppercase',
        [theme.breakpoints.up(BREAKPOINT)]: {
          fontSize: theme.getRemValue(20)
        }
      },
      '& > a': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.secondary,
        fontFamily: theme.fonts.heading,
        fontSize: theme.getRemValue(14),
        lineHeight: 1,
        letterSpacing: 4,
        textTransform: 'uppercase',
        textDecoration: 'none',
        [theme.breakpoints.up(BREAKPOINT)]: {
          ...outlineText(theme.colors.secondary),
          color: theme.colors.white,
          fontSize: theme.getRemValue(20),
          '&:hover': {
            color: theme.colors.secondary
          }
        }
      }
    },
    auctionLink: {
      textAlign: 'center'
    },
    bidLinkContainer: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      position: 'relative',
      paddingBottom: theme.sp(2),
      [theme.breakpoints.up(BREAKPOINT)]: {
        paddingBottom: theme.sp(6)
      }
    },
    bidLink: {
      // position: 'relative',
      // color: theme.colors.secondary,
      // fontFamily: theme.fonts.heading,
      // fontSize: theme.getRemValue(72),
      // lineHeight: 1,
      // letterSpacing: 6,
      // textAlign: 'center',
      // textTransform: 'uppercase',
      // textDecoration: 'none',
      // [theme.breakpoints.up(BREAKPOINT)]: {
      //   ...outlineText(theme.colors.secondary),
      //   color: theme.colors.white,
      //   fontSize: theme.getRemValue(92),
      //   '&:hover': {
      //     color: theme.colors.secondary
      //   }
      // }
    },
    itemData: {
      margin: theme.spacing.md,
      lineHeight: 1.2,
      fontSize: theme.getRemValue(24),
      display: 'flex',
      [theme.breakpoints.up(BREAKPOINT)]: {
        margin: [theme.spacing.lg, theme.spacing.md, theme.spacing.sm],
        display: 'block',
        fontSize: theme.getRemValue(40)
      }
    },
    itemNumber: {
      fontSize: theme.getRemValue(60),
      fontFamily: theme.fonts.heading,
      color: theme.colors.pink,
      display: 'flex',
      flex: 1,
      minWidth: 90,
      whiteSpace: 'nowrap',
      marginRight: theme.spacing.sm,
      [theme.breakpoints.up(BREAKPOINT)]: {
        paddingRight: 0,
        marginBottom: theme.spacing.sm,
        marginRight: theme.spacing.sm
      }
    },
    itemNameContainer: {
      flex: 1,
      maxWidth: `calc(100% - ${theme.spacingPx.md * 2}px)`,
      [theme.breakpoints.up(BREAKPOINT)]: {
        maxWidth: '100%'
      }
    },
    itemName: {
      width: '100%',
      textAlign: 'left',
      fontSize: theme.getRemValue(40),
      fontFamily: theme.fonts.heading,
      marginBottom: theme.spacing.sm
    },
    itemTrack: {
      fontSize: theme.getRemValue(20),
      marginBottom: theme.spacing.sm,
      textAlign: 'left'
    },
    itemSocialTag: {
      fontSize: theme.getRemValue(14),
      marginBottom: theme.spacing.sm
    },
    alternateSocialTile: {
      backgroundColor: theme.colors.teal
    },
    socialTileCta: {
      backgroundColor: theme.colors.peach
    },
    auctionContainer: {
      textAlign: 'center',
      margin: theme.spacing.md
    },
    auctionInstructionText: {
      fontSize: theme.getRemValue(20),
      margin: theme.spacing.md,
      [theme.breakpoints.up(BREAKPOINT)]: {
        fontSize: theme.getRemValue(14)
      }
    },
    auctionLinkContainer: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      paddingBottom: theme.sp(2),
      [theme.breakpoints.up(BREAKPOINT)]: {
        paddingBottom: theme.sp(6)
      },
      '& > a': {
        marginTop: theme.getRemValue(10)
      }
    }
  }))
])(GalleryItemDialog)
