import { NEXT_GALLERY_PAGE, SET_GALLERY_FILTER } from '../actions'

const initialState = {
  page: 0,
  limit: 4 * 10, // 10 rows
  total: 700,
  track: '',
  query: ''
}

const gallery = (state = initialState, action) => {
  switch (action.type) {
    case NEXT_GALLERY_PAGE:
      return {
        ...state,
        page: state.page + 1
      }

    case SET_GALLERY_FILTER:
      return {
        ...state,
        ...action.payload,
        page: 0
      }

    default:
      return state
  }
}

export default gallery
