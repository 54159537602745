import { connect } from 'react-redux'
import { getFacebookUrl, getTwitterkUrl, getInstagramUrl, getElementSizes } from '../../selectors'
import { elementSizeChanged } from '../../actions'
import get from 'lodash/get'
import SocialLinks from './SocialLinks'

function mapStateToProps (state) {
  return {
    facebook_link: getFacebookUrl(state),
    twitter_link: getTwitterkUrl(state),
    instagram_link: getInstagramUrl(state),
    size: get(getElementSizes(state), ['social_tile'], { width: 999, height: 300 })
  }
}

function mapDispatchToProps (dispatch) {
  return {
    sizeChanged: (width, height) => dispatch(elementSizeChanged('social_tile', width, height))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SocialLinks)
