import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { getCurrentTrackId, getTracks, isPlayerPaused } from '../../selectors'
import first from 'lodash/first'
import filter from 'lodash/filter'
import { ReactComponent as RoundPlayIcon } from './roundPlay.svg'
import { ReactComponent as RoundPauseIcon } from './roundPause.svg'
import { playTrack, playerPause } from '../../actions'
import SoundWave from '../widgets/SoundWave'

export const TRACK_COLORS = [
  { back: '#6CE909', clip: '#CCFFA4' },
  { back: '#1E3EE2', clip: '#4878FF' },
  { back: '#F0441E', clip: '#FF6C60' },
  { back: '#B81AEE', clip: '#FA6CFF' },
  { back: '#F34B03', clip: '#FF7B42' },
  { back: '#FEC600', clip: '#FFDC60' },
  { back: '#FF50BD', clip: '#FF9BD9' }
]

const Track = ({ track: { uid }, index }) => {
  const dispatch = useDispatch()
  const colorSet = TRACK_COLORS[index]
  const classes = useStyles({ backgroundColor: colorSet.back, clipColor: colorSet.clip })
  const tracks = useSelector(getTracks)
  const track = useMemo(() => first(filter(tracks, t => t.uid === uid)), [uid, tracks])

  const currentTrack = useSelector(getCurrentTrackId) === uid
  const currentlyPlaying = !useSelector(isPlayerPaused) && currentTrack

  const handlePlayTrack = useCallback(() => {
    if (currentlyPlaying) {
      dispatch(playerPause(uid))
    } else {
      dispatch(playTrack(uid))
    }
  }, [uid, currentlyPlaying])

  if (!track && !track.image) return null

  const imageUrl = track.image.images[0].url

  return (
    <div className={classes.trackContainer} >
      <div className={classes.trackTop}>
        <img className={classes.image} src={imageUrl} />
      </div>
      <div className={classes.textArea}>
        <div className={classes.trackArtist}>{track.artist}</div>
        <div className={classes.trackTitle}>{track.title}</div>
      </div>
      <div className={classes.controlButtonWrapper}>
        <ControlButton handlePlayTrack={handlePlayTrack} currentlyPlaying={currentlyPlaying} />
      </div>
      {currentlyPlaying && (
        <div className={classes.SoundWaveWrapper}>
          <SoundWave color={colorSet.clip} />
        </div>
      )}
    </div>
  )
}

const ControlButton = ({ handlePlayTrack, currentlyPlaying }) => {
  if (currentlyPlaying) {
    return <RoundPauseIcon onClick={handlePlayTrack} />
  }

  return <RoundPlayIcon onClick={handlePlayTrack} />
}

const useStyles = createUseStyles(theme => {
  const classes = {
    trackContainer: ({ backgroundColor, clipColor }) => ({
      minWidth: '260px',
      maxWidth: '260px',
      height: '500px',
      backgroundColor: backgroundColor,
      borderRadius: '25px',
      flexWrap: 'wrap',
      position: 'relative',
      marginBottom: '20px'
    }),
    trackTop: ({ backgroundColor, clipColor }) => ({
      width: '260px',
      height: '260px',
      backgroundColor: clipColor,
      borderRadius: '25px',
      overflow: 'hidden',
      position: 'relative'
    }),
    image: {
      width: '300px',
      height: '300px',
      position: 'relative',
      borderRadius: '0 50% 50% 0',
      left: '43%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },
    textArea: {
      paddingLeft: '20px',
      paddingRight: '10px',
      position: 'relative',
      top: '-20px'
    },
    trackArtist: {
      color: theme.colors.white,
      fontFamily: theme.fonts.heading,
      fontSize: '38px',
      fontWeight: '400',
      textTransform: 'uppercase',
      lineHeight: '95%'
    },
    trackTitle: {
      color: theme.colors.white,
      fontFamily: theme.fonts.sub,
      fontSize: '18p',
      fontWeight: '500',
      textTransform: 'uppercase',
      lineHeight: '120%',
      letterSpacing: '1.8px',
      paddingTop: '10px'
    },
    controlButtonWrapper: {
      position: 'absolute',
      bottom: 20,
      left: 20
    },
    SoundWaveWrapper: {
      position: 'absolute',
      bottom: 20,
      right: 20
    }
  }
  return classes
}, { name: 'Track' }
)

export default Track
