import React from 'react'
import injectStyles from 'react-jss'
import Link from '../Link'

const LinkButtonSlice = ({ text, link, classes, className }) => {
  return (
    <div className={classes.container}>
      <Link className={classes.button} to={link.url} target={link.target}>{text}</Link>
    </div>
  )
}

export default injectStyles(theme => ({
  container: {
    maxWidth: 940,
    padding: [0, theme.spacing.md],
    margin: [0, 'auto', theme.sp(4)],
    [theme.breakpoints.up('md')]: {
      margin: [0, 'auto', theme.sp(8)]
    }
  },
  button: {
    padding: '16px 40px',
    borderRadius: '30px',
    color: theme.colors.black,
    background: theme.colors.pink,
    textDecoration: 'none',
    transition: 'all 0.3s',
    fontFamily: theme.fonts.heading,
    fontSize: theme.getRemValue(22),
    display: 'inline-block',
    '&:hover': {
      color: theme.colors.white,
      background: theme.colors.pink
    }
  }

})
)(LinkButtonSlice)
