import React, { PureComponent } from 'react'
import injectSheet, { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import flow from 'lodash/flow'
import Link from './Link'
import { getCopyright, getFooterSlices } from '../selectors'
import CircularText from './widgets/CircularText'
import classNames from 'classnames'
import { ReactComponent as Logo } from '../img/secret7.svg'
import QuickLinks from './widgets/QuickLinks'
import { NavLink } from 'redux-first-router-link'
import RecordBox from './widgets/RecordBoxes/RecordBox'
import S7WarChildLogo from '../img/secret7_warchild_logo_white.png'
import SocialFooterLinks from './widgets/SocialFooterLinks'

function nl (text, caption) {
  var lines = text.split(/\r?\n/)
  let i = 1
  while (i < lines.length) {
    lines.splice(i, 0, <br key={i} />)
    i += 2
  }
  return lines
}

class Footer extends PureComponent {
  render () {
    const { classes, slices } = this.props

    const firstSocialLink = slices.find((i) => i.type === 'social_links')

    const filterdSlices = slices.filter((slice) => slice.type !== 'social_links')

    return (
      <footer className={classes.footer} id='footer'>
        <div className={classes.footerTopSection} >
          <div className={classes.footerItem}>
            <NavLink to='/' >
              <img className={classes.warChildLogo} src={S7WarChildLogo} alt='Logo' />
            </NavLink>
          </div>
          {filterdSlices.map((slice, i) => {
            let Wrapper = 'div'
            const props = {
              className: classes.footerItem
            }
            let children
            if (slice.type !== 'contact' && slice.link && slice.link.url) {
              Wrapper = Link
              props.className = classNames(props.className, classes.link)
              props.to = slice.link.url
              props.target = slice.link.target
            }
            switch (slice.type) {
              case 'logo':
                children = <Logo className={classes.logo} title={'Secret 7'} />
                break

              case 'circular_text':
                children = <CircularText className={classes.circularText}>{slice.text}</CircularText>
                break

              case 'normal_text':

                children = nl(slice.text, i)
                if (Wrapper === 'div') {
                  Wrapper = 'p'
                }
                break

              case 'contact':

                children = <div className={classes.contact}>
                  {slice.link ? <Link to={slice.link.url} target='_blank'>{slice.text}</Link>
                    : <span>{slice.text}</span>}
                  {slice.email_link ? <Link to={slice.email_link.url} target='_blank' className='email'>{slice.email}</Link>
                    : <span>{slice.email}</span>}
                </div>
                break

              case 'quick_links':

                children = <QuickLinks className={classes.circularText} slice={slice} />
                break

              case 'custom_text':

                children = <div className={classes.customText} dangerouslySetInnerHTML={{ __html: slice.text.html }} />
                break

              case 'footer_image':

                children = <img src={slice.image.images[0].url} />
                break

              default:
                console.warn(`Unknown slice type ${slice.type}`)
                return null
            }
            return (
              <Wrapper key={i} {...props}>
                {children}
              </Wrapper>
            )
          })}
        </div>
        { firstSocialLink && <div><SocialFooterLinks slice={firstSocialLink} /></div> }
        <div className={classes.boxesContainer}>
          <BoxRow boxes={BOXES} boxSpacing='10' />
        </div>
      </footer>
    )
  }
}

function mapStateToProps (state) {
  return {
    copyright: getCopyright(state),
    slices: getFooterSlices(state)
  }
}

export default flow([
  injectSheet((theme) => ({
    warChildLogo: {
      display: 'block',
      width: 'auto',
      height: 60,
      padding: 1,
      color: theme.colors.white,
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        height: 70
      },
      margin: '0 auto',
      marginRight: '6px'
    },
    footer: {
      backgroundColor: theme.colors.black,
      color: theme.colors.white,
      minHeight: 80,
      display: 'block',
      overflow: 'hidden'
    },
    footerTopSection: {
      color: theme.colors.white,
      minHeight: 80,
      gap: '50px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      padding: [40, theme.spacingPx.sm],
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        padding: [40, theme.sp(6)]
      },
      letterSpacing: '0.15em'
    },
    logo: {
      display: 'block',
      filter: 'invert(100%)',
      width: 40,
      height: 29,
      [theme.breakpoints.up('sm')]: {
        width: 46,
        height: 33
      }
    },
    footerItem: {
      // height: 80,
      minHeight: '150px',
      width: '250px',
      paddingTop: '20px',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: theme.fonts.sub,
      fontSize: '12px',
      letterSpacing: '0.05em',
      lineHeight: 1.2,
      color: 'inherit',
      textDecoration: 'none',
      '& a': {
        textDecoration: 'underline',
        color: 'currentColor'
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px'
      }
    },
    circularText: {
      display: 'block',
      width: 52,
      height: 52
    },
    copyright: {
      padding: [theme.spacing.md, 0],
      margin: [0, theme.spacing.sm]
    },
    siteByLink: {
      color: 'inherit',
      textDecoration: 'none'
    },
    contact: {
      '& a': {
        display: 'block',
        textDecoration: 'none',
        color: 'currentColor'
      },
      '& a.email': {
        textTransform: 'none',
        textDecoration: 'underline'
      }
    },
    mainLogo: {
      display: 'block',
      filter: 'invert(100%)',
      width: 'auto',
      height: 80,
      color: theme.colors.white,
      justifyContent: 'center',
      margin: '0 auto'
    },
    // warChildLogo: {
    //   display: 'block',
    //   width: 'auto',
    //   height: 80,
    //   padding: 1,
    //   color: theme.colors.white,
    //   margin: '0 auto',
    //   marginRight: '6px'
    // },
    // mainLogoContainer: {
    //   display: 'flex',
    //   minHeight: '100px',
    //   flexShrink: 0,
    //   '-webkit-tap-highlight-color': 'transparent',
    //   flexDirection: 'row',
    //   justifyContent: 'center'
    // },
    customText: {
      textAlign: 'center',
      alignContent: 'flex-start',
      '.p': {
        textTransform: 'default'
      }
    }
  })),

  connect(mapStateToProps)
])(Footer)

const BOX_HEIGHT = 170
const BOX_RADIUS = 7

const BOX_COLUMNS_SET = [
  { b: '#FEC600', c: '#FF6C60', hc: 'top' },
  { b: '#F0441E', c: '#FF9BD9' },
  { b: '#4AE0FD', c: '#C1F5FF', hc: 'top' }
]

const BOXES = Array(10).fill(BOX_COLUMNS_SET).flat()

const BoxRow = ({ offset = 0, boxes = [], boxSpacing }) => {
  const classes = useStylesBoxColumn({ offset, boxSpacing })
  return (
    <div className={classes.boxRow}>
      {boxes.map(({ b, c, hc }, id) => (
        <RecordBox
          key={id}
          boxColor={b}
          circleColor={c}
          {...(hc ? { isHalfPosition: hc } : {})}
          boxHeight={BOX_HEIGHT}
          boxBorderRadius={BOX_RADIUS}
        />
      ))}
    </div>
  )
}

const useStylesBoxColumn = createUseStyles({
  boxRow: ({ offset, boxSpacing }) => ({
    paddingTop: `${offset}px`,
    display: 'flex',
    flexDirection: 'row',
    gap: `${boxSpacing}px`,
    position: 'relative',
    bottom: `-${BOX_HEIGHT / 2}px`,
    left: `-${BOX_HEIGHT / 2}px`,
    marginTop: `-${BOX_HEIGHT / 2}px`
  })
})
