export const NOTIFICATION_DISMISS = 'NOTIFICATION_DISMISS'
export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW'

export const notificationShow = () => {
  return { type: NOTIFICATION_SHOW }
}

export const notificationDismiss = (content) => {
  return { type: NOTIFICATION_DISMISS }
}
