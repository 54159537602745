import React, { PureComponent } from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import flow from 'lodash/flow'
import Dropzone from 'react-dropzone'
import { getFileUploadStatus, getFileUploadFile, getFileUploadProgress, getFileUploadCachedFile } from '../../selectors'
import first from 'lodash/first'
import { uploadFile, cancelFileUpload, removeUploadedFile } from '../../actions'
import Checkmark from '../widgets/Checkmark'
import Progress from './Progress'
import theme from '../../styles/theme'

const MIN_FILE_UPLOAD_SIZE_BYTES = 1024 * 1
const MAX_FILE_UPLOAD_SIZE_BYTES = 1024 * 1024 * 10

class FileUpload extends PureComponent {
    _handleDrop = (accepted, rejected) => {
      var file = first(accepted)
      if (!file) return

      const { uploadFile } = this.props
      uploadFile(file)
    }

    _removeFile = (e) => {
      const { removeUploadedFile, cached_file: cachedFile } = this.props
      removeUploadedFile(cachedFile)
      e.stopPropagation()
    }

    _cancel = (e) => {
      const { cancelUpload } = this.props
      cancelUpload()
      e.stopPropagation()
    }

    render () {
      const { classes, className, status, file, progress } = this.props

      const Field = ({ text, status }) => (
        <div className={className}>
          <div className={cn('fileUpload', status)}>
            <span className='text'>{text}</span>
            <span className='validation'>*</span>
          </div>
        </div>
      )

      return <Dropzone onDrop={this._handleDrop} style={{ width: '100%' }}
        mutiple='false'
        maxSize={MAX_FILE_UPLOAD_SIZE_BYTES}
        minSize={MIN_FILE_UPLOAD_SIZE_BYTES}
        accept='image/jpeg,image/tiff,application/pdf'
        disabled={status === 'uploading'}>
        {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
          if (isDragActive) {
            return <Field text='Drop Your File Here' />
          }

          if (rejectedFiles && rejectedFiles.length) {
            if (rejectedFiles[0].size < MIN_FILE_UPLOAD_SIZE_BYTES) return <Field text={`File is too small, it must be at least ${MIN_FILE_UPLOAD_SIZE_BYTES / 1024} KB.`} status='error' />
            if (rejectedFiles[0].size < MAX_FILE_UPLOAD_SIZE_BYTES) return <Field text={`File is too big, it must be less than ${MAX_FILE_UPLOAD_SIZE_BYTES / (1024 * 1024)} MB.`} status='error' />
            return <Field text="I don't like that file, try another" status='error' />
          }

          if (status === 'error') { return <Field text='Oops, please try that again' status={status} /> }

          if (status === 'idle') { return <Field style={{ fontSize: '20px' }} text='Click or drag here to upload' status={status} /> }

          if (status === 'uploading') {
            return <div className={className}>
              <div className={cn('fileUpload', status)}>
                <Progress progress={progress} handleCancel={this._cancel} />
              </div>
            </div>
          }

          return <div className={className}>
            <div className={cn('fileUpload', status)}>
              {status === 'success' &&
                            [
                              <div key='field' className={classes.fileUploadResult}>
                                <Checkmark show='true' width={32} height={32} background={theme.colors.teal} />
                                {`Your file '${file.name}' is ready to be submitted`}
                              </div>,
                              <span key='remove' className={cn(classes.fileUploadRemove, 'close')} onClick={this._removeFile}>x</span>
                            ]
              }
            </div>
          </div>
        }}
      </Dropzone>
    }
}

const mapStateToProps = (state) => ({
  status: getFileUploadStatus(state),
  file: getFileUploadFile(state),
  progress: getFileUploadProgress(state),
  cache_file: getFileUploadCachedFile(state)
})

function mapDispatchToProps (dispatch) {
  return {
    uploadFile: (file) => dispatch(uploadFile(file)),
    cancelUpload: () => dispatch(cancelFileUpload()),
    removeUploadedFile: (cachedFile) => dispatch(removeUploadedFile(cachedFile))
  }
}

export default flow([
  injectSheet((theme) => ({
    fileUploadResult: {
      display: 'flex',
      alignItems: 'center'
    }
  })),
  connect(mapStateToProps, mapDispatchToProps)
])(FileUpload)
