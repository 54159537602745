import React, { Component } from 'react'
import injectSheet from 'react-jss'
import flow from 'lodash/flow'
import { expandAbsolutely } from '../../styles/theme'
import offsetParent from 'dom-helpers/query/offsetParent'
import elementResizeDetectorMaker from 'element-resize-detector'
import { fitTextToContainer } from '../../utils/text'
import { formatDate } from '../../utils/date'
import TileContainer from './TileContainer'
import outlineText from '../../styles/outlineText'
import ImageHover, { IMAGE_TRANSITION_DURATION } from '../widgets/ImageHover'
import detectIt from 'detect-it'

class TextTile extends Component {
  componentDidMount () {
    this.fit()
    this.erd = elementResizeDetectorMaker({ strategy: 'scroll' })
    this.erd.listenTo(offsetParent(this._containerRef), this.fit)
  }

  componentWillUnmount () {
    if (this.erd && this._containerRef) { this.erd.removeAllListeners(offsetParent(this._containerRef)) }
  }

    fit = () => {
      fitTextToContainer(
        offsetParent(this._containerRef),
        this._containerRef,
        [this._titleRef],
        [48, 20]
      )
    }

    _setContainerRef = (ref) => { this._containerRef = ref }

    _setTitleRef = (ref) => { this._titleRef = ref }

    _setCopyRef = (ref) => { this._copyRef = ref }

    render () {
      const {
        classes, title, link,
        color, published_date: publishedDate, cta_text: ctaText,
        image
      } = this.props

      return <TileContainer link={link} color={color} cta_text={ctaText} className={classes.tile} >
        {image && <ImageHover className={classes.image} classNames={{ overlayImage: classes.overlayImage }} image={image} />}
        <div className={classes.content}>
          <div className={classes.container} ref={this._setContainerRef}>
            {publishedDate && <time className={classes.date} dateTime={publishedDate}>{formatDate(publishedDate)}</time>}
            <h2 className={classes.title} ref={this._setTitleRef} >{title && title.text}</h2>
          </div>
        </div>
      </TileContainer>
    }
}

export default flow([
  injectSheet(theme => ({
    container: {
      position: 'relative'
    },
    tile: {
      color: theme.colors.secondary,
      ...(detectIt.primaryInput === 'touch' ? {} : {
        '&:hover $overlayImage': {
          opacity: 0
        }
      }),
      '&:hover': {
        color: theme.colors.white
      }
    },
    content: {
      ...expandAbsolutely,
      overflow: 'visible',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
      textDecoration: 'none',
      padding: theme.spacing.md,
      transition: `color ${IMAGE_TRANSITION_DURATION}s ease-in-out`,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing.lg
      },
      '& > *': {
        // without this, the container doesn't grow to fit its content on IE11, which stops fitTextToContainer()
        // from working. Come to think of it I don't know why this worked on other browsers either, the default
        // is supposed to be "0 1 auto".
        flex: '0 0 auto'
      }
    },
    title: {
      textAlign: 'center',
      position: 'relative',
      fontFamily: theme.fonts.heading,
      textTransform: 'uppercase',
      lineHeight: 1,
      fontSize: theme.getRemValue(48),
      marginBottom: 0
    },
    copy: {
      textAlign: 'center',
      position: 'relative',
      lineHeight: 1.6,
      fontSize: theme.getRemValue(16),
      '& p': {
        margin: 0
      }
    },
    date: {
      textAlign: 'center',
      display: 'block',
      textTransform: 'uppercase',
      fontSize: theme.getRemValue(16),
      fontWeight: 'bold',
      marginBottom: theme.sp(2)
    },
    button: {
      marginTop: theme.sp(2),
      display: 'inline-block',
      color: 'transparent',
      ...outlineText(theme.colors.white),
      textTransform: 'uppercase',
      fontSize: theme.getRemValue(40),
      textDecoration: 'none',
      fontFamily: theme.fonts.heading,
      '&:hover': {
        color: 'currentColor'
      }
    },
    image: {
      ...expandAbsolutely
    },
    overlayImage: {}
  }))
])(TextTile)
