import React, { Component } from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import Transition from 'react-transition-group/Transition'
import injectSheet from 'react-jss'
import flow from 'lodash/flow'
import { getSubscriptionStatus, getSubscriptionMessage } from './../selectors'
import { subscribe, subscribeValid } from './../actions'
import { validateEmail } from './../utils/validation'
import Checkmark from './widgets/Checkmark'
import RichTextContainer from './widgets/RichTextContainer'

const ErrorMessage = ({ classes, className, show, message }) => (
  <Transition in={show} timeout={300}>
    {(state) => (
      <div className={cn(classes.error, className, state)}>{message}</div>
    )}
  </Transition>
)

class SubscriptionTile extends Component {
  constructor (props) {
    super(props)
    this.state = { email: '', name: '' }
  }

    onSubmit = (e) => {
      e.preventDefault()
      const { subscribe, subscribeValid, status } = this.props
      if (status === 'loading') return

      if (!this.state.email) {
        subscribeValid(false, 'Your email is required')
        return
      }
      if (!validateEmail(this.state.email)) {
        subscribeValid(false, 'You have entered a invalid email')
        return
      }

      subscribe({ email: this.state.email, name: this.state.name })
    }

    isFormValid = () => {
      const { subscribeValid } = this.props
      if (this.state.email.length > 0 && this.state.name.length > 0) {
        subscribeValid(true)
      }
    }

    onEmailChanged = (event) => {
      this.isFormValid()
      this.setState({ email: event.target.value })
    }

    onNameChanged = (event) => {
      this.isFormValid()
      this.setState({ name: event.target.value })
    }

    render () {
      const {
        classes, className, inputClassName, buttonClassName,
        errorClassName, cta, status, optInText, copy, thankYouMessage,
        message, optInStatement
      } = this.props
      const { email, name } = this.state

      const loading = status === 'loading' || status === 'success'
      const ctaLabel = status === 'loading' ? 'Please wait...'
        : status === 'success' ? 'Thank you' : cta
      const errorMessage = status !== 'success' && message

      if (status === 'success') {
        return (
          <div className={classes.successWrapper}>
            <RichTextContainer content={thankYouMessage && thankYouMessage.html} />
            {status === 'success' && <span className={classes.spinner}>
              <Checkmark show />
            </span> }
            <div className={classes.optInStatement} dangerouslySetInnerHTML={{ __html: optInStatement && optInStatement.html }} />
          </div>
        )
      }

      return (
        <form className={cn(classes.subForm, className)} noValidate>
          {copy && (
            <div className={classes.copyContainer}>
              <RichTextContainer content={copy && copy.html} />
            </div>
          )}

          <ErrorMessage show={!!errorMessage}
            classes={classes}
            className={errorClassName}
            message={errorMessage}
          />
          <div className={classes.fieldRows}>
            <label>Full Name</label>
            <input className={cn(classes.input, inputClassName, { loading: loading })} type='name' defaultValue='' name='Name' required
              placeholder={'Full Name'}
              value={name}
              onChange={this.onNameChanged}
            />
          </div>
          <div className={classes.fieldRows}>
            <label>Email</label>
            <input className={cn(classes.input, inputClassName, { loading: loading })} type='email' defaultValue='' name='Email' required
              placeholder={'Email'}
              value={email}
              onChange={this.onEmailChanged}
            />
          </div>
          <div>
            <div className={classes.title} dangerouslySetInnerHTML={{ __html: optInText && optInText.html }} />
            <label className={classes.consentStatementContainer}>
              <div className={classes.checkboxLabel}>Opt-in</div>
              <input type='checkbox' name='opt_in' value='' style={{ width: '30px', height: '30px' }} />
            </label>
          </div>
          <div className={classes.buttonWrapper}>
            <button className={cn(classes.button, buttonClassName, { loading: loading })} onClick={this.onSubmit} type='submit' >
              {ctaLabel}
            </button>
          </div>
          <div className={classes.successWrapper}>
            {status === 'success' && <span className={classes.spinner}><Checkmark show /></span> }
          </div>
          <div className={classes.optInStatement} dangerouslySetInnerHTML={{ __html: optInStatement && optInStatement.html }} />
        </form>
      )
    }
}

function mapStateToProps (state) {
  return {
    status: getSubscriptionStatus(state),
    message: getSubscriptionMessage(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    subscribe: (data) => dispatch(subscribe(data)),
    subscribeValid: (valid, message) => dispatch(subscribeValid(valid, message))
  }
}

export default flow([
  injectSheet(theme => ({
    subForm: {
      width: '100%',
      maxWidth: '800px'
      // display: 'flex'
      // position: 'relative',
      // '&:after': {
      //   content: '""',
      //   position: 'absolute',
      //   bottom: 0,
      //   left: 0,
      //   right: 0,
      //   borderBottom: [1, 'solid', 'currentColor']
      // }
    },
    fieldRows: {
      paddingBottom: '20px',
      '& label': {
        fontSize: theme.getRemValue(20),
        color: theme.colors.black
      }
    },
    input: {
      width: '100%',
      height: '70px',
      // backgroundColor: 'transparent',
      borderRadius: '4px',
      border: `1px solid ${theme.colors.lightBlue}`,
      // border: 'none',
      // borderBottom: [1, 'solid', theme.colors.primary],
      // fontSize: theme.getRemValue(24),
      // fontFamily: theme.fonts.heading,
      padding: '16px 26px 16px 24px',
      fontSize: theme.getRemValue(22),
      ...theme.mixin.placeholder({ color: '#BABABA' })
      // flex: '1 1 0px',
      // position: 'relative',
      // fontFamily: theme.fonts.heading,
      // fontSize: theme.getRemValue(24),
      // padding: 0,
      // border: 'none',
      // minWidth: 0,
      // // transition: 'all 0.25s',
      // borderRadius: 0,
      // backgroundColor: 'transparent',
      // color: 'currentColor',
      // '&.loading': {
      //   flex: 0,
      //   padding: 0,
      //   width: 0
      // },
      // [theme.breakpoints.up('md')]: {
      //   fontSize: theme.getRemValue(34)
      // }
    },
    button: {
      width: '100%',
      border: 'none',
      padding: '16px 40px',
      borderRadius: '30px',
      background: theme.colors.darkBlue,
      textDecoration: 'none',
      color: theme.colors.white,
      transition: 'all 0.3s',
      fontFamily: theme.fonts.heading,
      fontSize: theme.getRemValue(22),
      marginTop: theme.sp(3),
      display: 'inline-block',
      '&:hover': {
        color: theme.colors.white,
        backgroundColor: '#4878FF',
        cursor: 'pointer'
      }
    },
    buttonWrapper: {
      textAlign: 'center'
    },
    error: {
      color: theme.colors.primary,
      fontWeight: 700,
      height: 32,
      fontSize: theme.getRemValue(16),
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -40,
      transition: 'transform 0.15s ease',
      transform: 'translateY(0)',
      display: 'flex',
      alignItems: 'center',
      zIndex: 1
    },
    buttonIcon: {
      position: 'absolute',
      left: '50%',
      right: 0,
      color: theme.colors.peach,
      transition: 'transform 0.15s ease-in-out',
      transform: 'translate(-50%, 0)',
      height: 120,
      width: 120,
      pointerEvents: 'none',
      [theme.breakpoints.up('md')]: {
        height: 150,
        width: 150
      }
    },
    successWrapper: {
      marginTop: theme.sp(1),
      marginBottom: theme.sp(2),
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    consentStatementContainer: {
      display: 'flex',
      flexDirection: 'rows'
    },
    checkboxLabel: {
      marginRight: 20
    },
    optInStatement: {
      fontSize: 16
    }
  })),
  connect(mapStateToProps, mapDispatchToProps)
])(SubscriptionTile)
