/* eslint-env browser */
import inDOM from 'dom-helpers/util/inDOM'
import theme from './theme'

const supportsTextStroke = !inDOM || (() => {
  const el = document.createElement('a')
  el.style.cssText = '-webkit-text-stroke:red;'
  return !!el.style.length
})()

export default function outlineText (color = theme.colors.black, size = 1) {
  return {
    '-webkit-text-stroke': `${size}px ${color}`,
    textShadow: supportsTextStroke
      ? ''
      : `${size}px ${size}px 0 ${color}, -${size}px -${size}px 0 ${color}, ${size}px -${size}px 0 ${color}, -${size}px ${size}px 0 ${color}, ${size}px ${size}px 0 ${color}`

  }
}
