import React from 'react'
import cn from 'classnames'
import get from 'lodash/get'
import { createUseStyles } from 'react-jss'
import theme from '../styles/theme'
import outlineText from '../styles/outlineText'

const Title = ({ className, title, color = theme.colors.black }) => {
  const classes = useStyles({ color })
  const html = get(title, ['html'], title)
  if (!html) return null
  return <span className={cn(classes.title, className)} dangerouslySetInnerHTML={{ __html: html }} />
}

const useStyles = createUseStyles({
  title: ({ color }) => ({
    display: 'block',
    '& h1': {
      color: 'currentColor',
      fontSize: theme.getRemValue(60),
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: theme.getRemValue(80)
      }
    },
    '& h2': {
      color: 'currentColor',
      fontSize: theme.getRemValue(30),
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: theme.getRemValue(60)
      }
    },
    '& span.highlight': {
      color: 'transparent',
      ...outlineText(color, 1)
    }
  })
}, { name: 'Title' })

export default Title
