import React from 'react'
import { createUseStyles } from 'react-jss'
import Link from '../Link'
import cn from 'classnames'

const QuickLinks = ({ slice }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <h3 className={classes.title} >{slice.title.text}</h3>
      <ul className={classes.list} >
        {slice.items.map((item, id) =>
          <li key={item.link_text.text} className={classes.listItem} >
            <Link className={classes.ListItemLink} to={item.link && item.link.url} target={item.link && item.link.target}>{item.link_text && item.link_text.text}</Link>
          </li>
        )}
        <li key={'cookies'} className={classes.listItem} >
          <a href='#' className={cn('ot-sdk-show-settings', classes.ListItemLink)}>Cookies</a>
        </li>
      </ul>
    </div>
  )
}

export default QuickLinks

const useStyles = createUseStyles({
  container: {
    display: 'block',
    color: 'inherit'
  },
  title: {
    textAlign: 'center'
  },
  list: {
    textAlign: 'center',
    listStyleType: 'none',
    padding: 0
  },
  listItem: {
    marginBottom: '10px'
  },
  ListItemLink: {
    color: 'inherit',
    textDecoration: 'none'
  }
})
