import React, { PureComponent } from 'react'
import cn from 'classnames'
import injectSheet from 'react-jss'
import flow from 'lodash/flow'

class Section extends PureComponent {
  render () {
    const { classes, className, sectionClassName, children, fullWidth = false } = this.props
    return <section className={cn(classes.section, { [classes.contained]: !fullWidth }, sectionClassName)}>
      <div className={className}>
        {children}
      </div>
    </section>
  }
}

export default flow([
  injectSheet(theme => ({
    section: {
      backgroundColor: ({ color }) => color || 'inherit',
      margin: 0
    },
    contained: {
    }
  }))
])(Section)
