import React, { useMemo } from 'react'
import HeroBanner from './HeroBanner'
import Banners from './Banners'
import Collaborators from './Collaborators'
import Sponsors from './Sponsors'

import SideBySide from './SideBySide'
// import MusicTiles from './MusicTiles'
import TrackList from './TrackList'
import SubmitYourSleeve from './SubmitYourSleeve'
import Tiles from '../Tiles'
import theme from '../../styles/theme'
import Intro from './Intro'
import Subscribe from './Subscribe'
import Eventbrite from './Eventbrite'

const sliceComponentSelector = {
  banner: Banners,
  tiles: ({ slice, page }) => <Tiles title={slice.slice_title} tiles={page.tiles} color={theme.colors.gold} backgroundColor={theme.colors.gold} showArticleImages />,
  music_tiles: ({ slice, page }) => <TrackList tiles={page.tiles_music} title={slice.slice_title} />,
  side_by_side_content: SideBySide,
  collaborators: Collaborators,
  sponsors: Sponsors,
  hero_banner: HeroBanner,
  intro: Intro,
  submit_your_sleeve: SubmitYourSleeve,
  subscribe: ({ slice, page }) => {
    return (<Subscribe title={slice.title} ctaText={slice.cta_text} optInText={slice.opt_in_text} copy={slice.copy} thankYouMessage={slice.thank_you_message} optInStatement={slice.opt_in_statement} />)
  },
  eventbrite: ({ slice }) => {
    return (<Eventbrite backgroundStyle={slice.background_style} />)
  }
}

const Home = ({ page }) => {
  var slices = useMemo(() => page.slices
    .map((slice, i) => {
      const Component = sliceComponentSelector[slice.type]
      if (!Component) {
        console.warn(`Unknown slice type ${slice.type}`)
        return null
      }
      return <Component key={i} slice={slice} page={page} />
    }), [page])

  return (
    <>
      {slices}
    </>
  )
}

export default Home
