import { put, takeLatest, call, take } from 'redux-saga/effects'
import createFileUploadChannel from './channels/createFileUploadChannel'
import { getApiUrl } from '../config'

import {
  UPLOAD_FILE,
  CANCEL_FILE_UPLOAD,
  REMOVE_UPLOADED_FILE,
  uploadFileError,
  updateUploadFileProgress,
  uploadFileSuccess,
  removeUploadedFile
} from '../actions'

var uploadFileRequest

function * cancelFileUpload (action) {
  if (uploadFileRequest) uploadFileRequest.abort()
  yield put(removeUploadedFile())
}

function * removeUploadedFileSaga (action) {
  if (!action.payload) return
  yield call(fetch, getApiUrl() + `/submission/upload/${action.payload.key}`, { method: 'delete' })
}

function * uploadFile (action) {
  try {
    var data = new FormData() // eslint-disable-line
    data.append('file', action.payload.file)
    uploadFileRequest = new XMLHttpRequest() // eslint-disable-line
    uploadFileRequest.open('POST', getApiUrl() + '/submission/upload')

    const channel = yield call(createFileUploadChannel, uploadFileRequest, data)
    while (true) {
      const { progress = 0, err, success, response } = yield take(channel)
      if (err) {
        yield put(uploadFileError(err))
        return
      }
      if (success) {
        yield put(uploadFileSuccess(response))
        return
      }
      yield put(updateUploadFileProgress(progress))
    }
  } catch (e) {
    yield put(uploadFileError(e.message))
  }
}

function * submissionSaga () {
  yield takeLatest(UPLOAD_FILE, uploadFile)
  yield takeLatest(CANCEL_FILE_UPLOAD, cancelFileUpload)
  yield takeLatest(REMOVE_UPLOADED_FILE, removeUploadedFileSaga)
}

export default submissionSaga
