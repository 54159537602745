import React, { PureComponent } from 'react'
import cn from 'classnames'
import injectSheet from 'react-jss'
import flow from 'lodash/flow'
import Transition from 'react-transition-group/Transition'
import { getTextWidth } from '../../utils/text'
import map from 'lodash/map'
import Waypoint from 'react-waypoint'
import theme from '../../styles/theme'

const RADIUS = 50
const INNER_RADIUS_MARGIN = 45
const LETTER_SPACING = 0.1

const linear = (t) => { return t }
// const easeInQuad = (t) => { return t*t };
// const easeOutQuad = (t) =>{ return t*(2-t) };
// const easeOutCubic = (t) => { return (--t)*t*t+1 }

const ANIMATION_TIME = 30

class Character extends PureComponent {
  render () {
    const { classes, character, animate, delay } = this.props
    return <Transition in={animate} timeout={delay * ANIMATION_TIME}>
      {(state) => (
        <tspan className={classes[state]}>{character}</tspan>
      )}
    </Transition>
  }
}

const CharacterWithDelay = injectSheet(theme => ({
  entering: {
    fill: 'transparent'
  },
  entered: {
    fill: 'inherit'
  }
}))(Character)

class CircularText extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fontSize: 14,
      textLength: undefined,
      innerCircleRadius: 35,
      animate: false
    }
  }

    circlePath = (cx, cy, r) => {
      return `M ${cx} ${cy} m -${r} 0 a ${r} ${r} 0 0 1 ${r * 2} 0 a ${r} ${r} 0 0 1 -${r * 2} 0`
    }

    componentDidMount () {
      const { children: text } = this.props
      const measureFontSize = 10

      const widthAtXPx = getTextWidth(text.toUpperCase() + 'xx', `normal ${measureFontSize}px ${theme.fonts.heading}`)

      const lineWidthWithSpacing = widthAtXPx.width + ((text.length) * measureFontSize * LETTER_SPACING)
      const circumference = Math.PI * INNER_RADIUS_MARGIN * 2
      const fontSize = Math.min(circumference / lineWidthWithSpacing * measureFontSize, 30)
      const innerRatio = (INNER_RADIUS_MARGIN - (fontSize / 2)) / INNER_RADIUS_MARGIN

      this.setState({
        fontSize: (innerRatio * fontSize),
        textLength: (innerRatio * circumference) - 10,
        innerCircleRadius: (innerRatio * INNER_RADIUS_MARGIN)
      })
    }

    handleWaypointEnter = () => {
      this.setState({ animate: true })
    }

    handleWaypointLeave = () => {
      this.setState({ animate: false })
    }

    render () {
      const { className, classes, children: text, fillColor = 'transparent', color = theme.colors.black } = this.props
      const { fontSize, textLength, innerCircleRadius, animate } = this.state

      return <div className={className}>
        <Waypoint
          onEnter={this.handleWaypointEnter}
          onLeave={this.handleWaypointLeave}>
          <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' className={cn(classes.circle, { animate })} >
            <circle id='circle' fill={fillColor} cx={RADIUS} cy={RADIUS} r={RADIUS} />
            <path id='curve' d={this.circlePath(RADIUS, RADIUS, innerCircleRadius)} fill='none' />
            <text fontFamily={theme.fonts.heading} fontSize={fontSize} textLength={textLength} letterSpacing={`${LETTER_SPACING}em`}>
              <textPath alignmentBaseline='top' xlinkHref='#curve' fill={color}>
                {map(text.toUpperCase(), (c, i) =>
                  <CharacterWithDelay key={i} character={c} delay={linear(i / (text.length - 1)) * text.length} animate={animate} />
                )}
              </textPath>
            </text>
          </svg>
        </Waypoint>

      </div>
    }
}

export default flow([
  injectSheet(theme => ({
    '@keyframes rotate': {
      '0%': { transform: 'rotate(45deg)' },
      '0.1%': { transform: 'rotate(360deg)' },
      '100%': { transform: 'rotate(45deg)' }
    },
    circle: {
      width: 300,
      height: 300,
      maxHeight: '100%',
      maxWidth: '100%',
      transform: 'rotate(45deg)',
      '&.animate': {
        animationDuration: ({ children: text }) => `${(text.length + 2) * ANIMATION_TIME}ms`,
        animationTimingFunction: 'ease-out',
        animationName: '$rotate'
      }
    },

    title: {
      fontSize: 30,
      fontFamily: theme.fonts.heading,
      lineHeight: 1.2,
      [theme.breakpoints.up('sm')]: {
        fontSize: 40
      }
    },
    copy: {
      fontSize: 16,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20
      }
    }
  }))
])(CircularText)
