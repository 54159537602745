import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Link from '../Link'
import get from 'lodash/get'
import PageWidth from '../PageWidth'
import theme from '../../styles/theme'

const Sponsors = ({ slice: { type, items, title }, variation }) => {
  const classes = useStyles()
  const Container = ({ item, children }) => item.link && item.link.url
    ? <Link to={item.link.url} target={item.link.target} className={classes.link}>{children}</Link>
    : children

  return (
    <section className={classes.section}>
      <PageWidth>
        <div className={classes.wrapper}>
          <h2 className={classes.title}>{title.text}</h2>
          <div className={classes.collaborators}>
            {items.map((item, i) => <div key={i} className={cn(classes.imageContainer, { small: type === 'sponsors' })}>
              <Container item={item}>
                <img className={classes.image} src={get(item, ['image', 'images', 0, 'url'])} alt={item.title} />
              </Container>
            </div>)}
          </div>
        </div>
      </PageWidth>
    </section>
  )
}

const useStyles = createUseStyles({
  link: {
    width: '100%',
    textAlign: 'center'
  },
  section: {
    backgroundColor: theme.colors.white,
    marginBottom: 0,
    overflow: 'hidden'
  },
  wrapper: {
    marginTop: '80px',
    marginBottom: '60px'
  },
  sponsors: {
    paddingTop: 0
  },
  title: {
    textAlign: 'center',
    fontSize: theme.getRemValue(30),
    letterSpacing: 10,
    [theme.breakpoints.up('md')]: {
      letterSpacing: 20,
      fontSize: theme.getRemValue(40)
    }
  },
  collaborators: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'center',
    gap: '50px'
  },
  imageContainer: {
    padding: theme.spacing.sm,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
    // width: '100%'
    // [theme.breakpoints.up('md')]: {
    //   // width: '33%',
    //   padding: theme.spacing.lg
    // },
    // '&.small': {
    //   width: '50%',
    //   [theme.breakpoints.up('md')]: {
    //     // width: '33%'
    //   },
    //   [theme.breakpoints.up('lg')]: {
    //     // width: '25%'
    //   }
    // }
  },
  image: {
    height: '175px'
    // [theme.breakpoints.up('md')]: {
    //   height: '200px'
    // }
  }
})

export default Sponsors
