import React from 'react'
import injectStyles from 'react-jss'
import cn from 'classnames'
import flow from 'lodash/flow'

const CloseIcon = ({ classes, className }) => {
  return <div className={classes.container}>
    <div className={cn(classes.leftLine, className)} />
    <div className={cn(classes.rightLine, className)} />
  </div>
}

export default flow([
  injectStyles((theme) => ({
    container: {
      width: ({ size }) => size || 30,
      height: ({ size }) => size || 30,
      position: 'relative',
      cursor: 'pointer',
      transition: 'all 0.1s',
      '&:hover': {
        transform: 'rotate(-90deg)'
      }
    },
    line: {
      position: 'absolute',
      height: 4,
      top: '50%',
      width: '100%',
      backgroundColor: theme.colors.black,
      transition: 'background-color 0.1s',
      '$container:hover &': {
        backgroundColor: theme.colors.peach
      }
    },
    leftLine: {
      extend: 'line',
      transform: 'translate(0, -50%) rotate(45deg)'
    },
    rightLine: {
      extend: 'line',
      transform: 'translate(0, -50%) rotate(-45deg)'
    }
  }))
])(CloseIcon)
