import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import injectStyles from 'react-jss'
import flow from 'lodash/flow'
import { nextGalleryPage, setGalleryFilter } from '../../actions'
import {
  getGalleryPage,
  getGalleryLimit,
  getGalleryTotal,
  getTracks,
  getGalleryTrackFilter,
  getGalleryQueryFilter,
  getSelectedGalleryItem
} from '../../selectors'
import Waypoint from 'react-waypoint'
import theme, { expandAbsolutely } from '../../styles/theme'
import filter from 'lodash/filter'
import GalleryItemDialog from './GalleryItemDialog'
import inDOM from 'dom-helpers/util/inDOM'
import GalleryItem from './GalleryItem'
import ArticleHero from '../ArticleHero'

const Select = ({ classes, className, value, onChange, emptyText, children, ...rest }) => (
  <div className={className}>
    <select value={value} {...rest} onChange={onChange}>
      {children}
    </select>
    <input value={value} {...rest} placeholder={emptyText} onChange={() => {}} />
  </div>
)

class Gallery extends Component {
    state = {
      ssr: true,
      tracks: []
    }

    componentDidMount () {
      if (inDOM && !this.state.ssr) { this.setState({ ssr: true }) }
    }

    _handleWaypointEnter = () => {
      const { pageIndex, limit, total } = this.props
      if ((pageIndex + 1) * limit >= total) return
      this.props.handleNextPage()
    }

    _onTrackChanged = (event) => {
      const { handleSetFilter, query } = this.props
      handleSetFilter(query, event.target.value)
    }

    _onSearchChanged = (event) => {
      const { handleSetFilter, track } = this.props
      handleSetFilter(event.target.value, track)
    }

    _getItems = () => {
      const { page, pageIndex, limit, track, query } = this.props
      var items = page.items
      if (!items) return []

      this.state.tracks = items.reduce((acc, i) => {
        if (acc.includes(i.track)) return acc
        acc.push(i.track)
        return acc
      }, []).sort()

      const uppercaseQuery = query.toUpperCase()
      if (track) items = filter(items, item => item.track.toUpperCase() === track)
      // if (query) items = filter(items, item => item.name && item.name.toUpperCase().indexOf(uppercaseQuery) >= 0 || (item.number + '') === uppercaseQuery)
      if (query) items = items.filter((item) => parseInt(item.number.toUpperCase().replace('#', ''), 10) === parseInt(uppercaseQuery.replace('#', ''), 10)).slice(0, 1)
      return items.slice(0, (pageIndex + 1) * limit)
    }

    _onDialogClose = (event) => {
      const { page, handleDialogClose } = this.props
      handleDialogClose(page.uid)
    }

    render () {
      const { classes, page, track, query, selectedItem } = this.props
      const urlShowName = inDOM ? window.location.href.includes('name=show') : false
      const isPastShowNamesDateTime = new Date(1710957000 * 1000) < Date.now()
      const showNames = urlShowName || isPastShowNamesDateTime
      const showAuction = !!page.show_auction
      const auctionInstructionHtml = page.auction_instructions && page.auction_instructions.html
      const auctionButtonText = page.auction_button_text

      const items = this._getItems()

      return <Fragment>
        <ArticleHero title={page.title} copy={page.copy} color={theme.colors.peach} background={theme.colors.blue} />
        <div className={classes.galleryContainer}>
          <div className={classes.filter}>
            {showNames && (
              <div className={classes.field}>
                <Select classes={classes} onChange={this._onTrackChanged} value={track} className={classes.field}
                  emptyText='FILTER BY TRACK' name='track_select'>
                  <option key='all' value=''>ALL TRACKS</option>
                  {this.state.tracks.map(track => <option key={track} value={track.toUpperCase()}>{track.toUpperCase()}</option>)}
                </Select>
              </div>
            )}
            <div className={classes.field}>
              <input type='text' name='query' value={query.toUpperCase()} onChange={this._onSearchChanged}
                placeholder='SEARCH BY NUMBER 001' />
            </div>
          </div>
          <div className={classes.containerStyles}>
            {items.length === 0 && <div className={classes.noResults}>No Results</div>}
            {items.map((item, i) => (
              <GalleryItem key={item.number} page={page} item={item} index={i} showNames={showNames} />
            ))}
            <Waypoint onEnter={this._handleWaypointEnter} />
          </div>
        </div>

        <GalleryItemDialog
          showNames={showNames}
          showAuction={showAuction}
          item={selectedItem}
          show={!!selectedItem}
          onClose={this._onDialogClose}
          auctionInstructionHtml={auctionInstructionHtml}
          auctionButtonText={auctionButtonText}
        />

      </Fragment>
    }
}

const mapStateToProps = (state) => {
  return {
    pageIndex: getGalleryPage(state),
    limit: getGalleryLimit(state),
    total: getGalleryTotal(state),
    tracks: getTracks(state),
    track: getGalleryTrackFilter(state),
    query: getGalleryQueryFilter(state),
    selectedItem: getSelectedGalleryItem(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleNextPage: () => dispatch(nextGalleryPage()),
    handleSetFilter: (query, track) => dispatch(setGalleryFilter(query, track)),
    handleItemSelected: (id) => dispatch({ type: 'GALLERY_ROUTE', payload: { id } }),
    handleDialogClose: (uid) => dispatch({ type: 'GALLERY_ROUTE', payload: { uid } })
  }
}

export default flow([
  injectStyles((theme) => {
    var classes = {
      hero: {
        backgroundColor: theme.colors.peach,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.colors.white,
        minHeight: 320,
        [theme.breakpoints.up('md')]: {
          minHeight: 480
        },
        position: 'relative',
        padding: theme.spacing.md
      },
      filter: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'flex'
        }
      },
      galleryContainer: {
        padding: 0
      },
      containerStyles: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
        gridAutoRows: '1fr'
        // gap: '5px',
        // margin: '5px'
      },
      noResults: {
        margin: [150, theme.spacing.sm],
        fontSize: theme.getRemValue(40),
        fontFamily: theme.fonts.heading,
        textTransform: 'uppercase',
        textAlign: 'center',
        flex: 1,
        letterSpacing: 10.0
      },

      field: {
        position: 'relative',
        flex: '1 0 auto',
        // margin: theme.spacing.sm,
        display: 'flex',
        letterSpacing: 8,
        '& input, & textarea, & div.fileUpload': {
          backgroundColor: '#f9f9f9',
          border: 'none',
          width: '100%',
          fontSize: theme.getRemValue(24),
          fontFamily: theme.fonts.heading,
          padding: theme.spacing.md,
          textAlign: 'center',
          ...theme.mixin.placeholder({ color: '#AAA', opacity: 1 }),
          letterSpacing: 2,
          [theme.breakpoints.up('sm')]: {
            letterSpacing: 6
          }
        },
        '& span': {
          width: '1.5rem',
          height: '1.5rem',
          color: theme.colors.white,
          fontSize: theme.getRemValue(20),
          fontFamily: theme.fonts.body,

          lineHeight: 1.1,
          backgroundColor: theme.colors.teal,
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 0,
          textAlign: 'center',
          '&.validation': {
            fontSize: '2rem'
          }
        },
        '&.required:after': {
          width: '1.5rem',
          height: '1.5rem',
          color: theme.colors.white,
          fontSize: theme.getRemValue(32),
          lineHeight: 1.1,
          backgroundColor: theme.colors.teal,
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 0,
          textAlign: 'center'
        },

        '& .fileUpload': {
          cursor: 'pointer'
        },
        '& .idle': {
          color: '#ccc'
        },
        '& .error': {
          color: theme.colors.peach
        },
        '& .success': {
          color: theme.colors.teal
        },
        '& select': {
          ...expandAbsolutely,
          appearance: 'none',
          width: '100%',
          height: '100%', // required on IE11
          opacity: 0,
          cursor: 'pointer'
        },
        '& option': {
          fontSize: theme.getRemValue(18),
          textTransform: 'uppercase'
        }
      },
      hiddenField: {
        position: 'absolute',
        top: '50%',
        opacity: 0
      },
      disabled: {
        color: '#cccccc'
      }
    }

    return classes
  }),
  connect(mapStateToProps, mapDispatchToProps)
])(Gallery)
