export const SUBMIT_REQUEST = 'SUBMIT_REQUEST'
export const SUBMIT_ERROR = 'SUBMIT_ERROR'
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS'
export const SUBMIT_CLEAR = 'SUBMIT_CLEAR'

export const submit = (data) => {
  return { type: SUBMIT_REQUEST, payload: data }
}

export const submitError = (message) => {
  return { type: SUBMIT_ERROR, payload: { message } }
}

export const submitSuccess = () => {
  return { type: SUBMIT_SUCCESS }
}

export const submitClear = () => {
  return { type: SUBMIT_CLEAR }
}
