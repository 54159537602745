import React from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import RichTextContainer from '../widgets/RichTextContainer'
import theme, { expandAbsolutely } from '../../styles/theme'
import SubmissionForm from './SubmissionForm'
import Complete from './Complete'
import { getDonationAmount, getSubmissionStatus } from '../../selectors'
// import Section from '../widgets/Section'
import TopRecordBoxes from '../widgets/RecordBoxes/TopRecordBoxes'
import PageWidth from '../PageWidth'

const Submission = ({ page }) => {
  const classes = useStyles()
  const status = useSelector(getSubmissionStatus)
  const donation = useSelector(getDonationAmount)
  const {
    subtitle, title, banner_title: bannerTitle, banner_copy: copy,
    complete_title: completeTitle, complete_copy: completeCopy,
    after_donation_copy: afterDonationCopy, donation_link: donationLink
  } = page

  const submitted = status === 'success'

  return (
    <>
      <TopRecordBoxes />
      {submitted && (
        <PageWidth>
          <div className={classes.wrapper}>
            <Complete
              complete_title={completeTitle}
              complete_copy={completeCopy}
              donation={donation}
              afterDonationCopy={afterDonationCopy}
              donationLink={donationLink}
            />
          </div>
        </PageWidth>
      )}
      {!submitted && (
        <PageWidth>
          <div className={classes.content}>
            <div className={classes.infoSection}>
              <div dangerouslySetInnerHTML={{ __html: title.html }} />
              <div dangerouslySetInnerHTML={{ __html: subtitle.html }} />
              <hr className={classes.divider} />
              <div dangerouslySetInnerHTML={{ __html: bannerTitle.html }} />
              <RichTextContainer className={classes.copy} content={copy.html} />
            </div>
            <div className={classes.formSection}>
              <div className={classes.formContainer}>
                <SubmissionForm page={page} />
              </div>
            </div>
          </div>
        </PageWidth>
      )}
    </>
  )
}

const useStyles = createUseStyles({
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0px 20px 60px 20px',
    gap: '20px',
    margin: [0, 'auto'],
    [theme.breakpoints.up('md')]: {
      padding: '0px 70px 50px 70px',
      gap: '50px'
    }
    // [theme.breakpoints.up('lg')]: {
    //   padding: '0px 70px 50px 70px',
    //   gap: '100px'
    // }
  },
  infoSection: {
    flex: '1 1 500px',
    // maxWidth: '800px',
    // [theme.breakpoints.up('md')]: {
    //   minWidth: '620px',
    //   // maxWidth: '620px',
    //   marginLeft: '40px'
    // },
    // [theme.breakpoints.up('lg')]: {
    //   minWidth: '600px',
    //   maxWidth: '600px',
    //   marginLeft: '80px'
    // },
    justifyContent: 'flex-start'
  },
  formSection: {
    flex: '1 1 500px',
    minWidth: '200px'
    // [theme.breakpoints.up('md')]: {
    //   minWidth: '600px'
    // }
  },
  copy: {
    '& ul': {
      paddingInlineStart: '20px'
    }
  },
  divider: {
    height: '6px',
    backgroundColor: theme.colors.lightBlue,
    border: 'none',
    margin: '40px 0px',
    [theme.breakpoints.up('md')]: {
      margin: '60px 0px'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '80px 0px'
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bannerContainer: {
    extend: 'column',
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
      flex: '40%'
    },
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    fontSize: theme.getRemValue(18),
    padding: theme.spacing.md,
    position: 'relative',
    '& a, & a:hover': {
      color: theme.colors.white
    }
  },
  bannerImage: {
    ...expandAbsolutely,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    },
    opacity: 0.05
  },
  bannerTitle: {
    position: 'relative',
    margin: [theme.spacing.lg, 0],
    display: 'flex',
    alignItems: 'center',
    minHeight: 200,
    [theme.breakpoints.up('md')]: {
      minHeight: 300
    },
    '& h2': {
      fontFamily: theme.fonts.heading,
      textAlign: 'center',
      maxWidth: 300,
      lineHeight: 1.2,
      position: 'relative',
      margin: 0,
      fontSize: theme.getRemValue(50),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.getRemValue(64),
        maxWidth: 400
      }
    }
  },
  icon: {
    position: 'absolute',
    color: theme.colors.secondary,
    transform: 'rotate(-135deg) translate(25%, 0)',
    width: '100%',
    height: '100%',
    '& > svg': {
      width: '100%',
      height: '100%'
    }
  },
  bannerCopyBottom: {
    '& $bannerCopySmall': {
      display: 'block'
    },
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    '& p:last-child': {
      margin: 0
    }
  },
  formWrapper: {
    flex: 1
  },
  formContainer: {
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '800px'
  }
})

export default Submission
