import React from 'react'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import cn from 'classnames'
import SideBySideWidget from '../widgets/SideBySide'
import ResponsiveImage from '../widgets/ResponsiveImage'
import Link from '../Link'
import PageWidth from '../PageWidth'

const BACKGROUND_STYLES = {
  'style-1': 'style1',
  'style-2': 'style2',
  'style-3': 'style3',
  'style-4': 'style4'
}

const SideBySide = ({ slice, className }) => {
  const classes = useStyles()
  const backgroundSliceStyle = slice.background_style
  const styleClassName = BACKGROUND_STYLES[backgroundSliceStyle] || 'style1'
  return (
    <section className={cn(classes.section, classes[styleClassName])}>
      <PageWidth>
        <div className={cn(classes.container, className)}>
          <SideBySideWidget className={classes.article} reverse={slice.image_position === 'Right'}>
            <div className={classes.imageContainer}>
              <ResponsiveImage hasRoundedCorners className={classes.image} images={slice.image.images} blur={slice.image.blur} aspect={908 / 980} />
            </div>
            <div>
              <div className={classes.summaryContent}>
                <h2 className={classes.title}>{slice.title.text}</h2>
                <div className={classes.summary} dangerouslySetInnerHTML={{ __html: slice.text.html }} />
                {slice.button_text && (<Link to={get(slice, ['link', 'url'])} className={classes.button}>{slice.button_text}</Link>)}
              </div>
            </div>
          </SideBySideWidget>
        </div>
      </PageWidth>
    </section>
  )
}

const useStyles = createUseStyles(theme => ({
  section: {
    overflow: 'hidden',
    position: 'relative',
    margin: 0,
    backgroundColor: theme.colors.lightBlue
  },
  style1: {
    backgroundColor: theme.colors.blue
  },
  style2: {
    backgroundColor: theme.colors.lightBlue
  },
  style3: {
    backgroundColor: theme.colors.yellow
  },
  style4: {
    backgroundColor: theme.colors.beige
  },
  container: {
    display: 'block',
    textDecoration: 'none',
    color: theme.colors.black
  },
  article: {
    position: 'static',
    [theme.breakpoints.up('md')]: {
      position: 'static'
    },
    [theme.breakpoints.up('xl')]: {
      position: 'relative'
    }
  },
  title: {
    lineHeight: 1.15
  },
  imageContainer: {
    marginTop: '30px',
    alignItems: 'flex-start'
  },
  diskContainer: {
    position: 'absolute',
    margin: 0,
    top: 0,
    bottom: 0,
    left: '100%',
    transform: 'translate(-50%, 0)',
    display: 'none',
    padding: [theme.sp(6), 0],
    overflow: 'visible',
    [theme.breakpoints.up('md')]: {
      width: 400,
      display: 'block'
    },
    [theme.breakpoints.up('xl')]: {
      width: 560,
      padding: 0,
      top: -theme.sp(6),
      bottom: -theme.sp(6)
    }
  },
  record: {
    color: theme.colors.secondary,
    height: '100%'
  },
  image: {
    height: 'auto'
  },
  summaryContent: {
    position: 'relative'
  },
  summary: {
    // fontSize: theme.getRemValue(16),
    // [theme.breakpoints.up('md')]: {
    //   fontSize: theme.getRemValue(22)
    // },
    '& > *:last-child': {
      marginBottom: 0
    }
  },
  button: {
    padding: '16px 40px',
    borderRadius: '30px',
    background: theme.colors.yellow,
    textDecoration: 'none',
    color: theme.colors.black,
    transition: 'all 0.3s',
    fontFamily: theme.fonts.heading,
    fontSize: theme.getRemValue(22),
    marginTop: theme.sp(8),
    display: 'inline-block',
    '&:hover': {
      color: theme.colors.white,
      backgroundColor: theme.colors.pink
    }
  }
}))

export default SideBySide
