import React, { memo } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import { NavLink } from '../Link'

const Link = memo((props) => <NavLink {...props} />)
const Span = memo(({ to, ...rest }) => <div {...rest} />)

const TileContainer = ({ className, children, link, cta_text: ctaText }) => {
  const classes = useStyles()
  const Container = link ? Link : Span
  return (
    <Container to={get(link, ['url'])} className={cn(classes.link, className)}>
      {children}
    </Container>
  )
}

const useStyles = createUseStyles({
  container: {
    overflow: 'hidden'
  },
  link: {
    textDecoration: 'none'
  }
})

export default TileContainer
