import { canUseDom } from './dom'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import min from 'lodash/min'
import max from 'lodash/max'
import width from 'dom-helpers/query/width'
import height from 'dom-helpers/query/height'
import style from 'dom-helpers/style'
import { round } from './math'

const MIN_FONT_SIZE = 6

function innerDimension (el, dimension) {
  const outerLength = dimension(el)
  const paddingStart = parseFloat(style(el, dimension === height ? 'paddingTop' : 'paddingLeft'))
  const paddingEnd = parseFloat(style(el, dimension === height ? 'paddingBottom' : 'paddingRight'))
  return outerLength - paddingStart - paddingEnd
}

function innerSize (el) {
  return {
    width: innerDimension(el, width),
    height: innerDimension(el, height)
  }
}

function areChildrenClippedHorizontally (el) {
  const availableWidth = innerDimension(el, width)
  return !!find(el.childNodes, child => child.scrollWidth > Math.ceil(availableWidth))
}

// Example "bold 12pt arial"
export const getTextWidth = (text, font) => {
  if (!canUseDom) return { width: 122.72976684570312, height: 10 } // What is a good way to measure the text width on the server
  // re-use canvas object for better performance
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  var context = canvas.getContext('2d')
  context.font = font
  var metrics = context.measureText(text)
  return { width: metrics.width, height: parseInt(context.font, 0) }
}

export const fitTextToContainer = (parentContainer, container, fontRefs, maxFontSizes, fit: { height: true, width: true }) => {
  var fontSizes = [...maxFontSizes]
  var largest = max(fontSizes)
  var currentLargest = largest

  const setFontSizes = (reset = false) => {
    if (reset) {
      currentLargest = largest
    } else {
      currentLargest -= 0.5
    }
    const scale = currentLargest / largest
    forEach(fontRefs, (fontRef, i) => {
      if (!fontRef) return
      if (reset) {
        fontRef.style.fontSize = ''
      } else {
        fontSizes[i] = round(Math.max(maxFontSizes[i] * scale, MIN_FONT_SIZE))
        fontRef.style.fontSize = `${fontSizes[i]}px`
      }
    })
  }

  const checkContainerFits = () => {
    const parentSize = innerSize(parentContainer)
    const containerWidth = width(container)
    const containerHeight = height(container)
    var result = true
    if (!fit || fit.height) {
      result = result && containerHeight <= parentSize.height
    }
    if (!fit || fit.width) {
      result = result && containerWidth <= parentSize.width &&
      !areChildrenClippedHorizontally(container)
    }
    return result
  }

  setFontSizes(true)

  while (!checkContainerFits() && min(fontSizes) > MIN_FONT_SIZE) {
    setFontSizes()
  }

  return fontSizes
}

export const fitText = (parentContainer, fontContainer, minFontSize, maxFontSize) => {
  var fontSize = maxFontSize

  const setFontSize = (reset = false) => {
    if (reset) {
      fontContainer.style.fontSize = `${maxFontSize}px`
    } else {
      fontSize -= 0.5
      fontSize = round(Math.max(fontSize, minFontSize))
      fontContainer.style.fontSize = `${fontSize}px`
    }
  }

  const checkContainerFits = () => {
    const parentSize = innerSize(parentContainer)
    const containerWidth = width(fontContainer)
    const containerHeight = height(fontContainer)
    return containerWidth <= parentSize.width &&
            containerHeight <= parentSize.height
  }

  setFontSize(true)

  // eslint-disable-next-line
  while (!checkContainerFits() && fontSize > minFontSize) {
    setFontSize()
  }

  return fontSize
}
