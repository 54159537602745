import { NAV_CLOSED, NAV_TOGGLED } from '../actions'

const initialState = {
  open: false
}

const nav = (state = initialState, action) => {
  switch (action.type) {
    case NAV_CLOSED:
      return {
        ...state,
        open: false
      }

    case NAV_TOGGLED:
      return {
        ...state,
        open: !state.open
      }

    default:
      return state
  }
}

export default nav
