import React from 'react'
import { createUseStyles } from 'react-jss'
import PageWidth from '../PageWidth'
import TopRecordBoxes from '../widgets/RecordBoxes/TopRecordBoxes'
import theme from '../../styles/theme'

const Intro = ({ slice }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <TopRecordBoxes />
      <PageWidth>
        <content className={classes.content}>
          <div className={classes.titleContainer}>
            <h1 className={classes.title} dangerouslySetInnerHTML={{ __html: slice.title.html }} />
          </div>
          <div className={classes.summaryContainer}>
            <div className={classes.summary} dangerouslySetInnerHTML={{ __html: slice.text.html }} />
          </div>
        </content>
      </PageWidth>
    </div>
  )
}

const useStyles = createUseStyles({
  wrapper: {
    overflow: 'hidden'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0px',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '80px',
    [theme.breakpoints.up('md')]: {
      gap: '100px'
    }
  },
  titleContainer: {
    flex: 1,
    alignItems: 'center',
    minWidth: '400px'
  },
  title: {
    fontSize: '60px',
    [theme.breakpoints.up('md')]: {
      fontSize: '80px'
    },
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '110%',
    textTransform: 'uppercase'
  },
  summaryContainer: {
    flex: 1,
    minWidth: '200px'
  },
  summary: {
    flex: 1,
    textAlign: 'left'
  }
})

export default Intro
