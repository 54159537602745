import React from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../widgets/ResponsiveImage'
import TileContainer from './TileContainer'
import theme, { expandAbsolutely } from '../../styles/theme'

const ImageTile = ({ image, link, cta_text: ctaText }) => {
  const classes = useStyles()
  return <TileContainer link={link} cta_text={ctaText} color={theme.colors.teal}>
    <ResponsiveImage images={image.images} blur={image.blur} className={classes.image} />
  </TileContainer>
}

const useStyles = createUseStyles({
  image: {
    ...expandAbsolutely
  }
})

export default ImageTile
