import React from 'react'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import PageWidth from '../PageWidth'
import Title from '../Title'
import RichTextContainer from '../widgets/RichTextContainer'
import theme from '../../styles/theme'
import Link from '../Link'
import Case from '../widgets/Case'
import Record from '../widgets/Record'
import outlineText from '../../styles/outlineText'
import detectIt from 'detect-it'

const SubmitYourSleeve = ({ slice }) => {
  const classes = useStyles()
  const { slice_title: title, copy, submit_text: submitText, submit_link: submitLink } = slice
  const link = get(submitLink, ['url'])

  return (
    <section className={classes.section}>
      <PageWidth withVerticalPadding>
        <div className={classes.container}>
          <div className={classes.content}>
            <Title title={title} color={theme.colors.white} />
            <RichTextContainer content={copy.html} className={classes.richText} />
          </div>
          <div className={classes.cta}>
            <Link to={link} className={classes.link}>
              <Record className={classes.record} />
              <Case className={classes.case} fill={theme.colors.primary} />
              <span className={classes.submitText}>{submitText}</span>
            </Link>
          </div>
        </div>
      </PageWidth>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    margin: 0,
    backgroundColor: theme.colors.primary,
    color: theme.colors.white
  },
  container: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  content: {
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  richText: {
    fontSize: theme.getRemValue(20),
    '& .highlight': {
      backgroundColor: theme.colors.peach,
      display: 'inline-block',
      padding: [2, 4],
      margin: [4, 0],
      '& a': {
        textDecoration: 'none'
      }
    },
    '& a': {
      color: theme.colors.white,
      '&:hover': {
        color: theme.colors.white
      }
    }
  },
  cta: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.sp(10),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      minHeight: 'auto',
      width: '50%',
      marginLeft: theme.sp(4)
    }
  },
  link: {
    color: 'currentColor',
    textDecoration: 'none',
    fontFamily: theme.fonts.heading,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    '-webkit-tap-highlight-color': 'transparent',
    '&:hover': {
      color: 'currentColor'
    },
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&:hover $submitText': {
        color: 'currentColor'
      },
      '&:hover $record': {
        transform: 'translate(0%, -50%)'
      }
    })
  },
  submitText: {
    position: 'relative',
    letterSpacing: 8,
    color: 'transparent',
    ...outlineText(theme.colors.white),
    fontSize: theme.getRemValue(64),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.getRemValue(80)
    }
  },
  icon: {
    color: theme.colors.secondary,
    position: 'absolute',
    top: '50%',
    transform: 'translate(0%, -50%)',
    width: 130,
    height: 130,
    [theme.breakpoints.up('md')]: {
      width: 280,
      height: 280
    },
    [theme.breakpoints.up('lg')]: {
      width: 320,
      height: 320
    }
  },
  case: {
    composes: '$icon',
    transform: 'translate(0%, -50%) rotate(45deg)'
  },
  record: {
    composes: '$icon',
    transform: 'translate(-25%, -75%)',
    transition: 'transform 0.15s ease-in-out'
  }
})

export default SubmitYourSleeve
