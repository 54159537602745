import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import injectStyles from 'react-jss'
import flow from 'lodash/flow'
import { getCurrentBreakpoint } from '../../selectors'
import Link from '../Link'
import { fitText } from '../../utils/text'

class GalleryItem extends PureComponent {
    fit = () => {
      const { breakpoint } = this.props
      const maxNameFontSize = breakpoint === 'xs' || breakpoint === 'sm' ? 42 : 52
      fitText(this._containerRef, this._textRef, 8, maxNameFontSize)
    }

    setContainerRef = (ref) => { this._containerRef = ref }

    setTextRef = (ref) => { this._textRef = ref }

    render () {
      const {
        classes,
        // showNames,
        item,
        page
      } = this.props

      return <div className={classes.galItem}>
        <Link className={classes.galImageContainer} to={`/${page.type}/${page.uid}/${item.id}`}>
          <img className={classes.galImage} src={item.image.originalUrlSmall} />
        </Link>
      </div>
    }
}

const mapStateToProps = (state) => {
  return {
    breakpoint: getCurrentBreakpoint(state)
  }
}

export default flow([
  injectStyles((theme) => {
    var classes = {
      galItem: {
        width: '100%',
        paddingTop: '100%', // Creates a square aspect ratio based on the width of the item
        position: 'relative',
        overflow: 'hidden'
      },
      galImageContainer: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        '&:hover': {
          opacity: 0.5,
          transition: 'opacity 0.2s ease-in-out'
        }
      },
      galImage: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        objectFit: 'cover' // Ensures the image covers the area, may be cropped
      },
      textContainer: {
        minWidth: '100%'
      }
    }

    return classes
  }),
  connect(mapStateToProps)
])(GalleryItem)
