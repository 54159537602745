import { nprogress } from 'redux-nprogress'
import content from './content'
import nav from './nav'
import ribbon from './ribbon'
import player from './player'
import subscription from './subscription'
import layout from './layout'
import submission from './submission'
import fileUpload from './fileUpload'
import gallery from './gallery'
import popup from './popup'
import notification from './notification'

export default {
  nprogress,
  content,
  nav,
  ribbon,
  player,
  subscription,
  layout,
  submission,
  fileUpload,
  gallery,
  popup,
  notification
}
