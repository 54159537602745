import React from 'react'

const RecordAndCase = ({ className, classNames = {}, width = 60, height, fill = 'none' }) => {
  return (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 159 158' width={width} height={width} >
      <path fill={fill} stroke='currentColor' vectorEffect='non-scaling-stroke'
        d='M158.277 78.723c0-43.079-35.146-78-78.5-78s-78.5 34.921-78.5 78c0 43.078 35.146 78 78.5 78s78.5-34.922 78.5-78zm-15 .5c0-34.518-28.206-62.5-63-62.5-34.793 0-63 27.982-63 62.5 0 34.517 28.207 62.5 63 62.5 34.794 0 63-27.983 63-62.5zm-16-.5c0-25.958-21.042-47-47-47-25.956 0-47 21.042-47 47 0 25.957 21.044 47 47 47 25.958 0 47-21.043 47-47zm-16 .5c0-17.398-14.102-31.5-31.5-31.5-17.396 0-31.5 14.102-31.5 31.5 0 17.397 14.104 31.5 31.5 31.5 17.398 0 31.5-14.103 31.5-31.5zm-15-.5c0-8.837-7.387-16-16.5-16-9.112 0-16.5 7.163-16.5 16s7.388 16 16.5 16c9.113 0 16.5-7.163 16.5-16z'
      />
    </svg>
  )
}

export default RecordAndCase
