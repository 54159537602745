import React from 'react'

const Case = ({ className, width = 60, height, fill = 'none' }) => {
  return (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 157 157' width={width} height={width} >
      <path fill={fill} stroke='currentColor' vectorEffect='non-scaling-stroke' d='M1 1h155v155H1z' />
    </svg>
  )
}

export default Case
