import { createSelector } from 'reselect'
import get from 'lodash/get'
import { NOT_FOUND } from 'redux-first-router'

export const getGlobalContent = (state) => state.content.global
export const getTracks = (state) => get(getGlobalContent(state), ['tracks'])
export const getFirstTrackId = (state) => get(getGlobalContent(state), ['tracks', 0, 'uid'])
export const getFloatingLink = createSelector(
  getGlobalContent,
  global => ({
    link: get(global, ['floating_link'], {}),
    title: get(global, ['floating_link_text'])
  })
)

// TODO: these have moved to location.js
export const getCurrentPath = (state) => state.location.pathname
export const getCurrentLocation = (state) => state.location
export const isNotFound = (state) => get(state, ['location', 'type']) === NOT_FOUND
export const getPageType = (state) => get(state.content, ['page', 'type'])
export const getScreenWidth = (state) => state.content.screenWidth

export const getPage = (state) => state.content.page

export const getPageContent = createSelector(
  getGlobalContent,
  getPage,
  (global, page) => {
    const pageTiles = get(page, ['tiles'])
    const globalTiles = { ...get(global, ['tiles']) }

    if (pageTiles) {
      for (var i = 0; i < pageTiles.length; i++) { globalTiles[i] = pageTiles[i] }
    }

    return {
      ...page,
      tiles: globalTiles,
      has_related_articles: pageTiles && pageTiles.length > 0 && pageTiles.find(x => x.type === 'article_link_tile')
    }
  })

export const getFacebookUrl = createSelector(
  getGlobalContent,
  (global) => get(global, ['facebook_url'])
)

export const getTwitterkUrl = createSelector(
  getGlobalContent,
  (global) => get(global, ['twitter_url'])
)

export const getInstagramUrl = createSelector(
  getGlobalContent,
  (global) => get(global, ['instagram_url'])
)

export const getFooterSlices = createSelector(
  getGlobalContent,
  (global) => get(global, ['footerSlices'], [])
)

export const getCopyright = createSelector(
  getGlobalContent,
  (global) => {
    if (global && global.copyright) {
      return global.copyright.replace(/\{\{\s*YEAR\s*\}\}/, new Date().getFullYear())
    }
  }
)

export const getSiteTitle = createSelector(
  getGlobalContent,
  (global) => get(global, ['site_title'])
)

export const getEventbriteId = createSelector(
  getGlobalContent,
  (global) => get(global, ['event_id'])
)

export const getPageTitle = createSelector(
  getPageContent,
  getSiteTitle,
  (page, siteTitle) => {
    var title = get(page, ['title', 'text'], get(page, ['title']))
    if (!title || title === siteTitle) return siteTitle
    return `${siteTitle} - ${title}`
  }
)

function transformOpenGraphImage (image) {
  if (!get(image, 'url')) return null
  return {
    url: image.url,
    width: image.dimensions.width,
    height: image.dimensions.height
  }
}

function transformPageMetadata (page, global, path, title, siteTitle) {
  if (!global) return null

  return {
    title,
    meta_title: get(page, ['meta', 'meta_title']) || get(global, ['meta', 'meta_title']),
    metaDescription: get(page, ['meta', 'meta_description']) || get(global, ['meta', 'meta_description']),
    metaKeywords: get(page, ['meta', 'meta_keywords']) || get(global, ['meta', 'meta_keywords']),
    ogUrl: global.site_url + path,
    ogTitle: get(page, ['meta', 'open_graph_title']) || title,
    ogDescription: get(page, ['meta', 'open_graph_description']) || get(global, ['meta', 'open_graph_description']),
    ogImage: transformOpenGraphImage(get(page, ['meta', 'open_graph_image'])) ||
                transformOpenGraphImage(get(global, ['meta', 'open_graph_image'])),
    ogSiteName: siteTitle
  }
}

export const getMetadata = createSelector(
  getPageContent,
  getGlobalContent,
  getCurrentPath,
  getPageTitle,
  getSiteTitle,
  transformPageMetadata
)

export const showDonateButton = createSelector(
  getPageContent,
  (page) => page.type !== 'submission'
)
