import React from 'react'
import { createUseStyles } from 'react-jss'
import Link from '../Link'
import { get } from 'lodash'
import theme from '../../styles/theme'

const QuickLinks = ({ slice }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {slice.items.map((item, id) =>
        <Link key={id} className={classes.ListItemLink} to={item.social_link && item.social_link.url} target={item.social_link && item.social_link.target}>
          <img className={classes.image} src={get(item, ['social_image', 'images', 0, 'url'])} alt={item.title} />
        </Link>
      )}
    </div>
  )
}

export default QuickLinks

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    padding: '40px'
  },
  title: {
    textAlign: 'center'
  },
  list: {
    textAlign: 'center',
    listStyleType: 'none',
    padding: 0
  },
  ListItemLink: {
    // backgroundColor: 'white',
    padding: '5px',
    borderRadius: '10px'
  },
  image: {
    height: '29px',
    width: '29px',
    [theme.breakpoints.up('sm')]: {
      height: '29px',
      width: '29px'
    }
  }
})
