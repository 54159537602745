import React from 'react'
import injectStyles from 'react-jss'
import cn from 'classnames'
import RichTextContainer from '../widgets/RichTextContainer'
import PageSection from './PageSection'

const TextSlice = ({ text, classes, className }) => (
  <PageSection>
    <RichTextContainer className={cn(classes.text, className)} content={text.html} />
  </PageSection>
)

export default injectStyles(theme => ({
  text: {
    fontSize: theme.getRemValue(22),
    '& p:last-child': {
      margin: 0
    }
  }

})
)(TextSlice)
