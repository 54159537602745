import React from 'react'
import cn from 'classnames'
import injectSheet from 'react-jss'

const SideBySide = ({ classes, children, className, reverse }) =>
  <div className={cn(classes.sideBySide, className, { reverse })}>
    {children}
  </div>

export default injectSheet(theme => ({
  sideBySide: {
    margin: [theme.sp(5) - theme.sp(2), 0],
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      margin: [theme.sp(6), -theme.sp(3)],
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('lg')]: {
      margin: [theme.sp(6), -theme.sp(3)]
    },
    '&.reverse': {
      flexDirection: 'row-reverse'
    },
    '& > *': {
      margin: [theme.sp(2), 0],
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        margin: [theme.sp(2), theme.sp(3)],
        width: 'auto',
        flex: '1 1 0px',
        // maxHeight: theme.content.width * 0.45,
        minHeight: `0`
      }
    }
  }
}))(SideBySide)
