import React from 'react'
// import cn from 'classnames'
import injectStyles from 'react-jss'
import PageSection from './PageSection'

const QuoteSlice = ({ classes, quote }) => (
  <PageSection>
    <aside className={classes.quote}>
      {quote}
    </aside>
    <div />
  </PageSection>
)

export default injectStyles((theme) => ({
  quote: {
    fontFamily: theme.fonts.heading,
    lineHeight: 1.2,
    position: 'relative',
    margin: [0, 0, 0, theme.spacing.md],
    fontSize: theme.getRemValue(32),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.getRemValue(40),
      margin: [0, 0, 0, theme.spacing.xl]
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: 3,
      backgroundColor: theme.colors.black,
      left: -theme.spacingPx.md / 2,
      [theme.breakpoints.up('sm')]: {
        width: 6,
        left: -theme.spacingPx.xlg / 2
      }
    }
  }
}))(QuoteSlice)
