import React, { PureComponent } from 'react'
import cn from 'classnames'
import { NavLink } from '../Link'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import flow from 'lodash/flow'
import { getFileUploadCachedFile, getTracks, getSubmissionStatus, getSubmissionMessage } from '../../selectors'
import theme, { expandAbsolutely } from '../../styles/theme'
import { LocalForm, Control, Errors } from 'react-redux-form'
import FileUpload from './FileUpload'
import { submit } from '../../actions'
import Checkbox from '../widgets/Checkbox'
import * as EmailValidator from 'email-validator'

const Input = ({ classes, className, value, required, ...rest }) => (
  <div className={className}>
    <input value={value} required={required} {...rest} />
    {required && <span className='validation'>*</span>}
  </div>
)

const TextArea = ({ className, value, required, ...rest }) => (
  <div className={className}>
    <textarea value={value} required={required} {...rest} />
    {required && <span className='validation'>*</span>}
  </div>
)

const Select = ({ className, value, required, options, onChange, selectHelpText, ...rest }) => (
  <div className={className}>
    <select value={value} {...rest} onChange={onChange}>
      <option value='' disabled >{selectHelpText}</option>
      {options.map(x => <option key={x.value} value={x.value} >{x.text}</option>)}
    </select>
    <input value={value} required={required} {...rest} />
    {required && <span className='validation'>*</span>}
  </div>
)

const ConsentCheckBoxInput = ({ value, onChange, ...rest }) => (
  <Checkbox
    color={theme.colors.blue}
    width={32}
    name='consentStatement'
    value={value}
    onChange={onChange}
    {...rest}
  />
)

const initialValues = {
  name: '',
  email: '',
  track: '',
  instagram: '',
  tiktok: '',
  twitter: '',
  website: '',
  description: '',
  donation: '',
  subscribeOptIn: false
}

class SubmissionForm extends PureComponent {
    state = {
      form: {}
    };

    _handleChange = (values) => {}

    _handleUpdate = (form) => {
      this.setState({
        form: { ...form }
      })
    }

    _handleSubmit = (values) => {
      const { status, handleSubmit, cached_file: cachedFile } = this.props

      if (!cachedFile || (status !== 'error' && status !== 'idle')) return

      handleSubmit({
        ...values,
        file_id: cachedFile.key
      })
    }

    render () {
      const {
        classes,
        tracks,
        cached_file: cachedFile,
        status,
        message,
        page: {
          call_to_action: callToAction,
          terms_and_conditions: terms,
          donate_text: donateText,
          consent_statement_text: consentStatementText,
          consent_info_text: consentInfoText
        }
      } = this.props

      return <div className={classes.formInnerContainer}>
        <LocalForm
          model='submission'
          initialState={{ ...initialValues }}
          onUpdate={this._handleUpdate}
          onChange={this._handleChange}
          onSubmit={this._handleSubmit}
        >

          <div className={classes.fieldRow}>

            <div className={classes.fieldWrapper}>
              <label htmlFor='.name'>Full Name</label>
              <Control model={'.name'} component={Input}
                validators={{ required: (val) => val && val.length }}
                controlProps={{
                  id: 'name',
                  required: true,
                  className: classes.field
                  // placeholder: 'Full Name'
                }}
              />
              <div className={classes.validationError}>
                <Errors
                  model={'.name'}
                  show={{ touched: true, focus: false }}
                  messages={{
                    required: 'Please provide a name'
                  }}
                />
              </div>
            </div>

          </div>
          <div className={classes.fieldRow}>

            <div className={classes.fieldWrapper}>
              <label htmlFor='.email'>Email Address</label>
              <Control model={'.email'} component={Input}
                validators={{
                  required: (val) => val && val.length,
                  invalid: (val) => val && EmailValidator.validate(val)
                }}
                controlProps={{
                  id: 'email',
                  required: true,
                  className: classes.field,
                  // placeholder: 'Email Address',
                  type: 'email',
                  pristine: true
                }}
              />
              <div className={classes.validationError}>
                <Errors
                  model={'.email'}
                  show={{ touched: true, focus: false }}
                  messages={{
                    required: 'Please provide an email',
                    invalid: 'Email is invalid'
                  }}
                />
              </div>
            </div>

          </div>
          <div className={classes.fieldRow}>

            <div className={classes.fieldWrapper}>
              <label htmlFor='.track'>Select a Track</label>
              <Control model={'.track'} component={Select}
                validators={{ required: (val) => val && val.length }}
                controlProps={{
                  id: 'track',
                  required: true,
                  className: cn(classes.field, classes.trackSelect),
                  // placeholder: 'Choose Track',
                  options: tracks.map(track => ({ text: track.title, value: track.title })),
                  selectHelpText: 'Select a Track'
                }}
              />
              <div className={classes.validationError}>
                <Errors
                  model={'.track'}
                  show={{ touched: true, focus: false }}
                  messages={{
                    required: 'Please select a track'
                  }}
                />
              </div>
            </div>

          </div>

          <div className={classes.fieldRow}>

            <div className={classes.fieldWrapper}>
              <label htmlFor='.instagram'>Your @Instagram</label>
              <Control model={'.instagram'} component={Input}
                controlProps={{
                  id: 'instagram',
                  className: classes.field
                  // placeholder: '@Instagram'
                }}
              />
              <div className={classes.validationError} />
            </div>

          </div>

          <div className={classes.fieldRow}>

            <div className={classes.fieldWrapper}>
              <label htmlFor='.tiktok'>Your @TikTok</label>
              <Control model={'.tiktok'} component={Input}
                controlProps={{
                  id: 'tiktok',
                  className: classes.field
                  // placeholder: '@TikTok'
                }}
              />
              <div className={classes.validationError} />
            </div>

          </div>

          <div className={classes.fieldRow}>

            <div className={classes.fieldWrapper}>
              <label htmlFor='.twitter'>Your @X</label>
              <Control model={'.twitter'} component={Input}
                controlProps={{
                  id: 'twitter',
                  className: classes.field
                  // placeholder: '@X'
                }}
              />
              <div className={classes.validationError} />
            </div>

          </div>

          <div className={classes.fieldRow}>

            <div className={classes.fieldWrapper}>
              <label htmlFor='.website'>Your Website URL</label>
              <Control model={'.website'} component={Input}
                controlProps={{
                  id: 'website',
                  className: classes.field
                  // placeholder: 'Your Website URL'
                }}
              />
              <div className={classes.validationError} />
            </div>
          </div>

          <div className={cn(classes.fieldRow, classes.upload)}>
            <div className={classes.fieldWrapper}>
              <label htmlFor='.file_id'>Upload File (pdf, jpg, tiff)</label>
              <input className={classes.hiddenField} required value={cachedFile ? cachedFile.key : ''} />
              <Control model={'.file_id'} component={FileUpload}
                controlProps={{
                  required: true,
                  className: classes.field
                }}
              />
              <div className={classes.validationError} />
            </div>
          </div>

          <div className={classes.fieldRow}>
            <div className={classes.fieldWrapper}>
              <label style={{ whiteSpace: 'pre-wrap' }} htmlFor='.description'>{'What was the inspiration for your design?\nIf your work is successful making it into the show, this message will be printed onto an authentication slip that they buyer of your work will receive with the sleeve and vinyl.\n(1000 characters or less)'}</label>
              <Control model={'.description'} component={TextArea}
                validators={{
                  required: (val) => val && val.length,
                  tooLong: (val) => val && val.length <= 1000
                }}
                controlProps={{
                  id: 'description',
                  required: true,
                  className: classes.field,
                  // placeholder: `What was the inspiration for your design? \n\nIf your work is successful making it into the show, this message will be printed onto an authentication slip that they buyer of your work will receive with the sleeve and vinyl. \n\n1000 characters or less.`,
                  rows: 10
                }}
              />
              <div className={classes.validationError}>
                <Errors
                  model={'.description'}
                  show={{ touched: true, focus: false }}
                  messages={{
                    required: 'Please enter a description',
                    tooLong: 'Description is too long (max 1000 characters)'
                  }}
                />
              </div>
            </div>
          </div>

          <div className={classes.fieldRow}>

            <div className={classes.fieldWrapper}>
              <div className={classes.donateText} >{donateText.text}</div>
              <Control model={'.donation'} component={Select}
                controlProps={{
                  id: 'donation',
                  className: cn(classes.field, classes.donateSelect),
                  placeholder: 'Donate to War Child (optional)',
                  options: ['No donation', '£3', '£7', '£10'].map(donateOption => ({ text: donateOption, value: donateOption })),
                  selectHelpText: 'Select a Donation'
                }}
              />
            </div>

          </div>

          <div className={classes.consentStatementText} >{consentStatementText.text}</div>

          <label className={classes.consentStatementContainer}>
            <Control
              model='.subscribeOptIn'
              component={ConsentCheckBoxInput}
            />
            <div className={classes.checkboxLabel}>Opt-in</div>
          </label>

          <div className={classes.consentInfoText} dangerouslySetInnerHTML={{ __html: consentInfoText.html }} />

          <div>
            <label className={classes.termsContainer}>
              <Checkbox color={theme.colors.blue} width={32} required name='terms' />
              <div className={classes.checkboxLabel}>I accept <NavLink to={terms.url}>Terms & Conditions</NavLink></div>
            </label>
            <div className={classes.submitContainer}>
              <button className={classes.button} type='submit'>
                {status === 'idle' && <span>{callToAction}</span>}
                {status === 'loading' && <span key='message'>{message}</span>}
                {status === 'error' && <span>{message}</span>}
                {status === 'success' && <span>{message}</span>}
              </button>
            </div>
          </div>

        </LocalForm>
      </div>
    }
}

const mapStateToProps = (state) => ({
  cached_file: getFileUploadCachedFile(state),
  tracks: getTracks(state),
  status: getSubmissionStatus(state),
  message: getSubmissionMessage(state)

})

function mapDispatchToProps (dispatch) {
  return {
    handleSubmit: (data) => dispatch(submit(data))
  }
}

export default flow([
  injectSheet((theme) => ({
    formInnerContainer: {
      width: '100%',
      '& h2': {
        textTransform: 'none',
        fontSize: theme.getRemValue(18),
        fontFamily: theme.fonts.body
      }
    },
    fieldRow: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      },
      '& label': {
        fontSize: theme.getRemValue(20),
        color: theme.colors.lightGrey
      }
    },
    fieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    },
    validationError: {
      fontSize: 12,
      color: theme.colors.primary,
      lineHeight: '30px',
      height: 30,
      '& * ~ *': {
        display: 'none'
      }
    },
    field: {
      position: 'relative',
      flex: '1 0 auto',
      display: 'flex',
      '& textarea': {
        ...theme.mixin.placeholder({ color: theme.colors.black })
      },
      '& input, & textarea, & div.fileUpload': {
        // backgroundColor: 'transparent',
        borderRadius: '4px',
        border: `2px solid ${theme.colors.lightBlue}`,
        // border: 'none',
        // borderBottom: [1, 'solid', theme.colors.primary],
        width: '100%',
        // fontSize: theme.getRemValue(24),
        // fontFamily: theme.fonts.heading,
        padding: '16px 26px 16px 24px',
        fontSize: theme.getRemValue(20),
        ...theme.mixin.placeholder({ color: '#BABABA' })
      },
      '& span.validation, & span.close': {
        color: '#BABABA',
        fontSize: theme.getRemValue(20),
        fontFamily: theme.fonts.body,
        lineHeight: 1.1,
        // backgroundColor: theme.colors.primary,
        display: 'block',
        position: 'absolute',
        top: '10px',
        right: '10px',
        textAlign: 'center'
      },
      '& span.validation': {
        fontSize: '1rem'
      },
      '&.required:after': {
        width: '1.5rem',
        height: '1.5rem',
        color: theme.colors.white,
        fontSize: theme.getRemValue(32),
        lineHeight: 1.1,
        // backgroundColor: theme.colors.primary,
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 0,
        textAlign: 'center'
      },

      '& .fileUpload': {
        cursor: 'pointer',
        '& .text': {
          fontSize: theme.getRemValue(20)
        }
      },
      '& .idle span:first-child': {
        opacity: 0.5
      },
      '& .error': {
        color: theme.colors.peach
      },
      '& .success': {
        color: theme.colors.teal
      },
      '& select': {
        ...expandAbsolutely,
        appearance: 'none',
        width: '100%',
        height: '100%', // required on IE11
        opacity: 0,
        cursor: 'pointer'
      },
      '& #track': {
        ...theme.mixin.placeholder({ color: theme.colors.black })
      },
      '& option': {
        fontSize: theme.getRemValue(18),
        textTransform: 'uppercase'
      }
    },
    donateText: {
      marginBottom: '20px',
      fontSize: theme.getRemValue(20)
    },
    trackSelect: {},
    descriptionHelpText: {
      fontSize: '0.8rem'
    },
    fileUploadResult: {
      display: 'flex',
      alignItems: 'center'
    },
    upload: {
      position: 'relative'
    },
    hiddenField: {
      position: 'absolute',
      top: '50%',
      opacity: 0
    },
    button: {
      cursor: 'pointer',
      width: '100%',
      padding: '16px 40px',
      borderRadius: '30px',
      border: 'none',
      color: theme.colors.black,
      background: theme.colors.yellow,
      textDecoration: 'none',
      transition: 'all 0.3s',
      fontFamily: theme.fonts.body,
      fontSize: theme.getRemValue(24),
      display: 'inline-block',
      '&:hover': {
        color: theme.colors.white,
        backgroundColor: theme.colors.pink
      }
    },
    termsContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.getRemValue(14),
      margin: [theme.spacing.md, 0, 50],
      userSelect: 'none',
      cursor: 'pointer',
      '& a': {
        color: theme.colors.black,
        '&:hover': {
          color: theme.colors.blue
        }
      }
    },
    checkboxLabel: {
      marginLeft: theme.spacing.sm
    },
    consentStatementText: {
      fontSize: theme.getRemValue(20),
      marginTop: '40px'
    },
    consentStatementContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.getRemValue(14),
      margin: [10, theme.spacing.md, 30, 0],
      userSelect: 'none',
      cursor: 'pointer',
      '& a': {
        color: theme.colors.black,
        '&:hover': {
          color: theme.colors.blue
        }
      }
    },
    consentInfoText: {
      fontSize: theme.getRemValue(14)
    }
  })),
  connect(mapStateToProps, mapDispatchToProps)
])(SubmissionForm)
