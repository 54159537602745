import React, { Component } from 'react'
import injectStyles from 'react-jss'
import flow from 'lodash/flow'
import theme from '../../styles/theme'

class Checkbox extends Component {
    state = {
      checked: false
    }

    handleChange = () => {
      const { onChange, value } = this.props
      if (onChange) {
        onChange(!value)
        return
      }
      this.setState({ checked: !this.state.checked })
    }

    render () {
      const { classes, onChange, ...rest } = this.props
      const checked = onChange ? this.props.value : this.state.checked
      const color = this.props.color || theme.colors.primary

      return <span className={classes.container} onClick={this.handleChange}>
        {checked &&
          <svg focusable='false' viewBox='0 0 24 24' aria-hidden='true' className={classes.icon}>
            <path fill={color} d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z' />
          </svg>
        }
        {!checked &&
          <svg focusable='false' viewBox='0 0 24 24' aria-hidden='true' className={classes.icon}>
            <path fill={color} d='M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z' />
          </svg>
        }
        <input className={classes.input} type='checkbox' {...rest} checked={checked} />
      </span>
    }
}

export default flow([
  injectStyles(theme => ({
    container: {
      display: 'block',
      width: ({ width }) => width || 24,
      height: ({ width }) => width || 24,
      minWidth: ({ width }) => width || 24,
      minHeight: ({ width }) => width || 24,
      position: 'relative'
    },
    input: {
      top: 0,
      left: 0,
      width: '100%',
      cursor: 'inherit',
      height: '100%',
      margin: 0,
      opacity: 0,
      padding: 0,
      position: 'absolute'
    },
    icon: {
      width: '100%',
      height: '100%'
    }
  }))
])(Checkbox)
