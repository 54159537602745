import {
  UPLOAD_FILE,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_SUCCESS,
  REMOVE_UPLOADED_FILE,
  SUBMIT_CLEAR,
  SUBMIT_SUCCESS
} from '../actions'

const initialState = {
  status: 'idle',
  progress: 0,
  file: undefined,
  cached_file: undefined
}

const fileUpload = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...state,
        status: 'uploading',
        progress: 0,
        file: action.payload.file,
        cached_file: undefined
      }
    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        status: 'error',
        message: action.payload.message
      }
    case UPLOAD_FILE_PROGRESS:
      return {
        ...state,
        progress: action.payload.progress
      }
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        status: 'success',
        progress: 100,
        cached_file: action.payload.file
      }
    case SUBMIT_SUCCESS:
    case SUBMIT_CLEAR:
    case REMOVE_UPLOADED_FILE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default fileUpload
