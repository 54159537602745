export const PLAYER_TRACK_CHANGED = 'PLAYER_TRACK_CHANGED'
export const PLAYER_PAUSE = 'PLAYER_PAUSE'
export const PLAYER_TOGGLE = 'PLAYER_TOGGLE'
export const PLAYER_PLAY = 'PLAYER_PLAY'
export const PLAYER_NEXT = 'PLAYER_NEXT'
export const PLAYER_PREV = 'PLAYER_PREV'
export const PLAYER_TIME_CHANGED = 'PLAYER_TIME_CHANGED'
export const PLAYER_DURATION_CHANGED = 'PLAYER_DURATION_CHANGED'
export const PLAYER_LOADING = 'PLAYER_LOADING'
export const PLAYER_READY = 'PLAYER_READY'
export const PLAYER_TRACK_ENDED = 'PLAYER_TRACK_ENDED'

export const playerTrackChanged = (track) => ({ type: PLAYER_TRACK_CHANGED, payload: { track } })
export const playTrack = (track) => ({ type: PLAYER_TRACK_CHANGED, payload: { track, paused: false } })
export const playerPause = () => ({ type: PLAYER_PAUSE })
export const playerPlay = () => ({ type: PLAYER_PLAY })
export const playerToggle = () => ({ type: PLAYER_TOGGLE })
export const playerNext = () => ({ type: PLAYER_NEXT })
export const playerPrev = () => ({ type: PLAYER_PREV })
export const playerLoading = () => ({ type: PLAYER_LOADING })
export const playerReady = () => ({ type: PLAYER_READY })
export const playerTrackEnded = () => ({ type: PLAYER_TRACK_ENDED })
export const playerTimeChanged = (time) => ({ type: PLAYER_TIME_CHANGED, payload: { time } })
export const playerDurationChanged = (duration) => ({ type: PLAYER_DURATION_CHANGED, payload: { duration } })
