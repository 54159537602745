import React, { useCallback, useRef, useEffect, memo } from 'react'
import cn from 'classnames'
import get from 'lodash/get'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { fitTextToContainer } from '../utils/text'
import { NavLink } from './Link'
import logo from '../img/secret7.svg'
import theme, { expandAbsolutely } from '../styles/theme'
import useElementSize from '../hooks/useElementSize'
import { IMAGE_TRANSITION_DURATION } from './widgets/ImageHover'
import detectIt from 'detect-it'
import { isCurrentBreakpointAtLeast } from '../selectors'

export const variations = [
  'purple', 'peach', 'primary'
]

const Link = memo((props) => <NavLink {...props} />)
const Span = memo(({ to, ...rest }) => <div {...rest} />)

const Banner = ({ link, children, title, variation, backgroundImage, color, textColor }) => {
  const classes = useStyles({ color, textColor })
  const isMobile = useSelector(isCurrentBreakpointAtLeast('md'))
  const containerRef = useRef()
  const textRef = useRef()
  const fit = useCallback(() => {
    fitTextToContainer(containerRef.current, textRef.current, [textRef.current], [isMobile ? 70 : 120])
  }, [])

  useElementSize(containerRef, fit)

  useEffect(() => { fit() }, [])

  const Container = link ? Link : Span

  return (
    <div className={classes.section} ref={containerRef}>
      <Container className={cn(classes.container, variation)} to={link}>
        <div className={classes.textContainer}>
          {title && <div className={classes.text} ref={textRef} dangerouslySetInnerHTML={{ __html: get(title, ['html']) }} />}
        </div>
      </Container>
    </div>
  )
}

const useStyles = createUseStyles({
  section: {
    marginBottom: 48,
    position: 'relative',
    overflow: 'hidden',
    '&:last-child': {
      marginBottom: 0
    },
    borderRadius: '20px',
    background: theme.colors.blue
  },
  container: {
    backgroundColor: theme.colors.blue,
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    paddingTop: `${(762 / 1142) * 100}%`,
    transition: `color ${IMAGE_TRANSITION_DURATION}s ease-in-out`,
    color: theme.colors.black,
    '&.primary': {
      backgroundColor: theme.colors.blue
    },
    '&.purple': {
      backgroundColor: theme.colors.yellow
    },
    '&.peach': {
      backgroundColor: theme.colors.pink
    },
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&:hover $overlayImage': {
        opacity: 0
      },
      '&:hover': {
        color: theme.colors.white
      }
    })
  },
  textContainer: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textDecoration: 'none',
    zIndex: 2
  },
  image: {
    ...expandAbsolutely
  },
  overlayImage: {},
  text: {
    padding: theme.spacing.lg,
    position: 'relative',
    fontSize: theme.getRemValue(80),
    fontFamily: theme.fonts.heading,
    textTransform: 'uppercase',
    lineHeight: 0.9,
    maxWidth: 600,
    '& .icon': {
      fontSize: '0rem',
      display: 'block',
      margin: 'auto',
      width: 80,
      [theme.breakpoints.up('sm')]: {
        width: 100
      }
    },
    '& .icon:before': {
      content: `url(${logo})`,
      visibility: 'visible'
    },
    '& > *': {
      margin: 0
    }
  }
})

export default Banner
