import React, { PureComponent } from 'react'
import injectSheet from 'react-jss'
import PageSection from './PageSection'

class YouTubeSlice extends PureComponent {
  render () {
    const { classes, embedded_content: embeddedContent } = this.props
    return <PageSection extended>
      <div className={classes.videoWrapper} dangerouslySetInnerHTML={{ __html: embeddedContent.html }} />
    </PageSection>
  }
}

export default injectSheet((theme) => (
  {
    videoWrapper: {
      position: 'relative',
      paddingBottom: '56.25%', // 16:9
      // paddingTop: 25px;
      height: 0,
      '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
    }
  }
))(YouTubeSlice)
