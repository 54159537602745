import React, { PureComponent } from 'react'
import cn from 'classnames'
import injectSheet from 'react-jss'
import flow from 'lodash/flow'
import { expandAbsolutely } from '../../styles/theme'
import elementResizeDetectorMaker from 'element-resize-detector'

class Progress extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { width: 0, height: 0 }
  }

  componentDidMount () {
    this.erd = elementResizeDetectorMaker({ strategy: 'scroll' })
    this.erd.listenTo(this._containerRef, this._setSize)
  }

  componentWillUnmount () {
    if (this.erd && this._containerRef) { this.erd.removeAllListeners(this._containerRef) }
  }

    _setSize = (element) => {
      this.setState({
        width: element.offsetWidth,
        height: element.offsetHeight })
    }

    _setContainerRef = (ref) => { this._containerRef = ref }

    _nooop = (e) => {
      e.stopPropagation()
    }

    render () {
      const { classes, progress, handleCancel } = this.props
      const { width, height } = this.state

      return <div className={classes.container} onClick={this._nooop}>
        <div className={classes.sizer} ref={this._setContainerRef} />
        <div className={classes.progress}>{progress}%</div>
        <div className={cn(classes.progress, classes.progressOverlay)}
          style={{ clip: `rect(0px, ${progress / 100 * width}px, ${height}px, 0px)` }}>{progress}%</div>
        <span onClick={handleCancel}>x</span>
      </div>
    }
}

export default flow([
  injectSheet((theme) => ({
    container: {
      minHeight: '1.5em',
      cursor: 'inherit'
    },
    sizer: {
      ...expandAbsolutely
    },
    progress: {
      ...expandAbsolutely,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    progressOverlay: {
      backgroundColor: theme.colors.primary,
      color: theme.colors.white
    }

  }))
])(Progress)
