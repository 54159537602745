import React, { PureComponent } from 'react'
import cn from 'classnames'
import injectSheet from 'react-jss'
import flow from 'lodash/flow'

import inDOM from 'dom-helpers/util/inDOM'
import outlineText from '../../styles/outlineText'

class ShareLinks extends PureComponent {
  render () {
    const { classes, show_share_links: showShareLinks } = this.props

    var location = inDOM ? window.location.href : ''
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(location)}`
    const twitterUrl = `https://twitter.com/home?status=${encodeURIComponent(location)}`

    return showShareLinks === 'Yes' ? (
      <div className={classes.shareContainer}>
        <label className={cn(classes.shareItem, 'label')}>Share</label>
        <a className={classes.shareItem} href={facebookUrl} target='_blank'>Facebook</a>
        <a className={classes.shareItem} href={twitterUrl} target='_blank'>Twitter</a>
      </div>
    ) : null
  }
}

export default flow([
  injectSheet((theme) => ({
    shareContainer: {
      display: 'flex',
      margin: [theme.sp(2), 0, 0],
      [theme.breakpoints.up('md')]: {
        margin: [theme.sp(4), 0, 0]
      }
    },
    shareItem: {
      fontFamily: theme.fonts.heading,
      letterSpacing: 4,
      // it looks like, when we have a non-zero letter-spacing, the browser (Chrome at least, need to test) adds
      // an extra space at the end of the text so it doesn't center-align quite right - and this is noticeable
      // when we rotate the prev and next buttons in opposite directions. So this is to re-adjust the centering
      textIndent: 4,
      textTransform: 'uppercase',
      padding: theme.sp(1),
      color: 'transparent',
      textDecoration: 'none',
      margin: 0,
      transition: 'all 0.3s',
      fontSize: theme.getRemValue(20),
      ...outlineText(theme.colors.secondary, 1),
      '&.label': {
        color: theme.colors.secondary,
        paddingLeft: 0
      },
      '&:hover': {
        color: theme.colors.secondary
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.getRemValue(28),
        padding: [theme.sp(1), theme.sp(2)]
      }
    }
  }))
])(ShareLinks)
