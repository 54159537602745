import { createSelector } from 'reselect'
import { getPage, getCurrentLocation } from './content'

import filter from 'lodash/filter'
import first from 'lodash/first'

export const getGalleryPage = (state) => state.gallery.page
export const getGalleryLimit = (state) => state.gallery.limit
export const getGalleryTotal = (state) => state.gallery.total
export const getGalleryTrackFilter = (state) => state.gallery.track
export const getGalleryQueryFilter = (state) => state.gallery.query

export const getSelectedGalleryItem = createSelector(
  getPage,
  getCurrentLocation,
  (page, location) => {
    if (!page) return null
    if (page.type === 'gallery' && location.pathname.indexOf('/gallery') === 0 &&
            location.payload.id) {
      return first(filter(page.items, item => item.id === location.payload.id + ''))
    }
    return null
  })
