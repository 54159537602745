import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { expandAbsolutely } from '../styles/theme'
import ResponsiveImage from './widgets/ResponsiveImage'
import Title from './Title'

const ArticleHero = ({
  link, children, title, image, copy,
  color = theme.colors.secondary, background = theme.colors.primary
}) => {
  const classes = useStyles({ background })
  const hasImages = image && image.images

  return (
    <section className={classes.section} >
      {hasImages && (
        <ResponsiveImage
          className={classes.image}
          images={image.images}
          blur={image.blur}
        />
      )}
      <div className={classes.text}>
        <Title className={classes.title} title={title} color={theme.colors.white} />
        {copy && <div dangerouslySetInnerHTML={{ __html: copy.html }} className={classes.copy} />}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    backgroundColor: ({ background }) => background,
    marginBottom: 0,
    position: 'relative',
    overflow: 'hodden'
  },
  container: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none'
  },
  image: {
    ...expandAbsolutely
  },
  text: {
    position: 'relative',
    color: theme.colors.white,
    padding: theme.sp(4),
    fontFamily: theme.fonts.heading,
    textTransform: 'uppercase',
    textAlign: 'center',
    lineHeight: 1,
    minHeight: 420,
    maxWidth: 800,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      minHeight: 560
    }
  },
  title: {
    '& h1, & h2, & p': {
      lineHeight: 1,
      fontSize: theme.getRemValue(70),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.getRemValue(80)
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: theme.getRemValue(120)
      }
    },
    '& p': {
      marginBottom: 0
    }
  },
  copy: {
    fontFamily: theme.fonts.heading,
    marginTop: theme.sp(4),
    fontSize: theme.getRemValue(32),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.getRemValue(40)
    }
  }
})

export default ArticleHero
