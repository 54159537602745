import inDOM from 'dom-helpers/util/inDOM'
import { useEffect } from 'react'
import elementResizeDetectorMaker from 'element-resize-detector'
import offset from 'dom-helpers/query/offset'

// The instance is reusable, and constructing it is expensive if we do it a lot
const erd = inDOM && elementResizeDetectorMaker({ strategy: 'scroll' })

export default function useElementSize (ref, onResize) {
  useEffect(() => {
    const el = ref.current

    erd.listenTo(el, () => {
      const { width, height } = offset(el)
      onResize({ el, width, height })
    })

    return () => {
      erd.removeAllListeners(el)
    }
  }, [onResize])
}
