/* eslint-env browser */
import inDOM from 'dom-helpers/util/inDOM'
import isIE11 from './isIE11'

const supportsBlurFilter = !inDOM || (() => {
  // eslint-disable-next-line no-undef
  const el = document.createElement('a')
  el.style.cssText = 'filter:blur(15px);'
  return !!el.style.length && !isIE11
})()

export const blurFilterIsFastEnough = false && (!inDOM || (supportsBlurFilter && navigator.userAgent.toLowerCase().indexOf('firefox') < 0))

export default supportsBlurFilter
