import get from 'lodash/get'
import React, { useCallback, useMemo } from 'react'
import last from 'lodash/last'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import round from '../../utils/round'
import { blurFilterIsFastEnough } from '../../utils/supportsBlurFilter'

const defaultImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

const Picture = ({ classes, alt, images, disableLazy }) => {
  if (!images) return null
  const hasWebp = !!get(images, [0, 'url_webp'])
  const srcset = (key, images) => images.map(item => (`${item[key]} ${item.dimensions.width}w`)).join()
  const urls = useMemo(() => srcset('url', images), [images])
  const webpUrls = useMemo(() => srcset('url_webp', images), [images])

  const srcName = disableLazy ? 'srcSet' : 'data-srcset'
  return (
    <picture>
      {hasWebp && <source {...{ [srcName]: webpUrls }} type='image/webp' />}
      {images && <source{...{ [srcName]: urls }} />}
      <img
        data-sizes='auto'
        alt={alt}
        className={cn(images && !disableLazy && 'lazyload', classes.image)}
        src={defaultImage}
      />
    </picture>
  )
}

const Blur = ({ classes, alt, blur, disableLazy }) => {
  const hideOnLazyLoaded = useCallback((el) => {
    if (el) {
      const blurImg = el
      blurImg.parentNode.addEventListener('lazyloaded', () => {
        blurImg.style.opacity = 0
      })
    }
  }, [])

  if (blur && !disableLazy) {
    return (
      <img
        ref={hideOnLazyLoaded}
        key='blur'
        src={blur.data ? blur.data : blur.url}
        className={classes.imageBlur}
        alt={alt}
      />
    )
  }
  return null
}

const NoScript = ({ classes, alt, images }) => {
  if (images) {
    const mainImage = last(images)
    const srcSet = useMemo(() => images.map(item => (`${item.url} ${item.dimensions.width}w`)).join(), [images])
    return (
      <noscript>
        <img
          src={mainImage.url}
          srcSet={srcSet}
          className={classes.image}
          alt={alt}
        />
      </noscript>
    )
  }
  return null
}

const ResponsiveImage = React.forwardRef(function ResponsiveImage ({ images, blur, className, aspect, size, children, disableLazy, alt, hasRoundedCorners }, ref) {
  const classes = useStyles({ aspect, size, hasRoundedCorners })
  const imageAlt = get(last(images), ['alt'])
  return (
    <div className={cn(classes.container, className)} ref={ref}>
      <Picture classes={classes} alt={alt || imageAlt} images={images} disableLazy={disableLazy} />
      <Blur classes={classes} alt={alt || imageAlt} blur={blur} disableLazy={disableLazy} />
      <NoScript classes={classes} alt={alt || imageAlt} images={images} />
      {children}
    </div>
  )
})

const BLUR_PIXELS = 10

const useStyles = createUseStyles(theme => ({
  container: {
    position: 'relative',
    width: '100%',
    maxWidth: ({ size }) => size ? theme.imageWidths[size] : '100%',
    display: 'block',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&::before': {
      display: 'block',
      content: ({ aspect }) => aspect ? '""' : undefined,
      paddingTop: ({ aspect }) => aspect ? `${round(100 / aspect)}%` : undefined
    },
    borderRadius: ({ hasRoundedCorners }) => hasRoundedCorners ? '40px' : 'default'
  },
  image: {
    position: 'absolute',
    objectFit: 'cover',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'opacity 0.3s',
    fontFamily: '"object-fit: cover;"' // object-fit polyfill
  },
  imageBlur: {
    position: 'absolute',
    top: '-10%',
    left: '-10%',
    width: '120%',
    height: '120%',
    filter: blurFilterIsFastEnough ? `blur(${BLUR_PIXELS}px)` : undefined,
    objectFit: 'cover',
    fontFamily: '"object-fit: cover;"', // object-fit polyfill
    transition: 'opacity 400ms',
    pointerEvents: 'none'
  },
  link: {
    textDecoration: 'none'
  }
}), { name: 'ResponsiveImage' })

export default ResponsiveImage
