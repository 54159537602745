import React from 'react'
import ReactDOM from 'react-dom'
import JssProvider from './styles/JssProvider'
import { ThemeProvider } from 'react-jss'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'

import App from './components/App'
import theme from './styles/theme'
import createStore from './store'
// import ReactGA from 'react-ga'
import { REHYDRATED } from './actions'
// import { GA_DEBUG, GA_ID } from './config'
import initLazySizes from './initLazySizes'
import gsap from 'gsap'
import CSSPlugin from 'gsap/CSSPlugin'
import 'matter-wrap'
import Matter, { Common } from 'matter-js'

// XXX this shouldn’t be necessary, but in 3.0.0-beta.11 the code in gsap-core.js incorrectly imported CSSPlugin by
// name (it is exported as `default`) so the automatic registration of CSSPlugin does not occur.
gsap.registerPlugin(CSSPlugin)

Common._seed = Math.random()
Matter.use('matter-wrap')

// ReactGA.initialize(GA_ID, { debug: GA_DEBUG === 'true' })

function getPreloadedState () {
  const preloadedStateEl = document.getElementById('redux-preinit')
  if (preloadedStateEl) {
    const preloadedState = JSON.parse(preloadedStateEl.innerText)
    preloadedStateEl.parentNode.removeChild(preloadedStateEl)
    return preloadedState
  }
}

const { store } = createStore({ preloadedState: getPreloadedState() })
const rootEl = document.getElementById('root')

const render = App => (
  <ReduxProvider store={store}>
    <JssProvider>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ThemeProvider>
    </JssProvider>
  </ReduxProvider>
)

// Need to check if this was ssr rendered
ReactDOM.hydrate(
  render(App),
  rootEl,
  () => {
    const style = document.getElementById('jss-preinit')
    if (style) {
      style.parentNode.removeChild(style)
    }
    initLazySizes()
    store.dispatch({ type: REHYDRATED })
  }
)

if (module.hot) {
  module.hot.accept('./components/App', function () {
    const App = require('./components/App').default
    ReactDOM.render(render(App), rootEl)
  })
}
