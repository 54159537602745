import React from 'react'
import cn from 'classnames'
import ResponsiveImage from '../widgets/ResponsiveImage'
import injectStyles from 'react-jss'
import PageSection from './PageSection'

const FullWidthImageSlice = ({ classes, image, className }) => (
  <PageSection fullWidth>
    <ResponsiveImage className={cn(classes.image, className)} images={image.images} blur={image.blur} />
  </PageSection>
)

export default injectStyles(theme => ({
  image: {
    '&::before': {
      paddingTop: `50%`
    },
    [theme.breakpoints.up('md')]: {
      '&::before': {
        paddingTop: `33%`
      }
    }
  }

})
)(FullWidthImageSlice)
