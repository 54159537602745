import React from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import { supportedValue } from 'css-vendor'
import theme from '../../styles/theme'
import outlineText from '../../styles/outlineText'
import Case from '../widgets/Case'

// css-vendor doesn't correctly translate `justifyContent: 'space-between'` to `-ms-flex-pack: justify` for IE10, so we
// need to detect that case ourselves
const flexPackJustify = (
  supportedValue('-ms-flex-pack', 'justify') === 'justify' &&
    !supportedValue('-ms-flex-pack', 'space-between')
) ? { msFlexPack: 'justify' } : undefined

const SocialLinks = ({
  className, lightClassName = 'light', textClassName,
  facebook_link: facebookLink, twitter_link: twitterLink, instagram_link: instagramLink
}) => {
  const classes = useStyles()
  const SocialLink = ({ text, className, link }) => (
    <a className={cn(classes.link, className)} href={link} target='_blank'>
      <div className={cn(classes.text, textClassName)} style={flexPackJustify}>
        {text}
      </div>
    </a>
  )

  return (
    <div className={cn(classes.container, className)}>
      <Case className={classes.case} fill={theme.colors.secondary} />
      {facebookLink && <SocialLink text='Facebook' link={facebookLink.url} />}
      {twitterLink && <SocialLink text='Twitter' link={twitterLink.url} className={lightClassName} />}
      {instagramLink && <SocialLink text='Instagram' link={instagramLink.url} />}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    lineHeight: 0.9,
    display: 'block', // IE10 makes these all 0px high without this
    color: theme.colors.secondary,
    textTransform: 'uppercase',
    fontFamily: theme.fonts.heading,
    textDecoration: 'none',
    ...outlineText(theme.colors.white),
    position: 'relative',
    fontSize: theme.getRemValue(54),
    letterSpacing: 8,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.getRemValue(80)
    },
    '&:hover': {
      color: theme.colors.white
    }
  },
  text: {},
  case: {
    position: 'absolute',
    width: '70%',
    height: '70%',
    color: theme.colors.peach,
    transform: 'rotate(45deg)',
    pointerEvents: 'none'
  }
})

export default SocialLinks
