import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import flow from 'lodash/flow'
import { submitClear } from '../../actions'
import RichTextContainer from '../widgets/RichTextContainer'
import Button from '../widgets/Button'

class Complete extends PureComponent {
  componentDidMount () {
    window.scrollTo(0, 0)
  }

  render () {
    const {
      classes,
      complete_title: title,
      complete_copy: copy,
      donation,
      donationLink,
      afterDonationCopy
    } = this.props

    return (
      <div className={classes.container}>
        <div className={classes.copyText}>
          <h1 className={classes.title}>{title.text}</h1>
        </div>
        <RichTextContainer className={classes.copy} content={copy.html} />
        <div className={classes.donationContainer}>
          {donation && donation !== 'No donation' && (
            <Button linkInfo={donationLink} text={`Click here to complete your ${donation} pledge`} />
          )}
          {(!donation || donation === 'No donation') && (
            <Button linkInfo={donationLink} text={`Click here to donate to War Child`} />
          )}
        </div>
        {afterDonationCopy && (
          <div className={classes.copyText}>
            <div dangerouslySetInnerHTML={{ __html: afterDonationCopy.html }} />
          </div>
        )}
        <a className={classes.link} onClick={this.props.handleClear}>Click here to submit another artwork</a>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

function mapDispatchToProps (dispatch) {
  return {
    handleClear: () => dispatch(submitClear())
  }
}

export default flow([
  injectSheet((theme) => ({
    container: {
      width: '100%',
      // minHeight: 300,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: '100px'
    },
    copyText: {
      marginBottom: theme.spacing.md
    },
    title: {
      margin: 0,
      lineHeight: 1,
      fontSize: theme.getRemValue(60),
      maxWidth: 650,
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.getRemValue(80)
      }
    },
    copy: {
      // '& h2': {
      //   lineHeight: 1.2,
      //   fontSize: theme.getRemValue(24),
      //   fontFamily: theme.fonts.body,
      //   textAlign: 'center',
      //   textTransform: 'none',
      //   [theme.breakpoints.up('sm')]: {
      //     fontSize: theme.getRemValue(32),
      //     textAlign: 'inherit'
      //   }
      // }
    },
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'inherit',
        marginLeft: theme.spacing.sm
      }
    },
    donationContainer: {
      margin: '0px 0px 100px 0px'
    }

  })),
  connect(mapStateToProps, mapDispatchToProps)
])(Complete)
