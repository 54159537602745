import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import PageWidth from '../PageWidth'
import { getEventbriteId } from '../../selectors'
import { useSelector } from 'react-redux'

const BACKGROUND_STYLES = {
  'style-1': 'style1',
  'style-2': 'style2',
  'style-3': 'style3',
  'style-4': 'style4',
  'style-5': 'style5'
}

const Eventbrite = ({ backgroundStyle }) => {
  const classes = useStyles()
  const eventId = useSelector(getEventbriteId)
  const styleClassName = BACKGROUND_STYLES[backgroundStyle] || 'style1'
  const idTarget = `eventbrite-widget-container-${eventId}`

  useEffect(() => {
    if (idTarget) {
      window.EBWidgets.createWidget({
        widgetType: 'checkout',
        eventId: eventId,
        iframeContainerId: idTarget,
        iframeContainerHeight: 425
      })
    }
  }, [])

  if (!eventId) return null

  return (
    <section className={cn(classes.section, classes[styleClassName])}>
      <PageWidth>
        <div id={idTarget} />
      </PageWidth>
    </section>
  )
}

const useStyles = createUseStyles(theme => ({
  section: {
    overflow: 'hidden',
    position: 'relative',
    margin: 0,
    padding: [theme.spacing.lg, 0],
    backgroundColor: theme.colors.lightBlue
  },
  style1: {
    backgroundColor: theme.colors.blue
  },
  style2: {
    backgroundColor: theme.colors.lightBlue
  },
  style3: {
    backgroundColor: theme.colors.yellow
  },
  style4: {
    backgroundColor: theme.colors.beige
  },
  style5: {
    backgroundColor: theme.colors.white
  }
}))

export default Eventbrite
