import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../styles/theme'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import { getFloatingLink, showDonateButton } from '../../selectors'
import Link from '../Link'
import height from 'dom-helpers/query/height'

function useScrollingRotation (enabled) {
  const ref = useRef()

  useEffect(() => {
    if (enabled) {
      const tick = () => {
        const y = window.scrollY
        const rotation = (y + 60) * 0.1
        const diff = (document.documentElement.scrollHeight - window.innerHeight) - window.scrollY
        const footerHeight = height(document.getElementById('footer'))
        gsap.set(ref.current, { rotation, y: -Math.max(0, footerHeight - diff) })
      }
      gsap.ticker.add(tick)
      return () => {
        gsap.ticker.remove(tick)
      }
    }
  }, [enabled])

  return ref
}

const AWithRef = ({ withRef, withHref, ...props }) => <a {...props} ref={withRef} href={withHref} />

const DonateButton = () => {
  const classes = useStyles()
  const { link, title } = useSelector(getFloatingLink)
  const show = useSelector(showDonateButton)
  const ref = useScrollingRotation(show)

  if (!show) return null

  return (
    <Link
      className={classes.donateButton}
      to={link.url}
      target={link.target}
      withRef={ref}
      withHref={link.url}
      tagName={AWithRef}
    >
      <span className={classes.text}>{title}</span>
    </Link>
  )
}

const useStyles = createUseStyles({
  donateButton: {
    position: 'fixed',
    width: 100,
    height: 100,
    borderRadius: '50%',
    right: 16,
    bottom: 16,
    border: 0,
    fontSize: 30,
    backgroundColor: theme.colors.pink,
    color: theme.colors.white,
    fontFamily: theme.fonts.heading,
    textTransform: 'uppercase',
    fontWeight: 'normal',
    zIndex: 3,
    [theme.breakpoints.up('md')]: {
      width: 150,
      height: 150,
      fontSize: 40,
      right: 32,
      bottom: 32
    },
    '&:hover': {
      // backgroundColor: theme.colors.lightPink,
      color: theme.colors.blue
    }
  },
  background: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
  },
  text: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}, { name: 'DonateButton' })

export default DonateButton
