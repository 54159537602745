import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme, { afterFix } from '../../styles/theme'
import { formatDate } from '../../utils/date'

import ImageSlice from './ImageSlice'
import PageHero from './PageHero'
import FullWidthImageSlice from './FullWidthImageSlice'
import TextSlice from './TextSlice'
import QuoteSlice from './QuoteSlice'
import YouTubeSlice from './YouTubeSlice'
import PageSection from './PageSection'
import Section from '../widgets/Section'
import Tiles from '../Tiles'
import ShareLinks from './ShareLinks'
import LinkButton from './LinkButton'
import Eventbrite from '../Home/Eventbrite'

const slice = {
  text: ({ text }, i) => <TextSlice key={i} text={text} />,
  full_width_image: ({ image }, i) => <FullWidthImageSlice key={i} image={image} />,
  image: ({ image, size, position, spacing }, i) => <ImageSlice key={i} image={image} size={size} position={position} spacing={spacing} />,
  quote: ({ quote }, i) => <QuoteSlice key={i} quote={quote} />,
  youtube: ({ video }, i) => <YouTubeSlice key={i} embedded_content={video} />,
  button: ({ text, link }, i) => <LinkButton key={i} text={text} link={link} />,
  eventbrite: ({ background_style: backgroundStyle }, i) => <Eventbrite key={i} backgroundStyle={backgroundStyle} />
}

const Page = ({ page }) => {
  const classes = useStyles()

  const {
    title, hero_image: image, slices, tiles,
    published_date: publishedDate, show_share_links: showShareLinks, has_related_articles: hasRelatedArticles,
    type
  } = page

  console.log(page)

  const data = useMemo(() => slices.map((s, i) => {
    const selectedSlice = slice[s.type]
    if (!selectedSlice) return null
    return selectedSlice(s, i)
  }), [slices])

  return (
    <div className={classes.page}>
      {image.images && <PageHero image={image} />}
      <Section className={classes.section}>
        <PageSection className={classes.titleContainer}>
          {publishedDate && <time className={classes.date} dateTime={publishedDate}>{formatDate(publishedDate)}</time>}
          <h1 className={cn(classes.title, type)}>{title.text}</h1>
          <ShareLinks show_share_links={showShareLinks} />
        </PageSection>
        {data}
        {hasRelatedArticles && <h2 className={classes.relatedTitle}>Related Articles</h2>}
        {Object.keys(tiles).length > 0 && <Tiles tiles={tiles} color={theme.colors.gold} backgroundColor={theme.colors.white} />}
      </Section>
    </div>
  )
}

const useStyles = createUseStyles({
  page: {
    backgroundColor: theme.colors.white,
    position: 'relative',
    ...afterFix()
  },
  boxes: {
    opacity: 0.7
  },
  section: {
    position: 'relative'
  },
  titleContainer: {
    paddingTop: theme.sp(4),
    paddingBottom: theme.sp(4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.sp(8),
      paddingBottom: theme.sp(8)
    },
    marginBottom: 0
  },
  title: {
    fontFamily: theme.fonts.heading,
    fontSize: theme.getRemValue(50),
    lineHeight: 1,
    margin: 0,
    padding: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.getRemValue(100)
    },
    '&.news_article': {
      fontSize: theme.getRemValue(40),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.getRemValue(40)
      }
    }
  },
  date: {
    display: 'block',
    marginBottom: theme.sp(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.sp(2)
    }
  },
  relatedTitle: {
    width: '100%',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.heading,
    textAlign: 'center',
    paddingTop: theme.sp(4),
    marginBottom: theme.sp(3),
    fontSize: theme.getRemValue(20),
    letterSpacing: 10,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.getRemValue(40),
      letterSpacing: 24,
      marginBottom: theme.sp(12)
    }
  }

})

export default Page
