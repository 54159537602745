export const NAV_TOGGLED = 'NAV_TOGGLED'
export const NAV_CLOSED = 'NAV_CLOSED'

export const navToggled = () => {
  return { type: NAV_TOGGLED }
}

export const navClosed = () => {
  return { type: NAV_CLOSED }
}
