import React, { PureComponent } from 'react'
import injectSheet from 'react-jss'
import flow from 'lodash/flow'
import ArticleHero from './ArticleHero'
import SideBySide from './widgets/SideBySide'
import ImageHover from './widgets/ImageHover'
import Link, { NavLink } from './Link'
import theme from '../styles/theme'
import 'date-format-lite' // adds .format() to Date
import Tiles from './Tiles'
import PageWidth from './PageWidth'
import { formatDate } from '../utils/date'
import outlineText from '../styles/outlineText'

class NewsListing extends PureComponent {
  render () {
    const { classes, page: { banner_title: title, hero_image: image, articles, cta_text: ctaText, tiles } } = this.props

    return (
      <React.Fragment>
        <ArticleHero image={image} title={title} />
        <div className={classes.container}>
          <PageWidth withVerticalPadding>
            <nav className={classes.articles}>
              {articles.map((article, i) => (
                <SideBySide key={i} className={classes.article}>
                  <NavLink to={`/news/${article.uid}`} className={classes.imageContainer}>
                    <ImageHover className={classes.image} image={article.hero_image} blur={article.hero_image.blur} aspect={552 / 368} withOverlay={false} />
                  </NavLink>
                  <div className={classes.summaryContainer}>
                    <div className={classes.summaryContent}>
                      <time className={classes.date} dateTime={article.published_date}>{formatDate(article.published_date)}</time>
                      <h2 className={classes.title}>{article.title.text}</h2>
                      <div className={classes.summary} dangerouslySetInnerHTML={{ __html: article.summary.html }} />
                      <Link to={`/news/${article.uid}`} className={classes.button}>{ctaText}</Link>
                    </div>
                  </div>

                </SideBySide>
              ))}
            </nav>
          </PageWidth>
        </div>
        <Tiles color={theme.colors.gold} backgroundColor={theme.colors.white} tiles={tiles} />
      </React.Fragment>
    )
  }
}

export default flow([
  injectSheet((theme) => ({
    article: {
      '&:nth-child(2n)': {
        '& $summaryContainer': {
          [theme.breakpoints.up('md')]: {
            order: -1
          }
        }
      },
      '&:first-child': {
        marginTop: 0
      },
      '&:last-child': {
        marginBottom: 0
      }
    },
    title: {
      lineHeight: 1.15
    },
    container: {
      position: 'relative'
    },
    image: {
    },
    summaryContainer: {
    },
    summaryContent: {
      maxWidth: 460,
      position: 'relative'
    },
    button: {
      color: 'transparent',
      transition: 'all 0.3s',
      ...outlineText(theme.colors.secondary, 1),
      fontFamily: theme.fonts.heading,
      fontSize: theme.getRemValue(40),
      letterSpacing: 6,
      textTransform: 'uppercase',
      textDecoration: 'none',
      '&:hover': {
        color: theme.colors.secondary
      }
    },
    date: {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: theme.getRemValue(14),
      marginBottom: theme.spacing.sm
    }
  }))
])(NewsListing)
