import { RIBBON_FONT_SIZE_CHANGED, RIBBON_REMOVED } from '../actions'

const initialState = {}

const ribbon = (state = initialState, action) => {
  switch (action.type) {
    case RIBBON_FONT_SIZE_CHANGED:
      return {
        ...state,
        [action.payload.key]: action.payload.fontSize
      }
    case RIBBON_REMOVED:
      return {
        ...state,
        [action.payload.key]: undefined
      }
    default:
      return state
  }
}

export default ribbon
