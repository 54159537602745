import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import color from 'color'
import { getGlobalContent, isNavOpen } from '../../selectors'
import { navToggled } from '../../actions'
import { NavLink } from '../Link'
import useOutsideClickListener from '../../hooks/useOutsideClickListener'

export default () => {
  const global = useSelector(getGlobalContent)
  const expanded = useSelector(isNavOpen)
  const dispatch = useDispatch()
  const ignoreClicksRef = useRef()
  const classes = useStyles()

  const onOutsideClick = useCallback(e => {
    dispatch(navToggled())
  }, [expanded])

  const elementRef = useOutsideClickListener(expanded, onOutsideClick)

  const onMouseEnter = useCallback(() => {
    // on mobile, this will be immediately followed by a click, so set a flag to ignore subsequent clicks
    // this also helps on desktop - if you thought you'd need to click to open the menu, you might click before
    // you notice that it opened on hover, so the timeout will ignore clicks for a moment after opening on hover
    if (!expanded && !ignoreClicksRef.current) {
      dispatch(navToggled())
      ignoreClicksRef.current = setTimeout(() => {
        ignoreClicksRef.current = null
      }, 700)
    }
  }, [expanded])

  const onMouseLeave = useCallback(() => {
    if (expanded) {
      dispatch(navToggled())
      if (ignoreClicksRef.current) {
        clearTimeout(ignoreClicksRef.current)
        ignoreClicksRef.current = null
      }
    }
  }, [expanded])

  const onToggleClick = useCallback(() => {
    if (!ignoreClicksRef.current) {
      dispatch(navToggled())
    }
  }, [])

  return (
    <nav className={classNames(classes.menu, expanded && classes.expanded)}
      ref={elementRef}
      onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <button type='button' className={classNames(classes.button, expanded && classes.buttonExpanded)}
        aria-expanded={expanded} aria-controls='menu' onClick={onToggleClick}>
        <span className={classes.hamburger}>
          <span className={classes.hamburgerBar} />
          <span className={classes.hamburgerBar} />
          <span className={classes.hamburgerBar} />
        </span>
        <span className={classes.label}>Menu</span>
      </button>
      <ul id='menu' role='menu' className={classNames(classes.items, expanded && classes.itemsExpanded)} aria-expanded={expanded}>
        {global && global.menu.map((x, i) => <li key={i} role='menuitem' className={classes.item}>
          {x.link && x.link.url && <NavLink to={x.link.url} className={classes.link}>
            <span>{x.title}</span>
          </NavLink>}</li>
        )}
      </ul>
    </nav>
  )
}

const hamburgerWidth = 28
const hamburgerHeight = 19

const useStyles = createUseStyles((theme) => ({
  menu: {
    height: '100%',
    fontFamily: theme.fonts.heading,
    fontSize: 24,
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    position: 'static',
    [theme.breakpoints.up('sm')]: {
      position: 'relative'
    }
  },
  expanded: {
    overflow: 'visible'
  },
  button: {
    '&[type=button]': {
      appearance: 'none' // override normalize
    },
    color: theme.colors.white,
    backgroundColor: theme.colors.black,
    tapHighlightColor: color(theme.colors.primary).fade(0.85).rgb().string(),
    border: 0,
    width: '100%',
    height: '100%',
    padding: [0, theme.spacingPx.sm],
    [theme.breakpoints.up('sm')]: {
      padding: [0, theme.spacingPx.md - theme.spacingPx.sm / 2]
    },
    [theme.breakpoints.up('xxl')]: {
      padding: [0, theme.spacingPx.md - theme.spacingPx.sm / 2]
    },
    cursor: 'pointer',
    fontFamily: 'inherit',
    textTransform: 'inherit',
    letterSpacing: 'inherit',
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    transition: 'background-color 0.2s ease-out'
  },
  label: {
    margin: [0, theme.spacingPx.sm / 2],
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  hamburger: {
    display: 'inline-block',
    position: 'relative',
    width: hamburgerWidth,
    height: hamburgerHeight,
    margin: [0, theme.spacingPx.sm / 2],
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  hamburgerBar: {
    display: 'block',
    width: '100%',
    height: 3,
    backgroundColor: 'currentColor',
    position: 'absolute',
    left: 0,
    transition: 'opacity 0.2s ease-out',
    '&:nth-child(1)': {
      top: 0
    },
    '&:nth-child(2)': {
      top: Math.ceil(hamburgerHeight / 2 - 1.5),
      transition: 'transform 0.2s ease-out',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: '50%',
        top: 0,
        width: 0,
        height: '100%',
        backgroundColor: 'currentColor',
        transform: 'translateX(-50%) rotate(90deg)',
        transition: 'width 0.2s ease-out'
      }
    },
    '&:nth-child(3)': {
      bottom: 0
    },
    '$expanded &': {
      '&:nth-child(1)': {
        opacity: 0
      },
      '&:nth-child(2)': {
        transform: 'rotate(-45deg)',
        '&::before': {
          width: '100%'
        }
      },
      '&:nth-child(3)': {
        opacity: 0
      }
    }
  },
  items: {
    opacity: 0,
    transition: 'opacity 0.2s ease-out',
    backgroundColor: theme.colors.black,
    position: 'absolute',
    left: 0,
    top: '100%',
    padding: [34, 0],
    width: '100vw',
    pointerEvents: 'none',
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: 360
    }
  },
  itemsExpanded: {
    opacity: 1,
    pointerEvents: 'auto'
  },
  item: {
    display: 'block',
    position: 'relative'
  },
  link: {
    padding: `${theme.getRemValue(4)} ${theme.getRemValue(20)}`,
    textDecoration: 'none',
    display: 'block',
    color: 'currentColor',
    '&:hover': {
      '& $icon': {
        display: 'block',
        color: theme.colors.white
      },
      color: theme.colors.primary
    }
  },
  titleCopy: {
    position: 'absolute',
    transition: 'all 0.25s ease-in-out',
    left: 0,
    right: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: theme.colors.primary
  }
}))
