import React, { useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import {
  getCurrentTrackArtist, getCurrentTrackTitle, getPlayerTime, isPlayerLoading,
  isPlayerPaused
} from '../../selectors'
import color from 'color'
import { playerNext, playerPrev, playerToggle } from '../../actions'
import { getAccurateProgress } from '../../middleware/player'
import { ReactComponent as PlayIcon } from './play.svg'
import { ReactComponent as PauseIcon } from './pause.svg'

/**
 * Format a number of seconds in minutes and seconds as 'm:ss' e.g. '0:00' or '12:34'
 * @param seconds
 */
function formatTime (seconds) {
  seconds = Math.round(seconds)
  const minutes = Math.floor(seconds / 60)
  seconds = seconds % 60
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

export default () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const artist = useSelector(getCurrentTrackArtist)
  const title = useSelector(getCurrentTrackTitle)
  const paused = useSelector(isPlayerPaused)
  const loading = useSelector(isPlayerLoading)
  const time = useSelector(getPlayerTime)

  const toggle = useCallback(() => dispatch(playerToggle()), [])
  const prev = useCallback(() => dispatch(playerPrev()), [])
  const next = useCallback(() => dispatch(playerNext()), [])
  const progressRef = useRef()
  const animationFrameRef = useRef()

  useEffect(() => {
    if (progressRef.current) {
      const callback = () => {
        const percent = (1 - getAccurateProgress()) * 100
        progressRef.current.style.transform = `translateX(-${percent}%)`
        if (!paused) {
          animationFrameRef.current = window.requestAnimationFrame(callback)
        }
      }
      callback()
      return () => {
        if (animationFrameRef.current) {
          window.cancelAnimationFrame(animationFrameRef.current)
        }
      }
    }
  }, [paused])

  return (
    <aside className={classNames(classes.player, loading && classes.loading)}>
      <div className={classes.trackWrapper}>
        <header className={classes.trackInfo}>
          <h2 className={classes.artist}>{artist}</h2>
          <h3 className={classes.title}>{title}</h3>
        </header>
        <p className={classes.timeWrapper}>
          <span className={classes.progressBar} ref={progressRef}>
            <span className={classes.timeLabelWrapper}>
              <span className={classes.timeLabel}>{formatTime(time)}</span>
            </span>
            <span className={classes.progressOverlay} />
          </span>
        </p>
      </div>
      <nav className={classes.controls}>
        <button type='button' className={classNames(classes.control, classes.prev)} onClick={prev}>
          <span className={classNames(classes.controlText, classes.prevText)}>Prev</span>
        </button>
        <button type='button' className={classNames(classes.control, classes.play)} onClick={toggle}>
          <span className={classNames(classes.controlText, classes.playText)}>
            {paused ? <PlayIcon width={11} height={23} /> : <PauseIcon width={14} height={21} />}
          </span>
        </button>
        <button type='button' className={classNames(classes.control, classes.next)} onClick={next}>
          <span className={classNames(classes.controlText, classes.nextText)}>Next</span>
        </button>
      </nav>
    </aside>
  )
}

const useStyles = createUseStyles((theme) => ({
  player: {
    margin: 0,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 1,
    color: theme.colors.black
  },
  loading: {
    // this is present just for use by reference below
  },
  trackWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    height: '100%',
    alignItems: 'center',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#4AE0FD'
      // backgroundImage: `linear-gradient(to right, ${theme.colors.secondary} 0, ${theme.colors.primary} 100%)`
    }
  },
  trackInfo: {
    padding: [0, theme.spacing.sm, 0, 0],
    flex: '0 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      padding: [0, theme.spacing.md]
    }
  },
  artist: {
    fontSize: theme.getRemValue(18),
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 1.3
  },
  title: {
    extend: 'artist',
    fontSize: 12,
    fontFamily: theme.fonts.body,
    textTransform: 'initial',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  controlTypography: {
    fontFamily: theme.fonts.heading,
    fontSize: theme.getRemValue(18),
    textTransform: 'uppercase',
    letterSpacing: '0.15em',
    // it looks like, when we have a non-zero letter-spacing, the browser (Chrome at least, need to test) adds
    // an extra space at the end of the text so it doesn't center-align quite right - and this is noticeable
    // when we rotate the prev and next buttons in opposite directions. So this is to re-adjust the centering
    textIndent: '0.15em'
  },
  controls: {
    flex: '0 0 auto',
    height: '100%'
  },
  control: {
    color: theme.colors.white,
    extend: 'controlTypography',
    '&[type=button]': {
      appearance: 'none' // override normalize
    },
    backgroundColor: 'transparent',
    tapHighlightColor: color(theme.colors.teal).fade(0.85).rgb().string(),
    border: 0,
    outline: 0,
    padding: 0,
    cursor: 'pointer',
    width: 40,
    height: '100%',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: 50
    }
  },
  controlText: {
    display: 'block',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
  },
  prev: {
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  prevText: {
    transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)'
  },
  playText: {
  },
  nextText: {
    transform: 'translateX(-50%) translateY(-50%) rotate(90deg)'
  },
  timeWrapper: {
    extend: 'controlTypography',
    color: 'currentColor',
    position: 'absolute',
    left: 0,
    top: 60,
    right: 0,
    height: 3,
    lineHeight: '3px',
    paddingRight: 0,
    textAlign: 'center',
    pointerEvents: 'none',
    overflow: 'hidden',
    backgroundImage: `linear-gradient(to right, ${theme.colors.secondary} 0, ${theme.colors.primary} 100%)`,
    [theme.breakpoints.up('sm')]: {
      minWidth: 47,
      backgroundImage: 'none',
      position: 'relative',
      left: 'auto',
      top: 'auto',
      flex: '1 1 0px',
      lineHeight: '30px',
      width: 0,
      height: '100%',
      paddingRight: 30,
      '&::before': {
        width: 30
      }
    },
    margin: 0,
    flexShrink: 0
  },
  progressBar: {
    position: 'relative',
    display: 'block',
    width: '100%',
    height: '100%',
    transform: 'translateX(-100%)',
    left: 0,
    top: 0,
    right: 0,
    transformOrigin: '0 0',
    [theme.breakpoints.up('sm')]: {
      left: 34
    }
  },
  progressOverlay: {
    display: 'block',
    width: '120%',
    height: '100%',
    position: 'absolute',
    left: '100%',
    backgroundColor: theme.colors.background,
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.colors.black
    }
  },
  timeLabelWrapper: {
    display: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 66,
    marginLeft: -1, // one extra pixel to overlap the progress bar to the left, to cover up pixel rounding artefacts
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: 31,
      height: '100%',
      marginRight: 8
    }
  },
  '@keyframes loadingAnimation': {
    '55%': { color: color(theme.colors.white).fade(0.0).rgb().string() },
    '60%': { color: color(theme.colors.white).fade(1.0).rgb().string() },
    '70%': { color: color(theme.colors.white).fade(1.0).rgb().string() },
    '75%': { color: color(theme.colors.white).fade(0.0).rgb().string() }
  },
  timeLabel: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      width: 'auto',
      left: '50%',
      top: '50%',
      transform: 'translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -90deg)'
    },
    '$loading &': {
      animation: '$loadingAnimation 1.2s linear infinite'
    }
  }
}))
