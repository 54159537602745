import React from 'react'
import injectStyles from 'react-jss'
import cn from 'classnames'

const Checkmark = ({ classes, show, width = 42, height = 42 }) => (
  <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 52 52' version='1.1' className={cn(classes.checkmark, { animate: show })}>
    <circle cx='26' cy='26' r='25' className={cn(classes.circle, { animate: show })} />
    <path fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' className={cn(classes.check, { animate: show })} />
  </svg>
)

export default injectStyles(theme => ({
  checkmark: {
    borderRadius: '50%',
    display: 'block',
    strokeWidth: 2,
    stroke: 'currentColor',
    strokeMiterlimit: 10,
    margin: [0, theme.spacing.sm],
    '&.animate': {
      animation: '$fill 0.2s ease-in-out 0.7s forwards, $scale 0.3s ease-in-out 1.1s both'
    }
  },
  circle: {
    strokeDasharray: 166,
    strokeDashoffset: 166,
    strokeWidth: 2,
    strokeMiterlimit: 10,
    stroke: 'currentColor',
    fill: 'none',
    '&.animate': {
      animation: '$stroke 0.4s 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards'
    }
  },
  check: {
    transformOrigin: '50% 50%',
    opacity: 0,
    '&.animate': {
      animation: '$opacity 1s 0.2s cubic-bezier(0.65, 0, 0.45, 1) forwards'
    }
  },
  '@keyframes fill': {
    '100%': {
      boxShadow: ({ background }) => `inset 0px 0px 0px 30px ${background || theme.colors.black}`
    }
  },
  '@keyframes scale': {
    '0%, 100%': {
      transform: 'none'
    },
    '50%': {
      transform: 'scale3d(1.1, 1.1, 1)'
    }
  },
  '@keyframes stroke': {
    '100%': {
      strokeDashoffset: 0
    }
  },
  '@keyframes opacity': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }

}))(Checkmark)
