import React from 'react'
import { createUseStyles } from 'react-jss'
import SubscriptionForm from '../SubscriptionForm'
import theme from '../../styles/theme'
import RichTextContainer from '../widgets/RichTextContainer'

const SubscriptionTile = ({ title, placeholder, cta, copy }) => {
  const classes = useStyles()
  return (
    <div className={classes.container} >
      <div className={classes.wrapper} >
        <div className={classes.titleContainer} >
          <h2 className={classes.title} >{title && title.text}</h2>
        </div>
        <div className={classes.copyContainer}>
          <RichTextContainer content={copy && copy.html} />
        </div>
        <SubscriptionForm
          placeholder={placeholder}
          cta={cta}
        />
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    color: 'currentColor',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // maxWidth: 500,
    width: '100%'
  },
  titleContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.sp(2)
  },
  copyContainer: {
    margin: [theme.sp(2), theme.sp(1)]
  },
  title: {
    margin: 0,
    fontFamily: theme.fonts.heading,
    textTransform: 'uppercase',
    fontSize: theme.getRemValue(60),
    lineHeight: 0.9,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.getRemValue(80)
    }
  }
})

export default SubscriptionTile
