import React from 'react'
import injectStyles from 'react-jss'
import theme from '../../styles/theme'
import cn from 'classnames'

const SoundWave = ({ classes, color = theme.colors.white }) => {
  return (
    <div className={classes.boxContainer}>
      <div className={cn(classes.box, classes.box1)} />
      <div className={cn(classes.box, classes.box2)} />
      <div className={cn(classes.box, classes.box3)} />
      <div className={cn(classes.box, classes.box4)} />
      <div className={cn(classes.box, classes.box5)} />
    </div>
  )
}

export default injectStyles(theme => ({
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '64px',
    '--boxSize': '8px',
    '--gutter': '4px',
    width: 'calc((var(--boxSize) + var(--gutter)) * 5)'
  },
  box: ({ color }) => ({
    transform: 'scaleY(.4)',
    height: '100%',
    width: 'var(--boxSize)',
    background: color || theme.colors.white,
    animationDuration: '1.2s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    borderRadius: '8px'
  }),
  box1: {
    animationName: 'quiet'
  },
  box2: {
    animationName: 'normal'
  },
  box3: {
    animationName: 'quiet'
  },
  box4: {
    animationName: 'loud'
  },
  box5: {
    animationName: 'quiet'
  }
}))(SoundWave)
