import theme from './theme'

export const imageBlendClasses = (color = theme.colors.primary, imageSelector = '&', filter = 'grayscale(1)') => ({
  [`${imageSelector} img`]: {
    filter
  },
  [`${imageSelector}:after`]: {
    backgroundColor: color,
    content: '""',
    position: 'absolute',
    display: 'block',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 1,
    mixBlendMode: 'screen'
  }
})
