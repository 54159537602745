import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Link from '../Link'
import get from 'lodash/get'
import PageWidth from '../PageWidth'
import theme from '../../styles/theme'

const Collaborators = ({ slice: { type, items, title }, variation }) => {
  const classes = useStyles()
  const Container = ({ item, children }) => item.link && item.link.url
    ? <Link to={item.link.url} target={item.link.target} className={classes.link}>{children}</Link>
    : children

  return (
    <section className={classes.section}>
      <PageWidth>
        <div className={cn(type === 'sponsors' && classes.sponsors)}>
          <h4 className={classes.title}>{title.text}</h4>
          <div className={classes.collaborators}>
            {items.map((item, i) => <div key={i} className={cn(classes.imageContainer, { small: type === 'sponsors' })}>
              <Container item={item}>
                <img className={classes.image} src={get(item, ['image', 'images', 0, 'url'])} alt={item.title} />
              </Container>
            </div>)}
          </div>
        </div>
      </PageWidth>
    </section>
  )
}

const useStyles = createUseStyles({
  link: {
    width: '100%',
    textAlign: 'center'
  },
  section: {
    backgroundColor: theme.colors.white,
    // marginBottom: 0,
    overflow: 'hidden'
  },
  sponsors: {
    paddingTop: 0
  },
  title: {
    color: theme.colors.lightGrey,
    textAlign: 'center',
    fontSize: theme.getRemValue(15),
    letterSpacing: 6,
    [theme.breakpoints.up('md')]: {
      letterSpacing: 12,
      fontSize: theme.getRemValue(20)
    }
  },
  collaborators: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '50px',
    marginBottom: '40px'
  },
  imageContainer: {
    padding: theme.spacing.sm,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: '80px',
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '100px'
    }
  }
})

export default Collaborators
