import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import find from 'lodash/find'
import theme, { expandAbsolutely } from '../../styles/theme'
import ResponsiveImage from '../widgets/ResponsiveImage'
import detectIt from 'detect-it'
import queryString from 'query-string'
import { imageBlendClasses } from '../../styles/image'

export const IMAGE_TRANSITION_DURATION = 0.5

const variations = {
  primary: {
    'blend-mode': 'screen',
    sat: -100,
    blend: theme.colors.primary.substring(1),
    vib: 100
  },
  purple: {
    'blend-mode': 'screen',
    sat: -100,
    blend: theme.colors.secondary.substring(1),
    bri: -50
  },
  peach: {
    'blend-mode': 'screen',
    sat: -100,
    blend: theme.colors.peach.substring(1),
    bri: -50
  }
}

const ImageHover = ({ className, variation, classNames = {}, image, children, withOverlay = true, aspect, hoverEffect = true }) => {
  const classes = useStyles()
  if (!image) return null
  const supportsBlendSource = useMemo(() => find(image.images, img => img.supportBlendMode), [image])
  const blendImage = useMemo(() => {
    if (supportsBlendSource) {
      const blendEffectUrl = (url) => {
        return url + '&' + queryString.stringify(variations[variation] || variations.primary)
      }
      return {
        ...image,
        images: image.images.map(img => ({
          ...img,
          url: img.url_blend || blendEffectUrl(img.url),
          url_webp: img.url_webp_blend
        })),
        blur: {
          ...image.blur,
          url: image.blur.url_blend || blendEffectUrl(image.blur.url),
          url_webp: image.blur.url_webp_blend
        }
      }
    } else {
      return image
    }
  }, [image, supportsBlendSource])
  return (
    <ResponsiveImage className={cn(classes.image, className, { hoverEffect })} images={image.images} blur={image.blur} aspect={aspect}>
      {withOverlay && <div className={classes.overlay} />}
      <ResponsiveImage
        className={cn(classes.overlayImage, classNames.overlayImage, variation, { blend: !supportsBlendSource })}
        images={blendImage.images}
        blur={blendImage.blur}
      />
      {children}
    </ResponsiveImage>
  )
}

const useStyles = createUseStyles({
  image: {
    position: 'relative',
    '& img': {
      transition: `transform ${IMAGE_TRANSITION_DURATION}s ease-in-out`
    },
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&:hover img': {
        transform: 'scale(1.05)'
      },
      '&.hoverEffect:hover $overlayImage': {
        opacity: 0
      }
    })
  },
  overlay: {
    ...expandAbsolutely,
    backgroundColor: theme.colors.black,
    opacity: 0.7
  },
  overlayImage: {
    transition: `opacity ${IMAGE_TRANSITION_DURATION}s ease-in-out`,
    ...expandAbsolutely,
    '&.blend': {
      ...imageBlendClasses()
    }
  }

})

export default ImageHover
