import React, { useState, useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme, { centerAbsolutely } from '../../styles/theme'
import outlineText from '../../styles/outlineText'
import useElementSize from '../../hooks/useElementSize'
import PageWidth from '../PageWidth'
import Link from '../Link'
import get from 'lodash/get'

export default ({ slice }) => {
  const classes = useStyles({ color: theme.colors.blue })
  const containerRef = useRef()
  const textContainerRef = useRef()
  const [loaded, setLoaded] = useState()

  const fit = useCallback(() => {
    const maxFontSize = 140
    const minFontSize = 30

    const textContainer = textContainerRef.current
    const container = containerRef.current

    var fontSize = maxFontSize
    textContainer.style.fontSize = `${fontSize}px`

    var width = textContainer.offsetWidth
    var height = textContainer.offsetHeight
    var clientWidth = container.offsetWidth
    var clientHeight = container.offsetHeight

    while (width > clientWidth || height > clientHeight) {
      fontSize -= 1
      textContainer.style.fontSize = `${fontSize}px`
      width = textContainer.offsetWidth
      height = textContainer.offsetHeight
      if (fontSize < minFontSize) { return }
    }
    setLoaded(true)
  }, [])

  useElementSize(containerRef, fit)

  // I need to share the mouse constraints so the events get passed to both falling box canvases

  return (
    <section className={classes.container} >
      <PageWidth>
        <div className={classes.wrapper}>
          <div className={classes.hero} ref={containerRef}>
            <h1 className={cn(classes.text, { loaded })} ref={textContainerRef} dangerouslySetInnerHTML={{ __html: slice.title.html }} />
            {slice.button_text && (
              <div className={classes.buttonContainer}>
                <Link to={get(slice, ['link', 'url'])} className={classes.button}>
                  {slice.button_text}
                </Link>
              </div>
            )}
          </div>
        </div>
      </PageWidth>
    </section>
  )
}

const useStyles = createUseStyles(theme => ({
  container: {
    margin: 0,
    minHeight: 360,
    ...theme.header.height.mixin('height', height => `calc(100vh - ${height}px)`),
    backgroundColor: ({ color }) => color,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden'
  },
  wrapper: {
    // pointerEvents: 'none',
    maxWidth: theme.content.width,
    margin: 'auto',
    width: '100%',
    flex: '1 1 auto',
    display: 'flex',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl
  },
  hero: {
    flex: '1 1 auto',
    position: 'relative',
    overflow: 'hidden',
    color: theme.colors.black
  },
  text: {
    ...centerAbsolutely(),
    fontFamily: theme.fonts.heading,
    opacity: 0,
    fontSize: 40,
    whiteSpace: 'nowrap',
    lineHeight: 1,
    textAlign: 'center',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      lineHeight: 0.8,
      fontSize: 110
    },
    '&.loaded': {
      opacity: 1
    },
    '& span.highlight': {
      color: ({ color }) => color, // Not transparent, we need to mask the items behind it
      display: 'block',
      ...outlineText(theme.colors.primary, 2)
    }
  },
  matterOverlay: {
    pointerEvents: 'none'
  },
  buttonContainer: {
    position: 'absolute',
    bottom: '10%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%'
  },
  button: {
    padding: '14px 30px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '120%',
    borderRadius: '30px',
    background: '#E4E4E4',
    textDecoration: 'none',
    color: theme.colors.black,
    transition: 'all 0.3s',
    fontSize: theme.getRemValue(14),
    marginTop: theme.sp(8),
    display: 'inline-block',
    '&:hover': {
      color: theme.colors.blue,
      background: theme.colors.white
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.getRemValue(18),
      padding: '16px 40px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '120%'
    }
  }
}))
