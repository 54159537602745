import { canUseDom } from '../utils/dom'
import {
  GLOBAL_CONTENT_LOADED, PLAYER_NEXT, PLAYER_PAUSE, PLAYER_PLAY, PLAYER_PREV, PLAYER_TOGGLE, PLAYER_TRACK_CHANGED,
  PLAYER_TRACK_ENDED, playerDurationChanged,
  playerReady,
  playerTimeChanged,
  playerTrackChanged, playerTrackEnded,
  REHYDRATED
} from '../actions'
import { getCurrentTrackId, getCurrentTrackSrc, getFirstTrackId, getTracks, isPlayerPlaying } from '../selectors'
import findIndex from 'lodash/findIndex'
import 'dom-helpers'

let audio
export function getAccurateProgress () {
  let progress = 0
  if (audio) {
    if (audio.duration) {
      progress = audio.currentTime / audio.duration
    }
  }
  return progress
}

export default store => {
  if (canUseDom) {
    audio = document.createElement('audio')
    audio.addEventListener('timeupdate', () => {
      store.dispatch(playerTimeChanged(audio.currentTime))
    })
    audio.addEventListener('durationchange', () => {
      store.dispatch(playerDurationChanged(audio.duration))
    })
    audio.addEventListener('playing', () => {
      store.dispatch(playerReady())
    })
    audio.addEventListener('ended', () => {
      store.dispatch(playerTrackEnded())
    })

    return next => action => {
      switch (action.type) {
        case PLAYER_NEXT:
        case PLAYER_PREV:
        case PLAYER_TRACK_ENDED:
          const state = store.getState()
          const tracks = getTracks(state)
          const currentTrackId = getCurrentTrackId(state)
          let trackIndex = findIndex(tracks, ['uid', currentTrackId])
          if (trackIndex < 0) {
            trackIndex = 0
          } else if (action.type === PLAYER_PREV) {
            trackIndex--
            if (trackIndex < 0) {
              trackIndex = tracks.length - 1
            }
          } else {
            trackIndex++
            if (trackIndex >= tracks.length) {
              trackIndex = 0
            }
          }
          action = playerTrackChanged(tracks[trackIndex].uid)
          break
                // no default
      }

      const ret = next(action)

      switch (action.type) {
        case GLOBAL_CONTENT_LOADED:
        case REHYDRATED:
          const trackId = getFirstTrackId(store.getState())
          if (trackId) {
            store.dispatch(playerTrackChanged(trackId))
          }
          break
        case PLAYER_TRACK_CHANGED:
          const src = getCurrentTrackSrc(store.getState())
          if (src !== audio.src) {
            audio.src = src
          }
          // fall through to play/pause
        case PLAYER_PLAY:
        case PLAYER_PAUSE:
        case PLAYER_TOGGLE:
          const playing = isPlayerPlaying(store.getState())
          if (!audio.paused !== playing) {
            if (playing) {
              audio.play()
            } else {
              audio.pause()
            }
          }
          break
                // no default
      }
      return ret
    }
  } else {
    return next => action => next(action)
  }
}
