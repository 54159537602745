import { createSelector } from 'reselect'
import get from 'lodash/get'
import find from 'lodash/find'
import { getGlobalContent } from './content'

export const getCurrentTrackId = state => state.player.track
export const getCurrentTrackInfo = createSelector(
  getCurrentTrackId,
  getGlobalContent,
  (trackId, global) => find(get(global, ['tracks']), ['uid', trackId]) || get(global, ['tracks', 0])
)
export const getCurrentTrackArtist = state => get(getCurrentTrackInfo(state), ['artist'])
export const getCurrentTrackTitle = state => get(getCurrentTrackInfo(state), ['title'])
export const getCurrentTrackImage = state => get(getCurrentTrackInfo(state), ['image'])
export const getCurrentTrackSrc = state => get(getCurrentTrackInfo(state), ['audio_aac', 'url'])
export const isPlayerPaused = state => state.player.paused
export const isPlayerPlaying = state => !state.player.paused
export const isPlayerLoading = state => state.player.loading
export const getPlayerTime = state => state.player.time
export const getPlayerProgress = state => state.player.duration && state.player.time / state.player.duration
