export const RIBBON_FONT_SIZE_CHANGED = 'RIBBON_WIDTH_CHANGED'
export const RIBBON_REMOVED = 'RIBBON_REMOVED'

export const ribbonFontSizeChanged = (key, fontSize) => {
  return { type: RIBBON_FONT_SIZE_CHANGED, payload: { key, fontSize } }
}

export const ribbonRemoved = (key) => {
  return { type: RIBBON_REMOVED, payload: { key } }
}
