import React from 'react'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import Banner, { variations } from '../Banner'
import PageWidth from '../PageWidth'

const Banners = ({ slice }) => {
  const classes = useStyles()
  return (
    <PageWidth withVerticalPadding>
      <section className={classes.section}>
        {slice.items.map((item, i) => (
          <Banner
            key={i}
            link={get(item, ['link', 'url'])}
            variation={variations[i % variations.length]}
            title={item.title}
            backgroundImage={item.image} />
        ))}
      </section>
    </PageWidth>
  )
}

const useStyles = createUseStyles({
  section: {
    marginBottom: 0
  }
})

export default Banners
