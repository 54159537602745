let apiURL = (process.env.BASE_API_URL || process.env.REACT_APP_BASE_API_URL || '/api')

export const setApiUrl = (host) => {
  apiURL = host
}

export const getApiUrl = () => {
  return apiURL
}

export const GA_ID = (process.env.REACT_APP_GA_ID)
export const GA_DEBUG = (process.env.REACT_APP_GA_DEBUG)
