export const NEXT_GALLERY_PAGE = 'NEXT_GALLERY_PAGE'
export const SET_GALLERY_FILTER = 'SET_GALLERY_FILTER'

export const nextGalleryPage = () => {
  return { type: NEXT_GALLERY_PAGE }
}

export const setGalleryFilter = (query, track) => {
  return { type: SET_GALLERY_FILTER, payload: { query, track } }
}
