import { GLOBAL_CONTENT_LOADED, CLOSE_POPUP } from '../actions'

const initialState = {
  open: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_CONTENT_LOADED:
      return {
        ...state,
        open: action.payload.popup_enabled === 'Yes'
      }
    case CLOSE_POPUP:
      return {
        ...state,
        open: false
      }

    default:
      return state
  }
}

export default reducer
