import React from 'react'
import cn from 'classnames'
import ResponsiveImage from '../widgets/ResponsiveImage'
import injectStyles from 'react-jss'

const PageHero = ({ classes, image, className }) => (
  <ResponsiveImage className={cn(classes.image, className)} images={image.images} blur={image.blur} />
)

export default injectStyles(theme => ({
  image: {
    '&:before': {
      content: '""',
      paddingTop: `50%`
    },
    [theme.breakpoints.up('md')]: {
      '&:before': {
        content: '""',
        paddingTop: `33%`
      }
    }
  }

})
)(PageHero)
