import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { showNotification, getNotificationCopy } from '../selectors'
import { notificationDismiss } from '../actions'
import theme from '../styles/theme'
import { ReactComponent as CloseIcon } from '../img/close.svg'

const Notification = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const show = useSelector(showNotification)
  const copy = useSelector(getNotificationCopy)
  const dismissOnClick = useCallback(() => {
    dispatch(notificationDismiss())
  }, [])

  return (
    <div className={cn(classes.notification, { show })}>
      <div className={classes.container}>
        {copy && <div dangerouslySetInnerHTML={{ __html: copy.html }} />}
        <button className={classes.closeButton} onClick={dismissOnClick}>
          <CloseIcon className={classes.closeIcon} />
        </button>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  notification: {
    position: 'fixed',
    ...theme.header.height.mixin('top'),
    right: 0,
    maxWidth: 500,
    transition: '0.25s ease-in-out',
    transform: 'translate(0, -100%)',
    zIndex: theme.zIndex.header - 1,
    '&.show': {
      transform: 'translate(0, 0)'
    }
  },
  container: {
    backgroundColor: theme.colors.black,
    color: theme.colors.white,
    margin: theme.sp(2),
    padding: [theme.sp(1), theme.sp(2)],
    fontSize: theme.getRemValue(16),
    display: 'flex',
    alignItems: 'flex-start',
    '& .highlight': {
      color: theme.colors.primary
    },
    '& p': {
      margin: 0
    },
    '& a': {
      color: 'currentColor'
    }
  },
  closeButton: {
    color: 'currentColor',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    padding: 0,
    marginLeft: theme.sp(2),
    cursor: 'pointer',
    width: '1.5em',
    height: '1.5em',
    '&:hover': {
      color: theme.colors.primary
    }
  },
  closeIcon: {
    width: 12,
    height: 12
  }
})

export default Notification
