import { LINE_HEIGHT, getRemValue } from './theme'

const globalStyles = (theme) => (
  {
    '@global': {
      html: {
        fontFamily: theme.fonts.body,
        boxSizing: 'border-box',
        fontSize: theme.base.fontSize,
        '-webkit-font-smoothing': 'antialiased',
        lineHeight: theme.base.lineHeight,
        [theme.breakpoints.down('sm')]: {
          fontSize: '16px'
        }
      },
      body: {
        margin: 0,
        backgroundColor: theme.colors.background
      },
      '*, *::before, *::after': {
        boxSizing: 'inherit'
      },
      'a > img': {
        border: 0
      },
      'textarea, input, button': {
        '&:focus': {
          // IE11 will apply a red outline to :invalid inputs after form submit, but not to :focus:invalid
          // inputs because of this rule. It would be nice to not apply this outline:none rule to those
          // inputs so that the red outline stays while you fix the validation error - but the :valid and
          // :invalid pseudoclasses apply even before you submit, so using :focus:valid or
          // :focus:not(:invalid) is no good either. We'll just have to live with the red outline appearing
          // and disappearing on IE after submit
          outline: 'none'
        },
        // Firefox has a browser style for :-moz-ui-invalid that sets a red outline via a boxShadow, and it
        // is applied on initial page load (pre-submit) - turn that off
        boxShadow: 'none'
      },
      'nav > ul': {
        listStyleType: 'none',
        margin: 0,
        padding: 0
      },
      '.sr-only': {
        position: 'absolute !important',
        width: 1,
        height: 1,
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)'
      },
      'p, h1, h2, h3, h4, h5, h6, section, aside, ul': {
        margin: 0,
        marginBottom: `${LINE_HEIGHT}rem`
      },
      'h1, h2, h3, h4, h5': {
        fontFamily: theme.fonts.heading,
        fontWeight: 'normal',
        fontStyle: 'normal',
        textTransform: 'uppercase',
        lineHeight: 1.1
      },
      h1: {
        fontSize: getRemValue(60),
        [theme.breakpoints.up('md')]: {
          fontSize: getRemValue(80)
        }
      },
      h2: {
        fontSize: getRemValue(40),
        [theme.breakpoints.up('md')]: {
          fontSize: getRemValue(60)
        }
      },
      h3: {
        fontSize: getRemValue(32)
      },
      h4: {
        fontSize: getRemValue(20)
      },
      h5: {
        fontSize: getRemValue(16),
        lineHeight: 'default'
      },
      'button,input,optgroup,select,textarea': {
        // this is in normalize.css but if that takes a moment to load, the font size in the menu is tiny
        // so this is just to avoid the bump when normalize.css loads
        fontSize: '100%'
      },
      a: {
        color: theme.colors.black,
        '&:hover': {
          color: theme.colors.primary
        }
      },
      '::selection': {
        background: '#ffb7b7'
      },
      '::-moz-selection': {
        background: '#ffb7b7'
      },
      '@keyframes quiet': {
        '25%': {
          transform: 'scaleY(.6)'
        },
        '50%': {
          transform: 'scaleY(.4)'
        },
        '75%': {
          transform: 'scaleY(.8)'
        }
      },
      '@keyframes normal': {
        '25%': {
          transform: 'scaleY(1)'
        },
        '50%': {
          transform: 'scaleY(.4)'
        },
        '75%': {
          transform: 'scaleY(.6)'
        }
      },
      '@keyframes loud': {
        '25%': {
          transform: 'scaleY(1)'
        },
        '50%': {
          transform: 'scaleY(.4)'
        },
        '75%': {
          transform: 'scaleY(1.2)'
        }
      }
    }
  }
)

export default globalStyles
