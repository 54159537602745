import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import forEach from 'lodash/forEach'
import theme, { aspectRatio } from '../../styles/theme'
import HeaderTile from './HeaderTile'
import TextTile from './TextTile'
import SocialTile from './SocialTile'
import ImageTile from './ImageTile'
import SubscriptionTile from './SubscriptionTile'
import CircleQuoteTile from './CircleQuoteTile'
import PageWidth from '../PageWidth'

const promoTile = {
  title_tile: ({ title, subtitle, link, call_to_action_text: ctaText, image }, color, index) => <HeaderTile title={title} image={image} subtitle={subtitle} link={link} cta_text={ctaText} index={index} />,
  social_tile: (slice) => <SocialTile />,
  image_tile: (slice) => <ImageTile image={slice.image} link={slice.link} cta_text={slice.call_to_action_label} />,
  article_link_tile: (slice, index, withButton) => <TextTile title={slice.body.title} text={slice.body.summary} image={slice.body.hero_image} link={slice.document} published_date={slice.body.published_date}
    withButton={withButton} />,
  small_title_and_text: (slice, color) => <TextTile title={slice.title} text={slice.text} link={slice.link} color={color} cta_text={slice.call_to_action_label} />,
  subscription_tile: (slice) => <SubscriptionTile title={slice.title} placeholder={slice.placeholder_text} cta={slice.cta_text} copy={slice.copy} />,
  circle_slice: (slice) => <CircleQuoteTile text={slice.text} link={slice.link} cta_text={slice.cta_text} />
}

const Tiles = ({ title, tiles, showArticleImages, color }) => {
  const classes = useStyles()

  var index = 0
  var tileComponents = useMemo(() => {
    const components = []
    forEach(tiles, tile => {
      if (tile.type === 'article_link_tile') {
        if (showArticleImages) {
          // Add the extra image tile, but only if we are the first on a line
          components.push(<div key={index} className={cn(classes.tile)}>
            <ImageTile image={tile.body.hero_image} link={tile.document} />
          </div>)
          index++
        }
        components.push(<div key={index} className={cn(classes.tile, 'alternate')}>
          {promoTile[tile.type](tile, color, index, !showArticleImages)}
        </div>)
        index++
      } else {
        components.push(<div key={index} className={cn(classes.tile, { alternate: color === theme.colors.gold && index % 2 !== 0 })}>
          {promoTile[tile.type](tile, color, index)}
        </div>)
        index++
      }
    })
    return components
  }, [tiles, showArticleImages, color])

  return (
    <section className={classes.section}>
      <PageWidth withVerticalPadding>
        <div className={classes.container}>
          {title && title.text && <h2 className={classes.title}>{title.text}</h2>}
          <div className={classes.tilesContainer}>
            {tileComponents}
          </div>
        </div>
      </PageWidth>
    </section>
  )
}

// const gridLayout = (gutterTopBottom, gutterLeftRight) => ({
//   margin: [-gutterTopBottom, -gutterLeftRight],
//   '& > *': {
//     width: `calc(50% - ${gutterLeftRight * 2}px)`,
//     margin: [gutterTopBottom, gutterLeftRight]
//   }
// })

const useStyles = createUseStyles({
  section: {
    marginBottom: 0,
    backgroundColor: theme.colors.anotherRed,
    position: 'relative',
    overflow: 'hidden'
  },
  container: {
    display: 'block',
    color: theme.colors.white,
    '&:after': {
      clear: 'both',
      content: '""',
      display: 'table'
    },
    '&.light': {
      color: theme.colors.black
    }
  },
  title: {
    position: 'relative',
    fontFamily: theme.fonts.heading,
    textAlign: 'center',
    color: 'currentColor',
    letterSpacing: 10,
    lineHeight: 1,
    marginBottom: theme.sp(8),
    fontSize: theme.getRemValue(20),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.getRemValue(32)
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.sp(12),
      letterSpacing: 24,
      fontSize: theme.getRemValue(40)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.sp(18)
    }
  },
  tilesContainer: {
    // '& > *:not(:last-child)': {
    //   marginBottom: theme.sp(4)
    // },
    // [theme.breakpoints.up('md')]: {
    //   display: 'flex',
    //   flexWrap: 'wrap',
    //   ...gridLayout(theme.sp(3), theme.sp(3))
    // }
  },
  tile: {
    ...aspectRatio(550 / 360)
  }
})

export default Tiles
