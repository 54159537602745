import { BREAKPOINT_CHANGED, ELEMENT_SIZE_CHANGED } from '../actions'
import { current } from '../styles/breakpoints'

const initialState = {
  breakpoint: current()
}

const layout = (state = initialState, action) => {
  switch (action.type) {
    case BREAKPOINT_CHANGED:
      return {
        ...state,
        breakpoint: action.payload.breakpoint
      }

    case ELEMENT_SIZE_CHANGED:
      return {
        ...state,
        sizes: {
          ...state.sizes,
          [action.payload.name]: {
            width: action.payload.width,
            height: action.payload.height
          }
        }

      }
    default:
      return state
  }
}

export default layout
