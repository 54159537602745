import {
  SUBMIT_REQUEST,
  SUBMIT_SUCCESS,
  SUBMIT_ERROR,
  SUBMIT_CLEAR
} from '../actions'

const initialState = {
  status: 'idle'
}

const submission = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_REQUEST:
      return {
        ...state,
        status: 'loading',
        message: 'Please wait...',
        donation: action.payload.donation
      }
    case SUBMIT_ERROR:
      return {
        ...state,
        status: 'error',
        message: 'Oops, something has gone wrong, please try again'
      }
    case SUBMIT_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: 'Your artwork has been submitted'
      }
    case SUBMIT_CLEAR:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default submission
