import {
  PLAYER_TRACK_CHANGED, PLAYER_PAUSE, PLAYER_PLAY, PLAYER_TIME_CHANGED, PLAYER_TOGGLE,
  PLAYER_LOADING, PLAYER_READY, PLAYER_DURATION_CHANGED
} from '../actions'

const initialState = {
  track: null,
  time: 0,
  duration: 0,
  paused: true,
  loading: false
}

const player = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_TRACK_CHANGED:
      return {
        ...state,
        ...action.payload,
        time: 0,
        loading: (!state.paused || action.payload.paused === false)
      }
    case PLAYER_PLAY:
      return {
        ...state,
        paused: false,
        loading: state.paused
      }
    case PLAYER_PAUSE:
      return {
        ...state,
        paused: true,
        loading: false
      }
    case PLAYER_TOGGLE:
      return {
        ...state,
        paused: !state.paused,
        loading: state.paused
      }
    case PLAYER_TIME_CHANGED:
      return {
        ...state,
        time: action.payload.time
      }
    case PLAYER_DURATION_CHANGED:
      return {
        ...state,
        duration: action.payload.duration
      }
    case PLAYER_LOADING:
      return {
        ...state,
        loading: true
      }
    case PLAYER_READY:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export default player
