import { createStore, applyMiddleware, compose as defaultCompose, combineReducers } from 'redux'
import { connectRoutes } from 'redux-first-router'
import restoreScroll from 'redux-first-router-restore-scroll'
import routesMap from './routes'
import { nprogressMiddleware } from 'redux-nprogress'
import createSagaMiddleware from 'redux-saga'
import layoutMiddleware from './middleware/layout'
import navMiddleware from './middleware/nav'
import playerMiddleware from './middleware/player'
import reducers from './reducers'
import sagas from './sagas'
// import gaMiddleware from './middleware/ga'
// import gtmMiddleware from './middleware/gtm'
import notificationMiddleware from './middleware/notification'
import ignoreScrollDuringTransitionMiddleware, { getStateStorage } from './middleware/ignoreScrollDuringTransition'
import querystring from 'query-string'

export default function ({ preloadedState, initialEntries }) {
  const { reducer, middleware, enhancer, thunk } = connectRoutes(
    routesMap,
    {
      initialEntries,
      restoreScroll: restoreScroll({
        // We’ll restore the scroll position as part of the transitions in the Page component.
        manual: true,
        stateStorage: getStateStorage()
      }),
      querySerializer: querystring
    }
  )

  let compose = defaultCompose
  /* global __REDUX_DEVTOOLS_EXTENSION_COMPOSE__:false */
  // if (process.env.NODE_ENV !== 'production' && typeof __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
  if (typeof __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
    // in development mode, if you install the Redux Devtools extension (see https://github.com/zalmoxisus/redux-devtools-extension)
    // then this will connect to the dev tools and allow you to inspect Redux state
    compose = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  }

  const sagaMiddleware = createSagaMiddleware()

  const rootReducer = combineReducers({
    ...reducers,
    location: reducer
  })
  const middlewares = applyMiddleware(
    middleware,
    nprogressMiddleware(),
    navMiddleware,
    playerMiddleware,
    sagaMiddleware,
    // gaMiddleware,
    // gtmMiddleware,
    notificationMiddleware,
    ignoreScrollDuringTransitionMiddleware,
    // thunk,
    layoutMiddleware
  )
  const enhancers = compose(enhancer, middlewares)

  // Create Redux store with initial state, if specified
  const store = createStore(rootReducer, preloadedState, enhancers)

  sagaMiddleware.run(sagas)

  if (module.hot && process.env.NODE_ENV === 'development') {
    module.hot.accept('./reducers/index', () => {
      const reducers = require('./reducers/index')
      const rootReducer = combineReducers({ ...reducers, location: reducer })
      store.replaceReducer(rootReducer)
    })
  }

  return { store, routerThunk: thunk }
}
