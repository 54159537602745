import mapValues from 'lodash/mapValues'
import * as breakpoints from './breakpoints'
import { round } from '../utils/math'

export const LINE_HEIGHT = 1.4
export const BASE_FONT_SIZE_PIXELS = 22

export function getRemValue (sizeInPixels) {
  return `${round(sizeInPixels / BASE_FONT_SIZE_PIXELS)}rem`
}

export const expandAbsolutely = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
}

export function centerAbsolutely () {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

export function flexCenter (inline = false) {
  return {
    display: inline ? 'inline-flex' : 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

export function afterFix () {
  return {
    '&:after': {
      clear: 'both',
      content: '""',
      display: 'table'
    }
  }
}

export function aspectRatio (ratio = 1) {
  return {
    position: 'relative',
    '&::before': {
      display: 'block',
      content: '""',
      paddingTop: `${100 / ratio}%`
    },
    '& > *': {
      ...expandAbsolutely
    }
  }
}

const colors = {
  background: '#FFF',
  black: '#000',
  white: '#FFF',
  primary: '#FF564D',
  secondary: '#583BC4',
  peach: '#fd9e7b',
  teal: '#009169',
  gold: '#fbd469',
  blue: '#4878FF',
  yellow: '#FEC600',
  pink: '#FF50BD',
  lightPink: '#FF9BD9',
  lightBlue: '#C1F5FF',
  beige: '#FAF7EF',
  darkBlue: '#1E3EE2',
  lightGrey: '#b5b5b5',
  anotherRed: '#F0441E'
}

const imageWidths = {
  small: 240,
  medium: 460,
  large: 940,
  xlarge: '100%'
}

const spacingFactors = {
  xs: 0.25,
  sm: 0.5,
  md: 1,
  lg: 2,
  xl: 3
}
const spacing = mapValues(spacingFactors, factor => getRemValue(BASE_FONT_SIZE_PIXELS * LINE_HEIGHT * factor))
const spacingPx = mapValues(spacingFactors, factor => BASE_FONT_SIZE_PIXELS * LINE_HEIGHT * factor)
const fonts = {
  heading: `'Anton', sans-serif`,
  body: `'DM Sans', sans-serif`,
  sub: `'DM Mono', monospace`
}

const sp = (space) => space * (BASE_FONT_SIZE_PIXELS / 2)

export default {
  sp,
  base: {
    fontSize: BASE_FONT_SIZE_PIXELS,
    lineHeight: LINE_HEIGHT
  },
  content: {
    width: 1440,
    padding: (prop = 'padding', func = x => x) => ({
      [prop]: func(sp(3)),
      [breakpoints.up('md')]: {
        [prop]: func(sp(12))
      },
      [breakpoints.up('lg')]: {
        [prop]: func(sp(18))
      }
    })
  },
  fonts,
  zIndex: {
    header: 1000,
    content: 900,
    overlay: 1100,
    dialog: 1200
  },
  breakpoints,
  colors: colors,
  imageWidths: imageWidths,
  getRemValue,
  animation: {
    easing: {
      standardCurve: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
      decelerationCurve: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
      accelerationCurve: 'cubic-bezier(0.4, 0.0, 1, 1)',
      sharpCurve: 'cubic-bezier(0.4, 0.0, 0.6, 1)'
    }
  },
  header: {
    height: {
      mixin: (prop, formatter = v => v) => ({
        [prop]: formatter(63),
        [breakpoints.up('sm')]: {
          [prop]: formatter(80),
          paddingBottom: 0
        }
      })
    }
  },
  spacingPx,
  spacing,
  mixin: {
    placeholder: (props) => ({
      '&::-webkit-input-placeholder': { /* Chrome/Opera/Safari */
        ...props
      },
      '&::-moz-placeholder': { /* Firefox 19+ */
        ...props
      },
      '&:-ms-input-placeholder': { /* IE 10+ */
        ...props
      },
      '&:-moz-placeholder': { /* Firefox 18- */
        ...props
      }
    })
  },
  hover: {
    brightness: {
      filter: 'brightness(110%)',
      transition: 'filter 0.35s ease-in-out'
    }
  },
  button: {
    mixin: (backgroundColor = colors.primary, color = colors.white) => ({
      ...flexCenter(true),
      color,
      backgroundColor,
      padding: [getRemValue(14), spacing.md],
      border: 'none',
      transition: 'all 0.2s',
      textTransform: 'uppercase',
      fontSize: getRemValue(24),
      fontFamily: fonts.heading,
      cursor: 'pointer',
      textDecoration: 'none',
      lineHeight: 1.15,
      minWidth: 150,
      '& svg': {
        marginRight: spacing.sm
      },
      '&:hover': {
        color
      }
    })
  }

}
