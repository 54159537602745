import {
  NOTIFICATION_DISMISS,
  notificationShow,
  REHYDRATED
} from '../actions'
import { hasNotification, getNotificationCopy } from '../selectors'
import hash from 'object-hash'

export default store => next => action => {
  const result = next(action)
  switch (action.type) {
    case NOTIFICATION_DISMISS:
      const notificationCopy = getNotificationCopy(store.getState())
      const notificationHash = hash(notificationCopy)
      window.localStorage.setItem('notification', notificationHash)
      break
    case REHYDRATED:
      if (hasNotification(store.getState())) {
        const notificationHash = window.localStorage.getItem('notification')
        const notificationCopy = getNotificationCopy(store.getState())
        if (notificationHash && notificationHash === hash(notificationCopy)) {
          break
        }
        store.dispatch(notificationShow())
      }
      break
  }
  return result
}
