const hydrateApp = () => {
  require('./hydrateApp')
}

const loadPolyfills = async () => {
  // can't even use import() without polyfilling Promise....
  if (typeof Promise === 'undefined') {
    require('promise/lib/rejection-tracking').enable()
    window.Promise = require('promise/lib/es6-extensions.js')
    await import(/* webpackChunkName: 'polyfills' */ './polyfills')
  }
  require('fetch-everywhere')
  Object.assign = require('object-assign')

  // require('core-js/es6/map')
  // require('core-js/es6/set')

  require('raf/polyfill')
  require('url-polyfill')
}

loadPolyfills()
  .then(hydrateApp)
