export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS'
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const REMOVE_UPLOADED_FILE = 'REMOVE_UPLOADED_FILE'
export const CANCEL_FILE_UPLOAD = 'CANCEL_FILE_UPLOAD'

export const uploadFile = (file) => {
  return { type: UPLOAD_FILE, payload: { file } }
}

export const updateUploadFileProgress = (progress) => {
  return { type: UPLOAD_FILE_PROGRESS, payload: { progress } }
}

export const uploadFileError = (message) => {
  return { type: UPLOAD_FILE_ERROR, payload: { message } }
}

export const uploadFileSuccess = (data) => {
  return { type: UPLOAD_FILE_SUCCESS, payload: data }
}

export const removeUploadedFile = (cachedFile) => {
  return { type: REMOVE_UPLOADED_FILE, payload: cachedFile }
}

export const cancelFileUpload = () => {
  return { type: CANCEL_FILE_UPLOAD }
}
