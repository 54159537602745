import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import cn from 'classnames'
import { expandAbsolutely } from '../styles/theme'
import RichTextContainer from './widgets/RichTextContainer'
import { getGlobalContent, isPopupOpen } from '../selectors'
import { closePopup } from '../actions'
import arrow from '../img/arrow-right.svg'
import Transition from 'react-transition-group/Transition'
import SubscriptionForm from './SubscriptionForm'

class Popup extends PureComponent {
    setRef = (ref) => { this.ref = ref }

    render () {
      const { classes, global, close, open } = this.props
      if (!global) return null // eslint-disable-line
      return (
        <Transition in={open} timeout={350} unmountOnExit>
          <section className={cn(classes.container, { hide: !open })} ref={this.setRef}>
            <div className={classes.inner}>
              <div>
                <button className={classes.button} onClick={close}>
                  {global.close_popup_text}
                  <img src={arrow} alt='Arrow icon' />
                </button>
                <h1 className={classes.title}>{global.popup_title.text}</h1>
                <RichTextContainer content={global.copy.html} />
                <SubscriptionForm
                  className={classes.form}
                  placeholder={global.signup_placeholder}
                  cta={global.signup_button_text}
                  inputClassName={classes.input}
                  buttonClassName={classes.signupButton}
                  errorClassName={classes.error}
                />
              </div>
            </div>
          </section>
        </Transition>
      )
    }
}

const mapStateToProps = (state) => ({
  open: isPopupOpen(state),
  global: getGlobalContent(state)
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closePopup())
})

export default injectSheet((theme) => ({
  container: {
    position: 'fixed',
    overflow: 'auto',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    zIndex: theme.zIndex.header + 1,
    backgroundColor: 'rgba(0, 0, 0, 0.92)',
    color: theme.colors.white,
    fontSize: theme.getRemValue(16),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.getRemValue(18)
    },
    lineHeight: 1.8,
    transition: 'opacity 0.35s ease-in-out',
    '&.hide': {
      opacity: 0
    },
    '& a': {
      color: theme.colors.gold,
      transition: 'opacity 0.15s',
      '&:hover': {
        color: theme.colors.gold,
        opacity: 0.8
      }
    }
  },
  button: {
    fontFamily: theme.fonts.heading,
    margin: [0, 0, theme.spacing.md, 'auto'],
    fontSize: theme.getRemValue(24),
    textTransform: 'uppercase',
    color: theme.colors.gold,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    },
    '& img': {
      marginLeft: theme.spacing.sm
    }
  },
  inner: {
    padding: theme.spacing.md,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 960,
    margin: [0, 'auto'],
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing.xl,
      ...expandAbsolutely
    }
  },
  title: {
    position: 'relative',
    display: 'inline-block',
    fontSize: theme.getRemValue(48),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.getRemValue(68)
    },
    textDecoration: 'underline'
  },
  form: {
    marginTop: theme.spacing.lg,
    display: 'block',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: 64,
      display: 'flex'
    }
  },
  input: {
    height: 64,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  signupButton: {
    height: 64,
    width: '100%',
    '& svg path': {
      fill: `${theme.colors.black} !important`
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  error: {
    backgroundColor: 'transparent'
  }
}))(connect(mapStateToProps, mapDispatchToProps)(Popup))
