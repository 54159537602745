import React from 'react'
import { createUseStyles } from 'react-jss'
import RecordBox from './RecordBox'
import theme from '../../../styles/theme'

const BOX_HEIGHT = 170
const BOX_RADIUS = 7
const BOX_SPACING = BOX_HEIGHT * 0.037

const BOX_COLUMNS_SET = [
  {
    offset: 0,
    boxes: [
      { b: '#FEC600', c: '#FF6C60', hc: 'top' },
      { b: '#F0441E', c: '#FF9BD9' },
      { b: '#4AE0FD', c: '#C1F5FF', hc: 'top' }
    ]
  },
  {
    offset: 60,
    boxes: [
      { b: '#F0441E', c: '#FF6C60', hc: 'top' },
      { b: '#FEC600', c: '#CCFFA4' },
      { b: '#B81AEE', c: '#FF9BD9' }
    ]
  },
  {
    offset: 20,
    boxes: [
      { b: '#F0441E', c: '#FF6C60', hc: 'bottom' },
      { b: '#4AE0FD', c: '#6CE909' },
      { b: '#FEC600', c: '#F0441E', hc: 'bottom' }
    ]
  },
  {
    offset: 140,
    boxes: [
      { b: '#F0441E', c: '#FF9BD9' },
      { b: '#FEC600', c: '#FF6C60' },
      { b: '#4878FF', c: '#FFDC60' }
    ]
  }
]

const BOX_COLUMNS = Array(10).fill(BOX_COLUMNS_SET).flat()

const TopRecordBoxes = ({ slice }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <section className={classes.section}>
        {BOX_COLUMNS.map(({ offset, boxes }) => (
          <BoxColumn {...{ offset, boxes }} />
        ))}
      </section>
    </div>
  )
}

const useStyles = createUseStyles({
  wrapper: {
    // overflow: 'hidden'
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    gap: `${BOX_SPACING}px`,
    top: `-${BOX_HEIGHT * 2.5}px`,
    left: `-${BOX_HEIGHT * 0.5}px`,
    marginRight: `-${BOX_HEIGHT * 1.5}px`,
    marginBottom: `-${BOX_HEIGHT * 2}px`,
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.colors.white,
    [theme.breakpoints.down('md')]: {
      top: `-${BOX_HEIGHT * 1.9}px`,
      marginBottom: `-${BOX_HEIGHT * 1.4}px`
    },
    [theme.breakpoints.down('sm')]: {
      top: `-${BOX_HEIGHT * 1.9}px`,
      marginBottom: `-${BOX_HEIGHT * 1.6}px`
    }
  }
})

const BoxColumn = ({ offset, boxes }) => {
  const classes = useStylesBoxColumn({ offset })
  return (
    <div className={classes.boxColumn}>
      {boxes.map(({ b, c, hc }) => (
        <RecordBox
          boxColor={b}
          circleColor={c}
          {...(hc ? { isHalfPosition: hc } : {})}
          boxHeight={BOX_HEIGHT}
          boxBorderRadius={BOX_RADIUS}
        />
      ))}
    </div>
  )
}

const useStylesBoxColumn = createUseStyles({
  boxColumn: ({ offset }) => ({
    paddingTop: `${offset}px`,
    display: 'flex',
    flexDirection: 'column',
    gap: `${BOX_SPACING}px`
  })
})

export default TopRecordBoxes
