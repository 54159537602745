import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'

import { NProgress } from 'redux-nprogress'
import { Helmet } from 'react-helmet-async'
import Header from './Header'
import Footer from './Footer'
import PageContent from './PageContent'
import Popup from './Popup'
import DonateButton from './widgets/DonateButton'

import globalStyles from '../styles/global'
import { getMetadata } from '../selectors'
import theme from '../styles/theme'
import Notification from './Notification'

export default () => {
  const meta = useSelector(getMetadata)
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {meta && <Helmet>
        <title>{meta.title}</title>
        {meta.ogUrl && <link rel='canonical' href={meta.ogUrl} />}
        {meta.meta_title && <meta name='title' content={meta.meta_title} />}
        {meta.metaDescription && <meta name='description' content={meta.metaDescription} />}
        {meta.metaKeywords && <meta name='keywords' content={meta.metaKeywords} />}
        {meta.ogUrl && <meta property='og:url' content={meta.ogUrl} />}
        {meta.ogTitle && <meta property='og:title' content={meta.ogTitle} />}
        {meta.ogImage && [
          <meta property='og:image' content={meta.ogImage.url} key={0} />,
          <meta property='og:image:width' content={meta.ogImage.width} key={1} />,
          <meta property='og:image:height' content={meta.ogImage.height} key={2} />
        ]}
        {meta.ogDescription && <meta property='og:description' content={meta.ogDescription} />}
        {meta.ogSiteName && <meta property='og:site_name' content={meta.ogSiteName} />}

        {meta.ogImage && <meta name='twitter:card' content='summary_large_image' />}
        {meta.ogSiteName && <meta name='twitter:site' content={meta.ogSiteName} />}
        {meta.ogTitle && <meta name='twitter:title' content={meta.ogTitle} />}
        {meta.ogDescription && <meta name='twitter:description' content={meta.ogDescription} />}
        {meta.ogImage && <meta name='twitter:image' content={meta.ogImage.url} />}
      </Helmet>
      }
      <Popup />
      <Header />
      <Notification />
      <div className={classes.content}>
        <NProgress color={theme.colors.primary} />
        <PageContent />
        <DonateButton />
      </div>
      <Footer />
    </div>
  )
}

const useStyles = createUseStyles((theme) => ({
  ...globalStyles(theme),
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: theme.colors.background,
    overflow: 'hidden'
  },
  content: {
    flex: '1 0 auto',
    width: '100%',
    margin: '0 auto'
  }
}))
