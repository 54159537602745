import { getApiUrl } from '../config'
import compact from 'lodash/compact'

export const fetchGlobal = async () => {
  const url = getApiUrl().toString() + `/global`

  return fetch(url, {
    headers: {
      Accept: 'application/json'
    },
    credentials: 'include'
  }).then(data => data.json())
}

export const fetchContent = async (path) => {
  const segments = compact(path.split('/'))
  if (segments[0] === 'news' && segments.length > 1) {
    segments[0] = 'news_article'
  }
  if (segments[0] === 'raffle') {
    segments.unshift('page')
  }
  segments.unshift('content')
  const url = getApiUrl().toString() + '/' + segments.join('/')

  return fetch(url, {
    headers: {
      Accept: 'application/json'
    },
    credentials: 'include'
  })
  // .then(checkStatus)
    .then(data => data.json())
  // .then(checkJson)
}
