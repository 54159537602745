import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Link from '../Link'

const Button = ({
  linkInfo: {
    url = '',
    target = '_blank'
  } = {},
  className,
  text,
  style
}) => {
  const classes = useStyles()
  return (
    <Link to={url} target={target} className={cn(classes.button, className)}>{text}</Link>
  )
}

const useStyles = createUseStyles(theme => ({
  button: {
    padding: '16px 40px',
    borderRadius: '30px',
    background: theme.colors.yellow,
    textDecoration: 'none',
    color: theme.colors.black,
    transition: 'all 0.3s',
    fontFamily: theme.fonts.heading,
    fontSize: theme.getRemValue(22),
    marginTop: theme.sp(8),
    display: 'inline-block',
    '&:hover': {
      color: theme.colors.white,
      backgroundColor: theme.colors.pink
    }
  }
}))

export default Button
