export const BREAKPOINT_CHANGED = 'BREAKPOINT_CHANGED'
export const ELEMENT_SIZE_CHANGED = 'ELEMENT_SIZE_CHANGED'

export function breakpointChanged (breakpoint) {
  return {
    type: BREAKPOINT_CHANGED,
    payload: {
      breakpoint
    }
  }
}

export const elementSizeChanged = (name, width, height) => {
  return { type: ELEMENT_SIZE_CHANGED, payload: { name, width, height } }
}
