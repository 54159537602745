import React from 'react'
import { createUseStyles } from 'react-jss'
import Title from '../Title'
import theme from '../../styles/theme'
import Track from './Track'
import { useMediaQuery } from 'react-responsive'
import TrackMobile from './TrackMobile'
import { values as breakpoints } from '../../styles/breakpoints'

const TrackList = ({ title, tiles }) => {
  const classes = useStyles()
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${breakpoints.md}px)`
  })
  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <Title title={title} color={theme.colors.white} className={classes.title} />
        <div className={classes.tracksContainer}>
          {tiles.map((track, i) => {
            if (isDesktopOrLaptop) {
              return (
                <Track key={i} track={track.track} index={i} />
              )
            }
            return (
              <TrackMobile key={i} track={track.track} index={i} />
            )
          })}
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    color: theme.colors.white,
    backgroundColor: theme.colors.black,
    borderRadius: '20px',
    // display: 'flex',
    padding: '40px 0px 0px 0px',
    // [theme.breakpoints.down('md')]: {
    //   paddingBottom: '30px',
    //   flexWrap: 'wrap'
    // },
    // justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: '-50px'
    },
    width: '100%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  container: {
    position: 'relative',
    minWidth: '100px',
    alignItems: 'center'
  },
  title: {
    color: theme.colors.white,
    fontFamily: theme.fonts.heading,
    fontSize: '50px',
    fontWeight: '400',
    textTransform: 'uppercase',
    lineHeight: '95%',
    marginBottom: '35px',
    [theme.breakpoints.up('md')]: {
      fontSize: '80px'
    },
    textAlign: 'center'
  },
  gridPadding: {
    ...theme.content.padding('paddingTop')
  },
  tracksContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    position: 'relative',
    zIndex: '1',
    // marginTop: '-190px',
    // bottom: '-190px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      paddingLeft: '20px',
      paddingRight: '20px'
    },
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      overflow: 'scroll'
    }
  }
})

export default TrackList
