import React from 'react'
import injectSheet from 'react-jss'
import theme, { centerAbsolutely } from '../../styles/theme'
import flow from 'lodash/flow'
import TileContainer from './TileContainer'
import CircularText from '../widgets/CircularText'

const CircleQuoteTile = ({ classes, text, link, cta_text: ctaText }) => {
  return <TileContainer link={link} cta_text={ctaText} className={classes.container}>
    <CircularText className={classes.quote} fillColor={theme.colors.primary} color={theme.colors.white}>{text}</CircularText>
  </TileContainer>
}

export default flow([
  injectSheet(theme => ({
    container: {
      backgroundColor: theme.colors.background
    },
    quote: {
      width: 250,
      ...centerAbsolutely()
    }
  }))
])(CircleQuoteTile)
