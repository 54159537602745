import { put, takeLatest, call } from 'redux-saga/effects'
import { getApiUrl } from '../config'

import {
  SUBMIT_REQUEST,
  submitError,
  submitSuccess
} from '../actions'

function postData (data) {
  return fetch(getApiUrl() + '/submission', {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    body: JSON.stringify(data)
  }).then(response => response.json())
}

function * submit (action) {
  try {
    var response = yield call(postData, action.payload)
    if (response.status === 'Error') {
      yield put(submitError(response.error))
    } else {
      yield put(submitSuccess())
    }
  } catch (e) {
    yield put(submitError(e.message))
  }
}

function * submitSaga () {
  yield takeLatest(SUBMIT_REQUEST, submit)
}

export default submitSaga
