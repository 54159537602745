import { NOT_FOUND } from 'redux-first-router'
import { beginTask, endTask } from 'redux-nprogress'
import { fetchContent, fetchGlobal } from './api'
import { globalContentLoaded, pageContentLoaded } from './actions'
import { getGlobalContent, getCurrentLocation, isNotFound, getPage } from './selectors'

export default {
  GALLERY_ROUTE: {
    path: '/gallery/:uid/:id?',
    toPath: segment => segment,
    thunk: async (dispatch, getState) => {
      // Looks the same as CONTENT_ROUTE but it isn't, i dont what to refetch the data when they navigate to a item,
      // i just want to open the dialog box to show the image

      const location = getCurrentLocation(getState())
      const global = getGlobalContent(getState())
      var pageContent = getPage(getState())

      if (!global) { dispatch(globalContentLoaded(await fetchGlobal())) }

      if (!pageContent || pageContent.type !== 'gallery') {
        try {
          dispatch(beginTask())
          pageContent = await fetchContent(location.pathname)
          if (location !== getCurrentLocation(getState())) return
          if (!pageContent || !pageContent.type) { return dispatch({ type: NOT_FOUND }) }
          dispatch(pageContentLoaded(pageContent))
        } finally {
          dispatch(endTask())
        }
      }
    }
  },
  CONTENT_ROUTE: {
    path: '*',
    toPath: segment => segment,
    thunk: async (dispatch, getState) => {
      dispatch(beginTask())
      try {
        const notFound = isNotFound(getState())
        if (notFound) return

        const location = getCurrentLocation(getState())
        const global = getGlobalContent(getState())

        if (!global) { dispatch(globalContentLoaded(await fetchGlobal())) }

        var pageContent = await fetchContent(location.pathname)

        // We have moved location since the call was made to fetch the content, so just throw the data away
        if (location !== getCurrentLocation(getState())) return

        if (!pageContent || !pageContent.type) { return dispatch({ type: NOT_FOUND }) }
        dispatch(pageContentLoaded(pageContent))
      } catch (ex) {
        console.error(ex)
        return dispatch({ type: NOT_FOUND })
      } finally {
        dispatch(endTask())
      }
    }
  }

}
