import React, { PureComponent } from 'react'
import cn from 'classnames'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import Transition from 'react-transition-group/Transition'
import NotFound from './NotFound'
import { getPageContent, getCurrentLocation, isNotFound } from '../selectors'
import { canUseDom } from '../utils/dom'
import Home from './Home'
import Page from './Page'
import NewsListing from './NewsListing'
import Submission from './Submission'
import Gallery from './Gallery'
import flow from 'lodash/flow'
import get from 'lodash/get'

const TRANSITION_DURATION_SECONDS = 0.3

const components = {
  homepage: (props) => <Home {...props} />,
  page: (props) => <Page {...props} />,
  news: (props) => <NewsListing {...props} />,
  news_article: (props) => <Page {...props} />,
  submission: (props) => <Submission {...props} />,
  gallery: (props) => <Gallery {...props} />
}

class PageContent extends PureComponent {
    _updateScroll = () => {
      if (!canUseDom) return
      // This uses the session storage that is setup by the redux-first-router-restore-scroll package
      // We need to set this to manual as we are doing some transitions between pages
      var key = get(window, ['history', 'state', 'key'])
      if (!key) key = 'loadPage'
      const valueString = window.sessionStorage.getItem(`@@scroll|${key}`)
      if (valueString) {
        const value = JSON.parse(valueString)
        window.scrollTo(value[0], value[1])
      } else {
        window.scrollTo(0, 0)
      }
    }

    _onEntering = (node) => {
      this._updateScroll()
    }

    render () {
      const { classes, page, notFound } = this.props

      if (notFound) return <NotFound />
      if (!page) return null
      const Component = components[page.type]
      if (!Component) return null

      return <TransitionGroup appear enter exit>
        <Transition timeout={TRANSITION_DURATION_SECONDS * 1000}
          onEntering={this._onEntering}
          key={page.uid} >
          {(state) => (
            <div className={cn(classes.page, state)}>
              <Component page={page} />
            </div>
          )}
        </Transition>
      </TransitionGroup>
    }
}

const mapStateToProps = (state) => {
  return {
    page: getPageContent(state),
    location: getCurrentLocation(state),
    notFound: isNotFound(state)
  }
}

const getWindowScrollY = () => {
  return canUseDom ? window.pageYOffset : 0
}

const getLeftOffset = (maxWidth) => {
  if (!canUseDom) return 0
  return Math.max((window.document.clientWidth - maxWidth) / 2, 0)
}

export default flow([
  connect(mapStateToProps),
  injectSheet(theme => ({
    page: {
      zIndex: theme.zIndex.content,
      transition: `opacity ${TRANSITION_DURATION_SECONDS}s ${theme.animation.easing.accelerationCurve}`,
      ...theme.header.height.mixin('marginTop'),
      '&.exiting': {
        position: 'fixed',
        overflow: 'hidden',
        width: '100%',
        opacity: 0,
        left: () => getLeftOffset(theme.content.width),
        top: () => -getWindowScrollY()
      }
    }
  }))
])(PageContent)
