import React from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import theme from '../styles/theme'

const PageWidth = ({ children, className, withoutPadding, withVerticalPadding, ...rest }) => {
  const classes = useStyles()
  return React.Children.map(children, child => {
    if (!child) return null
    const pageWidthClassName = cn(
      className,
      classes.container,
      !withoutPadding && classes.containerPadding,
      withVerticalPadding && classes.verticalPadding
    )
    return React.cloneElement(child, {
      className: cn(pageWidthClassName, className, child.props.className),
      ...rest
    })
  })
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    maxWidth: theme.content.width,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  containerPadding: {
    ...theme.content.padding('padding', x => [0, x])
  },
  verticalPadding: {
    paddingTop: theme.sp(8),
    paddingBottom: theme.sp(8),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.sp(12),
      paddingBottom: theme.sp(12)
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.sp(18),
      paddingBottom: theme.sp(18)
    }
  }
}, { name: 'PageWidth' })

export default PageWidth
