import React from 'react'
import { createUseStyles } from 'react-jss'
import { NavLink } from '../Link'
import Menu from './Menu'
import MusicPlayer from './MusicPlayer'
import S7WarChildLogo from '../../img/secret7_warchild_logo_white.png'

export default () => {
  const classes = useStyles()

  return (
    <header className={classes.header}>
      <nav className={classes.navbar}>
        <NavLink to='/' className={classes.homeLink}>
          <img className={classes.warChildLogo} src={S7WarChildLogo} alt='Logo' />
        </NavLink>
        <Menu />
        <MusicPlayer />
      </nav>
    </header>
  )
}

const useStyles = createUseStyles((theme) => ({
  header: {
    position: 'fixed',
    width: '100%',
    backgroundColor: theme.colors.black,
    color: theme.colors.white,
    zIndex: theme.zIndex.header,
    paddingBottom: 3, // make room for the music player's progress bar
    ...theme.header.height.mixin('height')
  },
  navbar: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative', // this is just to make sure the menu dropdown can appear above the music player progress bar on mobile - without it the menu dropdown's z-index doesn't take effect
    padding: [0, theme.sp(3)],
    [theme.breakpoints.up('md')]: {
      padding: [0, theme.sp(6)]
    }
  },
  homeLink: {
    display: 'flex',
    flexShrink: 0,
    '-webkit-tap-highlight-color': 'transparent',
    flexDirection: 'row'
  },
  logo: {
    display: 'block',
    filter: 'invert(100%)',
    width: 'auto',
    height: 32,
    color: theme.colors.white,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      height: 52
    },
    margin: '0 auto'
  },
  warChildLogo: {
    display: 'block',
    width: 'auto',
    height: 32,
    padding: 1,
    color: theme.colors.white,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      height: 52
    },
    margin: '0 auto',
    marginRight: '6px'
  }
}))
