import { fork, all } from 'redux-saga/effects'
import subscriptionSaga from './subscription'
import fileUploadSaga from './fileUpload'
import submissionSaga from './submission'

export default function * root () {
  yield all([
    fork(subscriptionSaga),
    fork(fileUploadSaga),
    fork(submissionSaga)
  ])
}
